// styles and icons
import './createOfferingModal.scss'

//react
import { useState, useRef, FC, FormEvent, SetStateAction, Dispatch } from 'react'

//components
import GuideModal from '../../../../general/modals/guideModal/guideModal'
import Modal from '../../../../general/modals/modal/modal'
import InputField from '../../../../general/inputField/inputField'
import EditableTable from '../../../../general/editableTable/editableTable'
import TextArea from '../../../../general/textArea/textArea'
import Dropdown from '../../../../general/dropdown/dropdown'
import FloatInput from '../../../../general/floatInput/floatInput'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setCreateOfferingModalIsOpen } from '../../../../../redux/sales/offeringList/modals'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import { getCompanyInventoryItems } from '../../../../../utils/old_urls/warehouse/warehouseUrl'
import { createOfferingUrl } from '../../../../../utils/old_urls/sales/offeringListUrls'

// types
import { currency, dropdownOption, infoType, inventoryItem, inventoryItemResponse, offering } from '../../../../../types/general/generalTypes'

// translation
import { useTranslation } from 'react-i18next'

//other
import { createDropdownOption, formatInventoryItemResponse, formatOfferingResponse, formatStringIntoTwoDigitsFloat } from '../../../../../assets/general/generalFunctions'

type formErrors = {
	name?: string
	description?: string
	priceWithVAT?: string
}

type createOfferingModalProps = {
	offerings: offering[]
	setOfferings: Dispatch<SetStateAction<offering[]>>
}

const CreateOfferingModal: FC<createOfferingModalProps> = ({ offerings, setOfferings }) => {
	const dispatch = useAppDispatch()

	const { t } = useTranslation('', { keyPrefix: 'sales.offeringList.modals.createOfferingModal' })
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t

	const { modalIsOpen } = useAppSelector((state) => state.offeringListModal.createOfferingModal)
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1

	const formRef = useRef<HTMLFormElement | null>(null)

	const [inventoryItems, setInventoryItems] = useState<inventoryItem[]>([])
	const [selectedItems, setSelectedItems] = useState<inventoryItem[]>([])
	const [name, setName] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const [priceWithoutVAT, setPriceWithoutVAT] = useState<number>(0)
	const [priceWithVAT, setPriceWithVAT] = useState<number>(0)
	const [VAT, setVAT] = useState<number>(0)
	const [offeringCurrency, setOfferingCurrency] = useState<dropdownOption>({title: tCurrency(currency.UAH), key: currency.UAH})
	const [errors, setErrors] = useState<formErrors>({})

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const closeModal = () => {
		setSelectedItems([])
		setName('')
		setDescription('')
		setPriceWithoutVAT(0)
		setPriceWithVAT(0)
		setVAT(0)
		setOfferingCurrency({title: tCurrency(currency.UAH), key: currency.UAH})
		dispatch(setCreateOfferingModalIsOpen(false))
	}

	const amountInputHandleWithoutVAT = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.00'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		
		setPriceWithoutVAT(numberValue)

		const newValue = parseFloat(
			(
				numberValue * (1 + VAT / 100)
			).toFixed(2)
		)
		
		setPriceWithVAT(newValue)
	}

	const amountInputHandleWithVAT = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.00'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)

		console.log('sadasdas')
		
		setPriceWithVAT(numberValue)

		const newValue = parseFloat(
			(
				numberValue / (1 + VAT / 100)
			).toFixed(2)
		)
		
		setPriceWithoutVAT(newValue)
	}


	const amountInputHandleVAT = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.00'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		setVAT(numberValue)
		setPriceWithoutVAT(
			parseFloat(
				(
					priceWithVAT / (1 + numberValue / 100)
				).toFixed(2)
			)
		)
	}

	const loadData = async (searchQuery: string, page: number) => {
		const { result }: { result: inventoryItemResponse[] } = await authorizedRequest(getCompanyInventoryItems(companyId) + `?page=${page}&per_page=${10}&needle=${searchQuery}`, 'GET')
		console.log(result)
		if (result.length > 0) {
			const formatedItems:inventoryItem[] = result.map(formatInventoryItemResponse)

			return formatedItems
		}

		return []
	}

	const checkErrors = () => {
		let result = true
		if (name === '') {
			setErrors(prevErrors => ({ ...prevErrors, name: t('pleaseEnterTheName') }))
			result = false
		}
		if (priceWithVAT === undefined || priceWithVAT < 0) {
			setErrors(prevErrors => ({ ...prevErrors, priceWithVAT: t('pleaseEnterThePrice') }))
			result = false
		}
		

		return result
	}


	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		setErrors({})
		
		setIsLoading(true)

		if (checkErrors()) {
			const itemIds = selectedItems.map(item => { return { item_id: item.id, item_quantity: item.quantity } })
			const { result } = await authorizedRequest(
				createOfferingUrl(companyId), 'POST', 'accessToken', {
					data: [
						{ 
							name: name,
							description: description,
							price: priceWithVAT,
							vat: VAT,
							currency: offeringCurrency?.key,
							items: itemIds
						}
					]
				})
			if (result.length > 0) {
				const formatedOfferings: offering[] = result.map(formatOfferingResponse)
				setOfferings([...formatedOfferings, ...offerings])
			}
			setIsLoading(false)
			closeModal()
		}
	}
	
	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			title={t('title')}
			submitButton={{
				text: t('add'),
				onClick: () => {
					if (formRef.current) {
						formRef.current.requestSubmit()
					}
				}
			}}
			isLoading={isLoading}
		>
			<div className='create-product-container'>
				<form
					className='product-info'
					ref={formRef}
					onSubmit={handleSubmit}
				>
					<div className='form-wrapper'>
						<InputField
							type='text'
							name='offering'
							label={t('offeringName')}
							value={name}
							onChange={(e) => {
								setName(e.target.value)
							}}
							info={errors.name ? {
								type: infoType.error,
								text: errors.name
							} : undefined}
						/>
						<TextArea
							label={t('description')}
							placeholder={t('description')}
							value={description}
							setValue={val => {
								setDescription(val)
							}}
						/>
						<FloatInput
							label={t('priceWithoutVat')}
							amountInputHandle={amountInputHandleWithoutVAT}
							value={`${priceWithoutVAT}`}	
						/>
						<FloatInput
							info={errors.priceWithVAT ? {
								type: infoType.error,
								text: errors.priceWithVAT
							} : undefined}
							label={t('priceWithVat')}
							amountInputHandle={amountInputHandleWithVAT}
							value={`${priceWithVAT}`}	
						/>
						<FloatInput
							label={t('vat')}
							amountInputHandle={amountInputHandleVAT}
							value={`${VAT}`}	
							maxLength={2}
						/>
						<div className='form-dropdown'>
							<div className='form-dropdown-currency'>
								<Dropdown
									placeholder={t('selectCurrency')}
									defaultSelectedOption={offeringCurrency}
									dropdownOptions={createDropdownOption(tCurrency, currency)}
									onSelect={({ key, title }) => {
										setOfferingCurrency({ key, title })
									}}
									selectedOption={offeringCurrency}
								/>
							</div>
						</div>
					</div>
				</form>
				<div className='table-wrapper'>
					<p className='inventory-items-label'>{t('inventoryItems')}</p>
					<EditableTable
						items={inventoryItems}
						setItems={(value) => { setInventoryItems(value as inventoryItem[]) }}
						loadItems={loadData}
						selected={{
							items: selectedItems,
							setItems: (value) => { setSelectedItems(value as inventoryItem[]) }
						}}
						noCost
					/>
				</div>
			</div>
		</Modal>
	)
}

export default CreateOfferingModal