// styles and icons
import './pipeline.scss'
import { calendar, dashboardIcon, listIcon, project as projectIcon, tableIcon, task as taskIcon } from '../../../assets/general/generalIcons'
import { taskListIcon } from '../../../assets/projects/project/projectIcons'

// react
import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import Layout from '../../general/layout/layout'
import SwitchButtons from '../../general/switchButtons/switchButtons'
import PipelineDashboard from '../pipelineDashboard/pipelineDashboard'
import ProjectsList from '../../general/projectsList/projectsList'
import TaskDashboard from '../../general/taskDashboard/taskDashboard'
import MyTasksCalendar from '../myTasks/myTasksCalendar/myTasksCalendar'
import TaskList from '../general/taskList/taskList'
import CheckBox from '../../general/checkBox/checkBox'
import ProjectTable from '../../general/tableViews/projectTable/projectTable'
import TaskTable from '../../general/tableViews/taskTable/taskTable'

// types
import { filterBar, switchButton, projectResponse, project, infoPopupTypes, taskResponse, stageResponse } from '../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../utils/queries'
import {
	pipelinesGetProjectsResourceUrl,
	pipelinesModifyPipelineResourceUrl,
	singelPipelineTaskCalendar,
	singelPipelineTasks
} from '../../../utils/urls/projects/pipelines'

//redux
import { useAppSelector, useAppDispatch } from '../../../customHooks/redux'
import { setProjectsGuidanceModalsClosed, setProjectsGuidancePipelinesModals } from '../../../redux/general/modals'

// translation
import { useTranslation } from 'react-i18next'

//other
import { formatProjectResponse, formatStageResponse, formatTaskResponse } from '../../../assets/projects/projectsFunctions'

const Pipeline: FC = () => {
	const { pipelineId } = useParams()

	const { projectsGuidanceModals } = useAppSelector(state => state.generalModals.guidanceModals)

	const [activeViewIconId, setActiveViewIconId] = useState(0)
	const [activeTypeIconId, setActiveTypeIconId] = useState(3)

	const [filterBar, setFilterBar] = useState<filterBar | undefined>()
	const [pipelineName, setPipelineName] = useState('')

	const [toolBarButtons, setToolBarButtons] = useState<switchButton[]>([
		{
			id: 0,
			icon: dashboardIcon,
			guidance: {
				open: projectsGuidanceModals.pipelines[4],
				close: () => {
					dispatch(setProjectsGuidanceModalsClosed())
				},
				next: () => {
					dispatch(setProjectsGuidancePipelinesModals([false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
				},
				text: ''
			}
		},
		{
			id: 1,
			icon: listIcon,
			guidance: {
				open: projectsGuidanceModals.pipelines[5] && filterBar !== undefined,
				close: () => {
					dispatch(setProjectsGuidanceModalsClosed())
				},
				next: () => {
					dispatch(setProjectsGuidancePipelinesModals([false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false]))
				},
				text: ''
			}
		},
		{
			id: 3,
			icon: tableIcon,
			guidance: {
				open: projectsGuidanceModals.pipelines[6],
				close: () => {
					dispatch(setProjectsGuidanceModalsClosed())
				},
				next: () => {
					dispatch(setProjectsGuidancePipelinesModals([false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false]))
				},
				text: ''
			}
		}

	])

	const { t } = useTranslation('', { keyPrefix: 'projects.pipelineList' })

	const [showClosedTasks, setShowClosedTasks] = useState(false)
	const [showClosedProjects, setShowClosedProjects] = useState(false)

	const dispatch = useAppDispatch()

	
	const titleUrls = [
		{
			url: '/ri-business/projects/pipelines',
			title: t('pipelines')
		},
		{
			url: location.pathname,
			title: pipelineName
		}
	]

	const infoPopup = {
		content: [{
			title: 'pipeline_info_title',
			description: 'pipeline_info_description',
			link: '/ri-manual/projects/single-pipeline'
		}],
		type: infoPopupTypes.INFO
	}

	useEffect(() => {

		let toolBarButtonsData: switchButton[] = [
			{
				id: 0,
				icon: dashboardIcon,
				guidance: {
					open: projectsGuidanceModals.pipelines[9],
					close: () => {
						dispatch(setProjectsGuidanceModalsClosed())
					},
					next: () => {
						dispatch(setProjectsGuidancePipelinesModals([false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false]))
					},
					text: 'project5'
				}
			},
			{
				id: 1,
				icon: listIcon,
				guidance: {
					open: projectsGuidanceModals.pipelines[8],
					close: () => {
						dispatch(setProjectsGuidanceModalsClosed())
					},
					next: () => {
						dispatch(setProjectsGuidancePipelinesModals([false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false]))
					},
					text: ''
				}
			},
			{
				id: 3,
				icon: tableIcon,
				guidance: {
					open: projectsGuidanceModals.pipelines[7],
					close: () => {
						dispatch(setProjectsGuidanceModalsClosed())
					},
					next: () => {
						dispatch(setProjectsGuidancePipelinesModals([false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false]))
					},
					text: ''
				}
			}

		]

		if (activeTypeIconId === 4) {
			toolBarButtonsData.push({
				id: 2,
				icon: calendar
			})
		} else {
			toolBarButtonsData = toolBarButtonsData.filter(e => e.id !== 2)
		}

		setToolBarButtons([...toolBarButtonsData])


	}, [projectsGuidanceModals.pipelines])
  
	const toolBarRightButtons: switchButton[] = [
		{
			id: 3,
			icon: taskIcon,
			guidance: {
				open: projectsGuidanceModals.pipelines[6],
				close: () => {
					dispatch(setProjectsGuidanceModalsClosed())
				},
				next: () => {
					dispatch(setProjectsGuidancePipelinesModals([false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false]))
				},
				text: 'project5'
			}
		},
		{
			id: 4,
			icon: taskListIcon,
			guidance: {
				open: projectsGuidanceModals.pipelines[5],
				close: () => {
					dispatch(setProjectsGuidanceModalsClosed())
				},
				next: () => {
					dispatch(setProjectsGuidancePipelinesModals([false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false]))
				},
				text: 'project5'
			}
		}
	]
	
	const loadTasks = async (stageId: number, searchRequest: string, page: number, showClosed: boolean) => {
		const response = await authorizedRequest(singelPipelineTasks(Number(pipelineId)) + `?needle=${searchRequest}&status=${stageId}&page=${page}&show_closed=${showClosed}`, 'GET')
		const { result } = response
		return result.map((task: taskResponse) => formatTaskResponse(task))
	}

	const loadProjects = async (stageId: number, searchRequest: string, page: number, showClosed: boolean): Promise<project[]>  => {
		const response = await authorizedRequest(pipelinesGetProjectsResourceUrl(Number(pipelineId)) + `?needle=${searchRequest}&status=${stageId}&page=${page}&show_closed=${showClosed}`, 'GET')
		const { result } = response
		return result.map((project: projectResponse) => formatProjectResponse(project))
		
	}

	const loadTaskCalendar = async () => {
		const response = await authorizedRequest(singelPipelineTaskCalendar(Number(pipelineId)), 'GET')
		const { result } = response
		return result.map((task: taskResponse) => formatTaskResponse(task))
	}

	const loadStages = async (showClosed: boolean) => {
		const response = await authorizedRequest(singelPipelineTasks(Number(pipelineId)) + `?show_closed=${showClosed}`, 'PATCH')
		const { result } = response
		return result.map((stage: stageResponse) => formatStageResponse(stage))
	}

	const toolBar = useMemo(()=>{
		if (activeTypeIconId === 3) {
			switch (activeViewIconId) {
			case 0:
				return <PipelineDashboard showClosed={showClosedProjects}/>
			case 1:
				return <ProjectsList loadProjects={loadProjects} setFilterBar={setFilterBar} showClosed={showClosedProjects} />
			case 2:
				setActiveViewIconId(0)
				return <PipelineDashboard />
			case 3:
				return <ProjectTable loadProjects={{projectUrl: pipelinesGetProjectsResourceUrl(Number(pipelineId)), showClosed: showClosedProjects}} />
			}
      
		}else{
			switch (activeViewIconId) {
			case 0:
				return <TaskDashboard showClosed={showClosedTasks} loadStages={loadStages} loadTasks={loadTasks} />
			case 1:
				return <TaskList key={showClosedTasks?'myTasks':'myTasksNoClosed'}  showClosed={showClosedTasks} loadTasks={loadTasks} setFilterBar={setFilterBar} />
			case 2:
				return <MyTasksCalendar key={showClosedTasks?'myTasksCalendar':'myTasksCalendarNoClosed'} loadTasks={loadTaskCalendar} />
			case 3:
				return <TaskTable loadTasks={{taskUrl: singelPipelineTasks(Number(pipelineId)), showClosed: showClosedTasks}} />
			}
		}
	},[activeTypeIconId, activeViewIconId, showClosedTasks, showClosedProjects])

	useEffect(() => {
		authorizedRequest(pipelinesModifyPipelineResourceUrl(Number(pipelineId)), 'GET')
			.then((response) => {
				const { result } = response
				setPipelineName(result.name)
			})
	}, [pipelineId])

	return (
		<Layout
			header={{
				avatar: true,
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			<div className='pipeline'>
				<div className="pipeline-tool-bar">
					<div className="pipeline-tool-bar-left">
						<SwitchButtons switchButtons={toolBarButtons} activeSwitchButtonId={activeViewIconId} setActiveSwitchButtonId={setActiveViewIconId} size='medium' />
						{
							activeTypeIconId === 4 ?
								<CheckBox label={t('showClosed')} isChecked={showClosedTasks} onClick={() => setShowClosedTasks(!showClosedTasks)}/>
								:
								<CheckBox label={t('showClosed')} isChecked={showClosedProjects} onClick={() => setShowClosedProjects(!showClosedProjects)}/>
						}
					</div>
					<SwitchButtons switchButtons={toolBarRightButtons} activeSwitchButtonId={activeTypeIconId} setActiveSwitchButtonId={setActiveTypeIconId} size='medium' />
				</div>
				{toolBar}
			</div>
		</Layout>
	)
}

export default Pipeline