//styles and icons
import './modal.scss'
import { close } from '../../../../assets/general/generalIcons'

//react
import { FC, useEffect, useState } from 'react'

//types
import { guidanceModalData, infoPopup, infoPopupTypes } from '../../../../types/general/generalTypes'

//components
import Button from '../../button/button'
import Loading from '../../loading/loading'
import InfoPopup from '../../infoPopup/infoPopup'

type modalProps = {
	closeModal: () => void
	closeButton?: boolean
	submitButton?: {
		text: string
		onClick: () => void
	},
	titlePopup?: infoPopup
	open: boolean
	title?: string
	children: React.ReactNode
	isLoading?: boolean
	extraClass?: string
	zIndex?: number
}

const Modal: FC<modalProps> = ({ closeModal, closeButton = true, submitButton, open, title, children, isLoading, titlePopup, extraClass, zIndex }) => {

	const [initiated, setInitiated] = useState(false)

	useEffect(() => {
		if (open) {
			setInitiated(true)
		}
	}, [open])

	return (
		<>
			{
				initiated ?
					<div onMouseDown={closeModal} className={open ? 'dark-bg' : 'hidden-modal'} style={zIndex ? {zIndex: zIndex} : undefined}>
						<div
							onMouseDown={(e) => {
								e.stopPropagation()
							}}
							className={`modal-container ${extraClass}`}
						>
							{
								closeButton || title ? (
									<div className="modal-header">
										{
											title ? (
												<div className='modal-header-wrapper'>
													<h2 className='modal-title'>{title}</h2>
													{titlePopup && <InfoPopup type={infoPopupTypes.INFO} content={titlePopup.content.map((content) => {
														return {
															title: content.title,
															description: content.description,
															link: content.link
														}
													})} />} 
												</div>
												
											) : null
										}
										{
											closeButton ? (
												<span onClick={() => closeModal()} className="close-animate">{close}</span>
											) : null
										}
									</div>
								) : null
							}
							<div className="modal-content-container">
								{children}
							</div>
							{
								submitButton && (
									<div className="modal-button">
										<Button disabled={isLoading} active={true} text={submitButton.text} onClick={submitButton.onClick} />
									</div>
								)
							}
							
						</div>
					</div>
					:
					null
			}
			{isLoading && <Loading />}
		</>
	)
}

export default Modal
