// styles
import './homeHeroSection.scss'

// components
import ImageOne from '../../../assets/images/appFeature/headerFeature/employeesImg1.png'
import ImageTwo from '../../../assets/images/appFeature/headerFeature/salesImg2.png'
import ImageThree from '../../../assets/images/appFeature/headerFeature/salesImg1.png'
import ImageFour from '../../../assets/images/appFeature/headerFeature/employeesImg2.png'
import ImageOneWebp from '../../../assets/images/appFeature/headerFeature/employeesImg1.webp'
import ImageTwoWebp from '../../../assets/images/appFeature/headerFeature/salesImg2.webp'
import ImageThreeWebp from '../../../assets/images/appFeature/headerFeature/salesImg1.webp'
import ImageFourWebp from '../../../assets/images/appFeature/headerFeature/employeesImg2.webp'
import HeaderAppFeature from '../../general/headerAppInformation/headerAppFeature'

//translation
import { useTranslation } from 'react-i18next'
import { employee, finance, project, purchase, sale, warehouse } from '../../../../../assets/general/generalIcons'

const HomeHeroSection = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.home.heroSection' })

	return (
		<div className="home-hero-section">
			<HeaderAppFeature
				page={'sales'}
				text={`${t('text')}`}
				heading={[`${t('title')}`, `${t('titleBlack')}`]}
				buttons={[{text: t('tryFree'), link: '/register', variant: 'solid'}, {text: t('watchDemo'), link: t('demoLink'), variant: 'outlined'}]}
				alternativeIcons={[
					{
						icon: purchase,
						iconPosition: {left: '4vw', top: '12vw'}
					},
					{
						icon: warehouse,
						iconPosition: {left: '84vw', top: '6vw'}
					},
				]}
				imagesData={[
					{
						png: ImageOne,
						webp: ImageOneWebp,
						icon: {
							icon: project,
							iconPosition: {left: '6vw', top: '23vw'}
						}
					},
					{
						png: ImageTwo,
						webp: ImageTwoWebp,
						icon: {
							icon: sale,
							iconPosition: {left: '4vw', top: '-2vw'}
						}
					},
					{
						png: ImageThree,
						webp: ImageThreeWebp,
						icon: {
							icon: finance,
							iconPosition: {left: '21vw', top: '19vw'}
						}
					},
					{
						png: ImageFour,
						webp: ImageFourWebp,
						icon: {
							icon: employee,
							iconPosition: {left: '13vw', top: '-6vw'}
						}
					}
				]}
			/>
		</div>
	)
}

export default HomeHeroSection