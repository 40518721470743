//components
import ListItemCard from '../../../general/listItemCard/listItemCard'

//react
import { FC, useEffect } from 'react'

// translation
import { useTranslation } from 'react-i18next'

// redux
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'
import { setDeletePipelineId, setDeletePipelineModalIsOpen } from '../../../../redux/projects/pipelineList/modals'
import { setPipelineGuidanceModals, setPipelineGuidanceModalsClosed } from '../../../../redux/general/modals'

//types
import { pipeline } from '../../../../types/projects/pipelineTypes'

//other
import { currencyToFormattedString, formatDate } from '../../../../assets/general/generalFunctions'

type pipelineListItemProps = pipeline<Date | undefined> & {
	onClick?: () => void
}

const PipelineListItem: FC<pipelineListItemProps> = ({id, name, description, numberOfProjects, revenue, earliestDeadline, deleteAccess, onClick}) => {
	const {t} = useTranslation('',{keyPrefix:'projects.pipelineList.pipelineListItem'})
	const dispatch = useAppDispatch()
	
	const handleDelete	= (id: number) => {
		dispatch(setDeletePipelineId(id))
		dispatch(setDeletePipelineModalIsOpen(true))
	}

	const { pipelineGuidanceModals } = useAppSelector((state) => state.generalModals.guidanceModals)

	useEffect(() => {
		if (!pipelineGuidanceModals[0]) {
			dispatch(setPipelineGuidanceModals([true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
		}
	}, [])

	return (
		<>
			<ListItemCard
				title={name}
				guideText={t('viewPipeline')}
				description={description}
				data={[
					...revenue.map((item) => {
						return {
							title: t('revenue'),
							value: currencyToFormattedString(item.value, item.currency)
				
						}
					}),
					{	
						title: t('projectTotal'),
						value: `${numberOfProjects}`
					},
					{
						title: t('earliestDeadline'),
						value: earliestDeadline ? formatDate(earliestDeadline, true, true) : t('na')
					}
				]}
				footer={{
					viewLink: {
						text: t('viewPipeline'),
						url: `/ri-business/projects/pipeline/${id}`
					},
					deleteFunction: deleteAccess && id > 0 ? () => { handleDelete(id)} : undefined
				}}
				onClick={() => {
					onClick && onClick()
					dispatch(setPipelineGuidanceModalsClosed())
				}}
			/>	
		</>
	)
}

export default PipelineListItem