// styles
import './appFeature.scss'

// react
import { FC, useRef } from 'react'

// components
import ScrollAnimation from '../scrollAnimation/scrollAnimation'
import Title from '../title/title'
import Text from '../text/text'


type appFeatureProps = {
	id?: string
	smallText: string,
	heading: string,
	text: string,
	buttons: {
		text: string
		link: string
		variant: 'solid' | 'outlined'
	}[]
	underButtonText?: string
	contentRight?: boolean
	image: string
	webpImage: string
	extras?: React.ReactNode[]
	icon?: JSX.Element
}

const AppFeature: FC<appFeatureProps> = ({ id, smallText, heading, text, contentRight = false, image, extras, icon, webpImage}) => {
	const ref = useRef(null)
	return (
		<div className="app-feature-component-wrapper" id={id}>
			<div className={`app-feature-component ${contentRight ? 'app-feature-component-rtl' : 'app-feature-component-ltr'}`} ref={ref}>
					
				<div className='app-feature-component-content'>
					<ScrollAnimation direction={contentRight ? 'to-left' : 'to-right'}>
						<div className='app-feature-component-content-container'>
							<div className='app-feature-component-subtitle-container'>
								{icon}
								<span className='app-feature-component-first-subtitle'>
									{smallText.split(' ')[0]}
								</span> <span className='app-feature-component-rest-subtitle'>
									{smallText.split(' ').length > 1 ? smallText.slice(smallText.indexOf(' ') + 1) : null}
								</span>
							</div>
							<Title>{heading}</Title>
							<Text>{text}</Text>
						</div>
					</ScrollAnimation>
				</div>
					
				
				<div className='app-feature-component-img-container'>
					<ScrollAnimation direction={contentRight ? 'to-right' : 'to-left'}>
					<picture>
                      <source srcSet={webpImage} type="image/webp" />
                      <img
                        src={image}
                      />
                    </picture>
					</ScrollAnimation>
				</div>
			</div>
			{extras?.map((elem, index) => {
				return (
					<ScrollAnimation direction={index %2!=0 ? 'to-right' : 'to-left'}>
						{elem}
					</ScrollAnimation>
				)
			})}
		</div>
	)
}

export default AppFeature