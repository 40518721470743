export const customize = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M3 3H11V11H3V3ZM13 3H21V11H13V3ZM3 13H11V21H3V13ZM18 13H16V16H13V18H16V21H18V18H21V16H18V13Z" fill="#444444"/>
</svg>	

export const filterList = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M10 18V16H14V18H10ZM6 13V11H18V13H6ZM3 8V6H21V8H3Z" fill="#444444"/>
</svg>

export const sortAscending = <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M19 17H22L18 21L14 17H17V3H19M11 13V15L7.67 19H11V21H5V19L8.33 15H5V13M9 3H7C5.9 3 5 3.9 5 5V11H7V9H9V11H11V5C11 4.46957 10.7893 3.96086 10.4142 3.58579C10.0391 3.21071 9.53043 3 9 3ZM9 7H7V5H9V7Z" fill="#444444"/>
</svg>  

export const progressCheck = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M12.9998 2.02979V4.04979C17.3898 4.58979 20.4998 8.57979 19.9598 12.9698C19.4998 16.6098 16.6398 19.4998 12.9998 19.9298V21.9298C18.4998 21.3798 22.4998 16.4998 21.9498 10.9998C21.4998 6.24979 17.7298 2.49979 12.9998 2.02979ZM10.9998 2.05979C9.0498 2.24979 7.1898 2.99979 5.6698 4.25979L7.0998 5.73978C8.2198 4.83978 9.5698 4.25979 10.9998 4.05979V2.05979ZM4.2598 5.66979C3.00946 7.18713 2.24001 9.04287 2.0498 10.9998H4.0498C4.2398 9.57979 4.7998 8.22978 5.6898 7.09978L4.2598 5.66979ZM15.4998 8.49979L10.6198 13.3798L8.4998 11.2598L7.43981 12.3198L10.6198 15.4998L16.5598 9.55979L15.4998 8.49979ZM2.0598 12.9998C2.2598 14.9598 3.0298 16.8098 4.2698 18.3298L5.6898 16.8998C4.80673 15.7694 4.24376 14.4224 4.0598 12.9998H2.0598ZM7.0998 18.3698L5.6698 19.7398C7.18458 21.0025 9.03907 21.7888 10.9998 21.9998V19.9998C9.57721 19.8158 8.23021 19.2529 7.0998 18.3698Z" fill="#444444"/>
</svg>

export const progressClose = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M12.9998 2.02979V4.04979C17.3898 4.58979 20.4998 8.57979 19.9598 12.9698C19.4998 16.6098 16.6398 19.4998 12.9998 19.9298V21.9298C18.4998 21.3798 22.4998 16.4998 21.9498 10.9998C21.4998 6.24979 17.7298 2.49979 12.9998 2.02979ZM10.9998 2.05979C9.0498 2.24979 7.1898 2.99979 5.6698 4.25979L7.0998 5.73978C8.2198 4.83978 9.5698 4.25979 10.9998 4.05979V2.05979ZM4.2598 5.66979C3.00946 7.18713 2.24001 9.04287 2.0498 10.9998H4.0498C4.2398 9.57979 4.7998 8.22978 5.6898 7.09978L4.2598 5.66979ZM2.0598 12.9998C2.2598 14.9598 3.0298 16.8098 4.2698 18.3298L5.6898 16.8998C4.80673 15.7694 4.24376 14.4224 4.0598 12.9998H2.0598ZM7.0998 18.3698L5.6698 19.7398C7.18458 21.0025 9.03907 21.7888 10.9998 21.9998V19.9998C9.57721 19.8158 8.23021 19.2529 7.0998 18.3698ZM14.5898 7.99979L11.9998 10.5898L9.4098 7.99979L7.9998 9.40979L10.5898 11.9998L7.9998 14.5898L9.4098 15.9998L11.9998 13.4098L14.5898 15.9998L15.9998 14.5898L13.4098 11.9998L15.9998 9.40979L14.5898 7.99979Z" fill="#444444"/>
</svg>

export const progressClock = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M12.9998 2.02979V4.04979C17.3898 4.58979 20.4998 8.57979 19.9598 12.9698C19.4998 16.6098 16.6398 19.4998 12.9998 19.9298V21.9298C18.4998 21.3798 22.4998 16.4998 21.9498 10.9998C21.4998 6.24979 17.7298 2.49979 12.9998 2.02979ZM10.9998 2.05979C9.0498 2.24979 7.1898 2.99979 5.6698 4.25979L7.0998 5.73978C8.2198 4.83978 9.5698 4.25979 10.9998 4.05979V2.05979ZM4.2598 5.66979C3.00946 7.18713 2.24001 9.04287 2.0498 10.9998H4.0498C4.2398 9.57979 4.7998 8.22978 5.6898 7.09978L4.2598 5.66979ZM2.0598 12.9998C2.2598 14.9598 3.0298 16.8098 4.2698 18.3298L5.6898 16.8998C4.80673 15.7694 4.24376 14.4224 4.0598 12.9998H2.0598ZM7.0998 18.3698L5.6698 19.7398C7.18458 21.0025 9.03907 21.7888 10.9998 21.9998V19.9998C9.57721 19.8158 8.23021 19.2529 7.0998 18.3698ZM12.4998 6.99979V12.2498L16.9998 14.9198L16.2498 16.1498L10.9998 12.9998V6.99979H12.4998Z" fill="#444444"/>
</svg>

 