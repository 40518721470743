// style
import './movingList.scss'
import {calendar, dashboardIcon, listIcon} from '../../../assets/general/generalIcons'

// react
import {FC, useState} from 'react'

// component
import Layout from '../../../components/general/layout/layout'
import TaskDashboard from '../../../components/general/taskDashboard/taskDashboard'
import TaskList from '../../../components/projects/general/taskList/taskList'
import MyTasksCalendar from '../../../components/projects/myTasks/myTasksCalendar/myTasksCalendar'
import SwitchButtons from '../../../components/general/switchButtons/switchButtons'
import CheckBox from '../../../components/general/checkBox/checkBox'

// redux
import { useAppSelector } from '../../../customHooks/redux'

// types
import {
	task,
	taskResponse,
	infoPopupTypes,
	stageResponse, switchButton,filterBar
} from '../../../types/general/generalTypes'

// network
import {
	companyWarehouseActionsUrl,
	companyWarehouseCalendarActionsUrl
} from '../../../utils/urls/warehouses/warehouseActions/warehouseActions'
import { authorizedRequest } from '../../../utils/queries'

// other
import { formatStageResponse, formatTaskResponse, moveItem } from '../../../assets/projects/projectsFunctions'

//translation
import { useTranslation } from 'react-i18next'

const movingList: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'warehouse.movingList' })

	const titleUrls = [
		{
			url: '/ri-business/warehouse/moving-list',
			title: t('movings')
		}
	]

	const infoPopup = {
		content: [{
			title: 'moving_list_info_title',
			description: 'moving_list_info_description',
			link: '/ri-manual/warehouses'
		}],
		type: infoPopupTypes.INFO
	}

	const [ activeToolBarIconId, setActiveToolBarIcon ] = useState(0)

	const [showClosed, setShowClosed] = useState(false)
	const { t:tr } = useTranslation('', { keyPrefix: 'projects.myTasks' })

	const [filterBar, setFilterBar] = useState<filterBar | undefined>()

	const toolBarButtons: switchButton[] = [
		{
			id: 0,
			icon: dashboardIcon
		},
		{
			id: 1,
			icon: listIcon
		},
		{
			id: 2,
			icon: calendar
		}
	]

	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1


	const loadTasks = async (stageId: number, searchRequest: string, page: number, showClosed: boolean) => {
		const response = await authorizedRequest(companyWarehouseActionsUrl(companyId) + `?type=moving&needle=${searchRequest}&page=${page}&status=${stageId}&show_closed=${showClosed}`, 'GET')
		const { result } = response
		return result.map((task: taskResponse) => formatTaskResponse(task))
	}

	const loadStages = async (showClosed: boolean) => {
		const response = await authorizedRequest(companyWarehouseActionsUrl(companyId) + `?type=moving&show_closed=${showClosed}`, 'PATCH')
		const { result } = response
		return result.map((stage: stageResponse) => formatStageResponse(stage))
	}

	const loadTaskCalendar = async () => {
		const response = await authorizedRequest(companyWarehouseCalendarActionsUrl(companyId)+'?type=moving', 'GET')
		const { result } = response
		return result.map((task: taskResponse) => formatTaskResponse(task))
	}

	const renderToolbar = (activeId: number, showClosedTasks:boolean)=> {
		switch(activeId) {
		case 0:
			return <TaskDashboard showClosed={showClosedTasks} loadStages={loadStages} loadTasks={loadTasks} isWarehouseAction={true} />
		case 1:
			return <TaskList setFilterBar={setFilterBar} showClosed={showClosedTasks} loadTasks={loadTasks}/>
		case 2:
			return <MyTasksCalendar showClosed={showClosedTasks} loadTasks={loadTaskCalendar} />
		}
	}

	return (
		<Layout
			header={{
				avatar: true,
			}}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			filterBar={filterBar}
		>
			<div className='my-tasks'>
				<div className="my-tasks-tool-bar">
					<SwitchButtons switchButtons={toolBarButtons} activeSwitchButtonId={activeToolBarIconId} setActiveSwitchButtonId={setActiveToolBarIcon} size='medium'/>
					<CheckBox label={tr('showClosed')} isChecked={showClosed} onClick={() => setShowClosed(prev => !prev)}/>
				</div>
				{renderToolbar(activeToolBarIconId, showClosed)}
			</div>

		</Layout>
	)
}

export default movingList