// styles and icons
import './createPipelineModal.scss'
import { errorAlert } from '../../../../../assets/general/generalIcons'

// react
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'

// components
import Modal from '../../../../general/modals/modal/modal'
import InputField from '../../../../general/inputField/inputField'
import PipelineStageEditor from '../general/pipelineStageEditor/pipelineStageEditor'
import TextArea from '../../../../general/textArea/textArea'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setCreatePipelineModalIsOpen } from '../../../../../redux/projects/pipelineList/modals'
import { setPipelineGuidanceModals, setPipelineGuidanceModalsClosed, setProjectsGuidancePipelinesModals } from '../../../../../redux/general/modals'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import {pipelinesManagePipelineResourceUrl} from '../../../../../utils/urls/projects/pipelines'

// types
import { pipeline, pipelineResponse } from '../../../../../types/projects/pipelineTypes'
import { currency, infoType } from '../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

type createPipelineModalProps = {
	pipelines: pipeline<Date | undefined>[]
	setPipelines: Dispatch<SetStateAction<pipeline<Date | undefined>[]>>
}

type formError = {
	pipelineName?: string
	stages?: string
}

const CreatePipelineModal : FC<createPipelineModalProps> = ({pipelines, setPipelines}) => {
	const { t } = useTranslation('', {keyPrefix: 'projects.pipelineList.modals.createPipelineModal'})  

	const { projectsGuidanceModals } = useAppSelector((state) => state.generalModals.guidanceModals)

	const activeGuidanceRef = useRef(false)

	const [pipelineName, setPipelineName] = useState('')
	const [description, setDescription] = useState('')
	const [stages, setStages] = useState<string[]>([])
	const { modalIsOpen } = useAppSelector(state => state.pipelineListModals.createPipeline)
	const { userCompanyData } = useAppSelector((state) => state.general)
	const [errors, setErrors] = useState<formError>({})
	
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [guideStep, setGuideStep] = useState<number>(1)

	const companyId: number = userCompanyData?.companyId || -1
	const dispatch = useAppDispatch()

	const closeModal = () => {

		if(activeGuidanceRef.current){
			dispatch(setProjectsGuidancePipelinesModals([false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
		}
		
		dispatch(setCreatePipelineModalIsOpen(false))
		setPipelineName('')
		setStages([])
		setErrors({})
		dispatch(setPipelineGuidanceModalsClosed())
		setGuideStep(1)
	}

	const checkErrors = () => {
		let hasErrors = false
		const addedStages = stages.filter(stage => stage !== '')
		if(pipelineName === '') {
			hasErrors = true
			setErrors(prevErrors => ({...prevErrors, pipelineName: t('enterPipelineNamePlease')}))
		}
		if(addedStages.length === 0) {
			hasErrors = true
			setErrors(prevErrors => ({...prevErrors, stages: t('addPipelineStages')}))
		}
		return hasErrors
	}
	const createPipeline = async ()=> {
		if(checkErrors()) return
		setIsLoading(true)
		
		try {
			const {result}: {result: pipelineResponse} = await authorizedRequest(pipelinesManagePipelineResourceUrl(companyId), 'POST', 'accessToken', {
				name: pipelineName,
				description: description,
				stages: stages.filter(stage => stage !== '').map((stage, index) => {
					return {
						name: stage,
						order_number: index+1,
						color: `#${Math.floor(Math.random()*16777215).toString(16).padStart(6, '0')}`
					}
				})
			})

			const pipeline : pipeline<Date | undefined> = {
				id: result.id,
				name: result.name,
				description: result.description,
				numberOfProjects: result.number_of_projects || 0,
				revenue: [{value: 0, currency: currency.USD}, {value: 0, currency: currency.UAH}, {value: 0, currency: currency.EUR}],
				earliestDeadline: undefined,
				deleteAccess: result.delete_access
			}
			setPipelines([pipeline, ...pipelines])
			setIsLoading(false)
			closeModal()			
		} catch(err) {
			console.log(err)
		}
	}


	
	useEffect(() => {
		if(projectsGuidanceModals.pipelines[2] && modalIsOpen){
			activeGuidanceRef.current = true
			dispatch(setProjectsGuidancePipelinesModals([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
		}
	}, [projectsGuidanceModals.pipelines, modalIsOpen])
	
	
	const nextGuideStep = () => {
		if (guideStep === 4) {
			dispatch(setPipelineGuidanceModalsClosed())
		} else {
			setGuideStep((prevStep) => prevStep + 1)
		}
	}

	return (
		<Modal 
			title={t('createPipeline')}
			open={modalIsOpen}
			closeModal={closeModal} 
			submitButton={{ text: t('create'), onClick: createPipeline }}
			isLoading={isLoading}>
			<div className="create-pipeline">
				<InputField 
					type="text" 
					info={errors.pipelineName ? {
						type: infoType.error,
						text: errors.pipelineName
					} : undefined}
					label={t('pipelineName')} 
					placeholder={t('pipelineName')} 
					value={pipelineName} 
					onChange={(e) => {
						setPipelineName(e.target.value)
						if (guideStep === 1) {
							nextGuideStep()
							dispatch(setPipelineGuidanceModals([false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
						} 
					}}
				/>
				<TextArea 
					label={t('description')} 
					placeholder={t('description')} 
					value={description} 
					setValue={(value) => {
						setDescription(value)
						if (guideStep === 2) {
							nextGuideStep()
							dispatch(setPipelineGuidanceModals([false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
						}
					}} 
				/>
				<PipelineStageEditor 
					stages={stages} 
					setStages={(value) => {
						setStages(value)
						if (guideStep === 3) {
							nextGuideStep()
							dispatch(setPipelineGuidanceModals([false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
						}
					}} 
				/>
				{errors.stages && 
				<div className='error-wrapper'>
					<div className='error-text'>
						{errorAlert}
						<p>{errors.stages}</p>
					</div>
				</div>
				}
			</div>
		</Modal>
	)
}

export default CreatePipelineModal