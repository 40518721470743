//styles and icons
import './navBarItem.scss'
import { arrowIcon } from '../../../../../assets/general/generalIcons'

//react
import { FC, useEffect, useState } from 'react'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setEmployeesGuidanceModalsClosed,setEmployeesGuidanceHierarchyModals, setEmployeesGuidanceSearchEmployeesModals, setEmployeesGuidanceProductivityModals, setProjectsGuidancePipelinesModals, setProjectsGuidanceModalsClosed, setProjectsGuidanceMyTasksModals, setPurchasesGuidanceProviderModals, setPurchasesGuidanceModalsClosed, setPurchasesGuidanceOrderModals, setSalesGuidanceCustomerModals, setSalesGuidanceModalsClosed, setSalesGuidanceOrderModals } from '../../../../../redux/general/modals'

//components
import InfoPopup from '../../../infoPopup/infoPopup'
import GuideModal from '../../../modals/guideModal/guideModal'
import NavBarItemSubcategory from './navBarItemSubcategory/navBarItemSubcategory'

//types
import { infoPopup, guidanceModalData, navbarCategory } from '../../../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

type navBarItemProps = navbarCategory & {
	index: number
	activeCategory: number
	infoPopup?: infoPopup
	setActiveCategory: React.Dispatch<React.SetStateAction<number>>
}

const NavBarItem: FC<navBarItemProps> = ({ name, icon, subCategories, index, activeCategory, setActiveCategory, infoPopup}) => {
	const dispatch = useAppDispatch()

	const { t } = useTranslation('', { keyPrefix: 'general.layout.navbar' })

	const { employeesGuidanceModals, projectsGuidanceModals, purchasesGuidanceModals, salesGuidanceModals } = useAppSelector((state) => state.generalModals.guidanceModals)

	const getGuidanceOpen = (): guidanceModalData | undefined => {

		switch(name){
		case 'employees':
			if(employeesGuidanceModals.hierarchy[3]){
				return {
					open: employeesGuidanceModals.hierarchy[3],
					next: () => { 
						dispatch(setEmployeesGuidanceHierarchyModals([false, false, false, false, true, false, false]))
					},
					close: () => { dispatch(setEmployeesGuidanceModalsClosed()) },
					text: 'employee1'
				}
			}else if(employeesGuidanceModals.searchEmployees[0]){
				return {
					open: employeesGuidanceModals.searchEmployees[0],
					next: () => { 
						dispatch(setEmployeesGuidanceSearchEmployeesModals([false, true, false, false, false, false, false, false]))
					},
					close: () => { dispatch(setEmployeesGuidanceModalsClosed()) },
					text: 'employee2'
				}
			}else if(employeesGuidanceModals.productivity[0]){
				return {
					open: employeesGuidanceModals.productivity[0],
					next: () => { 
						dispatch(setEmployeesGuidanceProductivityModals([false, true, false, false]))
					},
					close: () => { dispatch(setEmployeesGuidanceModalsClosed()) },
					text: 'employee2'
				}
			}

			return {
				open: false,
				next: () => { 
					dispatch(setEmployeesGuidanceModalsClosed())
				},
				close: () => { dispatch(setEmployeesGuidanceModalsClosed()) },
				text: ''
			}
		
		case 'projects':
			if(projectsGuidanceModals.pipelines[0]){
				return {
					open: projectsGuidanceModals.pipelines[0],
					next: () => { 
						dispatch(setProjectsGuidancePipelinesModals([false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
					},
					close: () => { dispatch(setProjectsGuidanceModalsClosed()) },
					text: 'project2'
				}
			}else if(projectsGuidanceModals.myTasks[0]){
				return {
					open: projectsGuidanceModals.myTasks[0],
					next: () => { 
						dispatch(setProjectsGuidanceMyTasksModals([false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
					},
					close: () => { dispatch(setProjectsGuidanceModalsClosed()) },
					text: 'project2'
				}
			}

			return {
				open: false,
				next: () => { 
					dispatch(setProjectsGuidanceModalsClosed())
				},
				close: () => { dispatch(setProjectsGuidanceModalsClosed()) },
				text: ''
			}
		
		case 'purchases':
			if(purchasesGuidanceModals.providers[0]){
				return {
					open: purchasesGuidanceModals.providers[0],
					next: () => { 
						dispatch(setPurchasesGuidanceProviderModals([false, true, false, false, false, false, false, false, false, false, false, false]))
					},
					close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
					text: 'project2'
				}
			}else if(purchasesGuidanceModals.orders[0]){
				return {
					open: purchasesGuidanceModals.orders[0],
					next: () => { 
						dispatch(setPurchasesGuidanceOrderModals([false, true, false, false, false, false, false, false, false, false]))
					},
					close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
					text: 'project2'
				}
			}

			return {
				open: false,
				next: () => { 
					dispatch(setPurchasesGuidanceModalsClosed())
				},
				close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
				text: ''
			}

		case 'sales':
			if(salesGuidanceModals.customers[0]){
				return {
					open: salesGuidanceModals.customers[0],
					next: () => {
						dispatch(setSalesGuidanceCustomerModals([false, true, false, false, false, false, false, false, false, false, false]))
					},
					close: () => { dispatch(setSalesGuidanceModalsClosed()) },
					text: 'sales'
				}
			}else if(salesGuidanceModals.orders[0]){
				return {
					open: salesGuidanceModals.orders[0],
					next: () => {
						dispatch(setSalesGuidanceOrderModals([false, true, false, false, false, false, false, false, false, false]))
					},
					close: () => { dispatch(setSalesGuidanceModalsClosed()) },
					text: 'sales'
				}
			}

			return {
				open: false,
				next: () => { 
					dispatch(setSalesGuidanceModalsClosed())
				},
				close: () => { dispatch(setSalesGuidanceModalsClosed()) },
				text: ''
			}
		
		

			
		// case 'projects':
		// 	return {
		// 		open: pipelineGuidanceModals[0],
		// 		next: () => { dispatch(setPipelineGuidanceModals([])) },
		// 		close: () => { dispatch(setPipelineGuidanceModalsClosed()) },
		// 		text: 'projects1'
		// 	}
		// case 'purchases':
		// 	return {
		// 		open: purchasesGuidanceModals[0],
		// 		update: (value: boolean[]) => { dispatch(setPurchasesGuidanceModals(value as [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean])) },
		// 		close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
		// 		text: 'purchases1'
		// 	}
		// case 'sales':
		// 	return {
		// 		open: salesGuidanceModals[0],
		// 		update: (value: boolean[]) => { dispatch(setSalesGuidanceModals(value as [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean])) },
		// 		close: () => { dispatch(setSalesGuidanceModalsClosed()) },
		// 		text: 'sales1'
		// 	}
		// case 'finance':
		// 	return {
		// 		open: financeGuidanceModals[0],
		// 		update: (value: boolean[]) => { dispatch(setFinanceGuidanceModals(value as [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean])) },
		// 		close: () => { dispatch(setFinanceGuidanceModalsClosed()) },
		// 		text: 'finance1'
		// 	}
		// case 'warehouses':
		// 	return {
		// 		open: warehouseGuidanceModals[0],
		// 		update: (value: boolean[]) => { dispatch(setWarehouseGuidanceModals(value as [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean])) },
		// 		close: () => { dispatch(setWarehouseGuidanceModalsClosed()) },
		// 		text: 'warehouses1'
		// 	}
		default:
			return undefined
		}
	}

	const getSubcategoryGuidance = (url: string): guidanceModalData | undefined => {
		switch(url){
		case '/ri-business/employees/hierarchy':
			return {
				open: employeesGuidanceModals.hierarchy[4],
				next: () => { dispatch(setEmployeesGuidanceHierarchyModals([false, false, false, false, false, true, false])) },
				close: () => { dispatch(setEmployeesGuidanceModalsClosed()) },
				text: 'employe2'
			}
		case '/ri-business/employees/search-employee':
			return {
				open: employeesGuidanceModals.searchEmployees[1],
				next: () => { dispatch(setEmployeesGuidanceSearchEmployeesModals([false, false, true, false, false, false, false, false])) },
				close: () => { dispatch(setEmployeesGuidanceModalsClosed()) },
				text: 'employe2'
			}
		case '/ri-business/employees/productivity':
			return {
				open: employeesGuidanceModals.productivity[1],
				next: () => { dispatch(setEmployeesGuidanceProductivityModals([false, false, true, false])) },
				close: () => { dispatch(setEmployeesGuidanceModalsClosed()) },
				text: 'employe2'
			}
		case '/ri-business/projects/pipelines':
			return {
				open: projectsGuidanceModals.pipelines[1],
				next: () => { dispatch(setProjectsGuidancePipelinesModals([false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])) },
				close: () => { dispatch(setProjectsGuidanceModalsClosed()) },
				text: 'projects2'
			}
		case '/ri-business/projects/my-tasks':
			return {
				open: projectsGuidanceModals.myTasks[1], 
				next: () => { dispatch(setProjectsGuidanceMyTasksModals([false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false])) },
				close: () => { dispatch(setProjectsGuidanceModalsClosed()) },
				text: 'projects2'
			}
		case '/ri-business/purchase/provider-list':
			return {
				open: purchasesGuidanceModals.providers[1],
				next: () => { dispatch(setPurchasesGuidanceProviderModals([false, false, true, false, false, false, false, false, false, false, false, false])) },
				close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
				text: 'purchases2'
			}
		case '/ri-business/purchase/order-list':
			return {
				open: purchasesGuidanceModals.orders[1],
				next: () => { dispatch(setPurchasesGuidanceOrderModals([false, false, true, false, false, false, false, false, false, false])) },
				close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
				text: 'purchases2'
			}
		case '/ri-business/sales/customer-list':
			return {
				open: salesGuidanceModals.customers[1],
				next: () => { dispatch(setSalesGuidanceCustomerModals([false, false, true, false, false, false, false, false, false, false, false])) },
				close: () => { dispatch(setSalesGuidanceModalsClosed()) },
				text: ''
			}
		case '/ri-business/sales/order-list': 
			return {
				open: salesGuidanceModals.orders[1],
				next: () => { dispatch(setSalesGuidanceOrderModals([false, false, true, false, false, false, false, false, false, false])) },
				close: () => { dispatch(setSalesGuidanceModalsClosed()) },
				text: ''
			}
		// case 'sales':
		// 	return {
		// 		open: salesGuidanceModals[1],
		// 		update: (value: boolean[]) => { dispatch(setSalesGuidanceModals(value as [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean])) },
		// 		close: () => { dispatch(setSalesGuidanceModalsClosed()) },
		// 		text: 'sales2'
		// 	}
		// case 'finance':
		// 	return {
		// 		open: financeGuidanceModals[1],
		// 		update: (value: boolean[]) => { dispatch(setFinanceGuidanceModals(value as [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean])) },
		// 		close: () => { dispatch(setFinanceGuidanceModalsClosed()) },
		// 		text: 'finance2'
		// 	}
		// case 'warehouses':
		// 	return {
		// 		open: warehouseGuidanceModals[1],
		// 		update: (value: boolean[]) => { dispatch(setWarehouseGuidanceModals(value as [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean])) },
		// 		close: () => { dispatch(setWarehouseGuidanceModalsClosed()) },
		// 		text: 'warehouses2'
		// 	}
		default:
			return undefined
		}
	}

	const [guidanceOpen, setGuidanceOpen] = useState<guidanceModalData | undefined>(getGuidanceOpen())
	
	const onClickCallback = (index: number) => {
		if(activeCategory != index){
			setActiveCategory(index) 
		}else{
			setActiveCategory(0)
		}
	}

	useEffect(() => {
		setGuidanceOpen(getGuidanceOpen())
	}, [
		employeesGuidanceModals.hierarchy,
		employeesGuidanceModals.searchEmployees,
		projectsGuidanceModals.pipelines,
		projectsGuidanceModals.myTasks,
		purchasesGuidanceModals.providers,
		purchasesGuidanceModals.orders,
		salesGuidanceModals.customers,
		salesGuidanceModals.orders
	])



	return (
		<div className={`app-navitem ${activeCategory === index  ? 'selected' : ''}`}>
			<div className="navitem-header" onClick={() => {
				onClickCallback(index)
				
				if(guidanceOpen && guidanceOpen.open){
					guidanceOpen.next()
				}
			}}>
				<div className="navitem-header-text" onClick={(e) => {
					e.stopPropagation()
				}}>
					<span className="navitem-header-icons">{icon}</span>
					<p>{t(name)}</p>
				</div>
				<div
					className="navitem-icons" 
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					{infoPopup ? <InfoPopup {...infoPopup}/> : null}
					<span className="navitem-icons-arrow" onClick={() => {
						onClickCallback(index)
				
						if(guidanceOpen && guidanceOpen.open){
							guidanceOpen.next()
						}
					}}>{arrowIcon}</span>
				</div>	
			</div>

			<div className='navitem-category-container'>
				{subCategories.map((item) => 
				{	 
					const subcategoryGuidance = getSubcategoryGuidance(item.url)
					return (
						<NavBarItemSubcategory
							url={item.url}
							name={item.name}
							guidance={subcategoryGuidance}
							active={activeCategory === index}
						/>
					)
				})
				}
			</div>

			{guidanceOpen && <GuideModal
				open={guidanceOpen.open}
				closeModal={guidanceOpen.close}
			>
				{guidanceOpen.text}
			</GuideModal>}	
		</div>
	)
}

export default NavBarItem
