//styles
import './hierarchy.scss'

//icons
import { deleteIcon } from '../../../assets/general/generalIcons'
import { moveEmployeeButton } from '../../../assets/employees/general/generalIcons'
import { refresh, chat } from '../../../assets/general/generalIcons'

//react
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//components
import Layout from '../../../components/general/layout/layout'
import HierarchyTable from '../../../components/employees/hierarchy/hierarchyTable/hierarchyTable'
import HireEmployee from '../../../components/employees/general/modals/hireEmployee/hireEmployee'
import FireEmployee from '../../../components/employees/general/modals/fireEmployee/fireEmployee'
import MoveEmployee from '../../../components/employees/general/modals/moveEmployee/moveEmployee'
import MoveEmployeeConfirmation from '../../../components/employees/general/modals/moveEmployee/moveEmployeeConfirmation/moveEmployeeConfirmation'
import OrganizationChart from '../../../components/employees/hierarchy/organizationChart/organizationChart'
import ResignEmployee from '../../../components/employees/general/modals/resignEmployee/resignEmployee'

//redux
import {useAppDispatch} from '../../../customHooks/redux'
import {
	setFireEmployee,
	setFireEmployeeModalIsOpen,
	setHireEmployeeEmployeeId,
	setHireEmployeeLeaderId,
	setHireEmployeeModalIsOpen,
	setMoveEmployeeId,
	setMoveEmployeeModalIsOpen,
	setMoveEmployeeNewLeaderId,
	setMoveEmployeeTeam,
	setResignEmployeeModalIsOpen
} from '../../../redux/employees/general/modals'

//types
import { employee } from '../../../types/employees/hierarchyTypes'
import { infoPopupTypes, switchButton } from '../../../types/general/generalTypes'

//translation
import { useTranslation } from 'react-i18next'

const Hierachy: FC = () => {

	const { t } = useTranslation('', { keyPrefix: 'employees.hierarchy' })

	const navigate = useNavigate()

	//global variables
	const userId: number = parseInt(localStorage.getItem('userId') || '-1')
	
	const dispatch = useAppDispatch()

	const [activeHierarchyView, setActiveHierarchyView] = useState('table')	

	const hierarchyTabButtons = [
		{
			active: activeHierarchyView === 'table',
			onClick: ()=>{setActiveHierarchyView('table')},
			text: t('tableView')
		},
		{
			active: activeHierarchyView === 'organization',
			onClick: ()=>{setActiveHierarchyView('organization')},
			text: t('organizationChart')
		}
	]
	
	const titleUrls = [
		{
			url: '/ri-business/employees/hierarchy',
			title: t('title')
		}
	]

	const infoPopup = {
		content: [{
			title: 'hierarchy_info_title',
			description: 'hierarchy_info_description',
			link: '/ri-manual/employees/hierarchy'
		}],
		type: infoPopupTypes.INFO
	}

	const replaceEmployee = (employee: employee) => {
		dispatch(setHireEmployeeModalIsOpen(true))
		dispatch(setHireEmployeeLeaderId(undefined))
		dispatch(setHireEmployeeEmployeeId(employee.id))
	}

	const moveEmployee = (employeeId:number, isTeamLeader:boolean) => {
		dispatch(setMoveEmployeeModalIsOpen(true))
		dispatch(setMoveEmployeeId(employeeId))
		dispatch(setMoveEmployeeNewLeaderId(undefined))
		dispatch(setMoveEmployeeTeam(isTeamLeader))
	} 

	const fireEmployee = (employee: employee) => {
		dispatch(setFireEmployeeModalIsOpen(true))
		dispatch(setFireEmployee(employee))
	}

	const resignEmployee = ()=>{
		dispatch(setResignEmployeeModalIsOpen(true))
	}
	
	const getEmployeeActions: (employee: employee) => switchButton[] = (employee) => {
		const buttons = [ 
			{
				id: 0,
				icon: chat,
				onClick: (event: React.MouseEvent) => {
					event.stopPropagation()
					navigate(`/ri-business/chats/${employee.chatId}`)
				}
			},
			{
				id: 1,
				icon: deleteIcon,
				onClick: (event: React.MouseEvent) => {
					event.stopPropagation()
					fireEmployee(employee)
				}
			},
			{
				id: 2,
				icon: moveEmployeeButton,
				onClick: (event: React.MouseEvent) => {
					event.stopPropagation()
					moveEmployee(employee.id, employee.isTeamLeader)
				}
			},
			{
				id: 3,
				icon: refresh,
				onClick: (event: React.MouseEvent) => {
					event.stopPropagation()
					replaceEmployee(employee)
				}
			},
			{
				id: 4,
				icon: deleteIcon,
				onClick: (event: React.MouseEvent) => {
					event.stopPropagation()
					resignEmployee()
				}
			},
		]


		const result: switchButton[] = []
		const fired = employee.userId === null

		if(employee.userId !== userId && !fired){
			result.push(buttons[0])
		}

		if(employee.fireAccess && employee.userId !== userId){
			result.push(buttons[1])
		}

		if(employee.moveAccess && employee.userId !== userId){
			result.push(buttons[2])
		}

		if(employee.replaceAccess && employee.userId === null){
			result.push(buttons[3])
		}
		
		if(employee.userId === userId){
			result.push(buttons[4])
		}

		return result
	}

	const renderTab = () => {
		switch(activeHierarchyView) {
		case 'table':
			return <HierarchyTable getEmployeeActions={getEmployeeActions} />
		case 'organization':
			return <OrganizationChart getEmployeeActions={getEmployeeActions}/>
		default:
			return null
		}
	}

	return (
		<Layout
			header={{
				avatar: true,
			}}
			tabButtons={hierarchyTabButtons}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>
			{
				renderTab()
			}
			<HireEmployee getEmployeeActions={getEmployeeActions} />
			<FireEmployee getEmployeeActions={getEmployeeActions} />
			<MoveEmployee />
			<ResignEmployee/>
			<MoveEmployeeConfirmation getEmployeeActions={getEmployeeActions} />

		</Layout>
	)
}

export default Hierachy