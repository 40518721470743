// styles and icons
import './createProviderModal.scss'

// react
import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useRef, useState } from 'react'

// components
import Modal from '../../../../general/modals/modal/modal'
import InputField from '../../../../general/inputField/inputField'
import TextArea from '../../../../general/textArea/textArea'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setCreateProviderModalIsOpen } from '../../../../../redux/purchase/providersList/modals'
import { setPurchasesGuidanceModalsClosed, setPurchasesGuidanceProviderModals } from '../../../../../redux/general/modals'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import { companyProvidersUrl } from '../../../../../utils/urls/purchases/provider'

// types
import { infoType, relationshipTypes } from '../../../../../types/general/generalTypes'
import { provider, providerResponse } from '../../../../../types/purchase/providerTypes'

// translation
import { useTranslation } from 'react-i18next'


type formErrors =  {
	name?: string
    email?: string
    phoneNumber?: string
}

type createProviderModalProps = {
	providerList: provider[]
	setProviderList: Dispatch<SetStateAction<provider[]>>
}

const CreateProviderModal: FC<createProviderModalProps> = ({providerList, setProviderList}) => {	
	const {t} = useTranslation('', { keyPrefix: 'purchase.providerList.modals.createProviderModal' })

	const dispatch = useAppDispatch()
	const { modalIsOpen } = useAppSelector((state) => state.providerListModal.createProviderModal)
	const { userCompanyData } = useAppSelector((state) => state.general)
	const { purchasesGuidanceModals } = useAppSelector(state => state.generalModals.guidanceModals)      

	const companyId: number = userCompanyData?.companyId || -1

	const formRef = useRef<HTMLFormElement | null>(null)
	const activeGuidance = useRef(false)

	const [ name, setName ] = useState<string>('')
	const [ email, setEmail ] = useState<string>('')
	const [ phoneNumber, setPhoneNumber ] = useState<string>('')
	const [ description, setDescription ] = useState<string>('')

	const [errors, setErrors] = useState<formErrors>({})

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const closeModal = () => {
		dispatch( setCreateProviderModalIsOpen( false ))
		setName('')
		setEmail('')
		setPhoneNumber('')
		setDescription('')

		if(activeGuidance.current){
			dispatch(setPurchasesGuidanceProviderModals([false, false, false, true, false, false, false, false, false, false, false, false]))
		}
	}

	const checkErrors = () => {
		let result = true

		if(name === ''){
			setErrors(prevErrors => ({...prevErrors, name: t('pleaseEnterAName')}))
			result = false
		}
		if(email === ''){
			setErrors(prevErrors=> ({...prevErrors, email: t('pleaseEnterAnEmail')}))
			result = false
		}
		if(phoneNumber === ''){
			setErrors(prevErrors => ({...prevErrors, phoneNumber: t('pleaseEnterAPhoneNumber')}))
			result = false
		}

		return result
	}

	const handleSubmit = async ( e: FormEvent<HTMLFormElement> ) => {
		e.preventDefault()

		setErrors({})

		setIsLoading(true)

		if ( checkErrors() ) {
			const {result} = await authorizedRequest( companyProvidersUrl(companyId), 'POST', 'accessToken', {
				data: [
					{ 
						company_id: companyId, 
						email, 
						name, 
						phone: phoneNumber, 
						description, 
						relationship_type: relationshipTypes.PROVIDER 
					}
				]
			})
			if(result.length > 0){
				const formatedProviders: provider[] = result.map((provider: providerResponse) => {
					return {
						id: provider.relationship_id,
						relationshipId: provider.relationship_id,
						description: provider.description,
						name: provider.name,
						avatar: provider.avatar,
						labels: provider.labels,
						deleteAccess: provider.delete_access
					}
				})
				setProviderList([...formatedProviders, ...providerList])
			}
			closeModal()
		}
		setIsLoading(false)
	}

	useEffect(() => {

		if(purchasesGuidanceModals.providers[2] && modalIsOpen){
			dispatch(setPurchasesGuidanceModalsClosed())
			activeGuidance.current = true
		}

	}, [modalIsOpen, purchasesGuidanceModals.providers])

	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			title={t('createAProvider')}
			submitButton={{
				text: t('create'),
				onClick: () => {
					if ( formRef.current ) formRef.current.requestSubmit()
				}
			}}
			isLoading={isLoading}
		>

			<form
				className='create-provider-container'
				ref={formRef}
				onSubmit={handleSubmit}
			>
				<div className='form-elements-wrapper'>
					<InputField
						type='text'
						label={t('name')}
						placeholder={t('name')}
						value={ name }
						onChange={ e => {
							setName( e.target.value ) 
						}}
						info={errors.name ? {
							type: infoType.error,
							text: errors.name
						} : undefined}
					/>

					<InputField
						type='text'
						label={t('email')}
						placeholder={t('email')}
						value={ email }
						onChange={ e => {
							setEmail( e.target.value )
						}}
						info={errors.email ? {
							type: infoType.error,
							text: errors.email
						} : undefined}
					/>

					<InputField
						type='text'
						label={t('phoneNumber')}
						placeholder={t('phoneNumber')}
						value={ phoneNumber }
						onChange={ e => {
							setPhoneNumber( e.target.value ) 
						}}
						info={errors.phoneNumber ? {
							type: infoType.error,
							text: errors.phoneNumber
						} : undefined}
					/>

					<TextArea
						label={t('description')}
						placeholder={t('description')}
						value={description}
						setValue={ val => {
							setDescription(val)
						}}
					/>
				</div>
			</form>
		</Modal>
	)
}

export default CreateProviderModal
