// react
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// components
import Layout from '../../../components/general/layout/layout'
import OrderDetails from '../../../components/general/order/orderDetails/orderDetails'
import OrderTracking from '../../../components/general/order/orderTracking/orderTracking'
import ErrorList from '../../../components/general/errorList/errorList'

// types
import { button, error } from '../../../types/general/generalTypes'

//redux
import { useAppSelector, useAppDispatch } from '../../../customHooks/redux'
import { setPurchasesGuidanceModalsClosed, setPurchasesGuidanceOrderModals, setSalesGuidanceModalsClosed, setSalesGuidanceOrderModals } from '../../../redux/general/modals'

//translation
import { useTranslation } from 'react-i18next'

const Order: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'order' })

	const { purchasesGuidanceModals, salesGuidanceModals } = useAppSelector(state => state.generalModals.guidanceModals) 

	const dispatch = useAppDispatch()

	const [ activeOrderSection, setActiveOrderSection ] = useState('details')
	const [ projectId, setProjectId ] = useState<number>()

	const [ errors, setErrors ] = useState<error[]>([])

	const navigate = useNavigate()

	const orderId = parseInt(useParams().orderId || '-1')

	const [orderDetailTabButton, setOrderDetailTabButton] = useState<button[]>([
		{
			active: 'details' === activeOrderSection,
			text: t('details'),
			onClick: () => {setActiveOrderSection('details')},
			guidance: {
				open: purchasesGuidanceModals.orders[6] || salesGuidanceModals.orders[6],
				close: () => { 
					dispatch(setPurchasesGuidanceModalsClosed()) 
					dispatch(setSalesGuidanceModalsClosed())
				},
				next: () => {
					if(purchasesGuidanceModals.orders[6]){
						dispatch(setPurchasesGuidanceOrderModals([false, false, false, false, false, false, false, true, false, false]))
					}else if(salesGuidanceModals.orders[6]){
						dispatch(setSalesGuidanceOrderModals([false, false, false, false, false, false, false, true, false, false]))
					}
				},
				text: ''
			}
		},
		{
			active: 'tracking' === activeOrderSection,
			text: t('tracking'),
			onClick: () => {setActiveOrderSection('tracking')},
			guidance: {
				open: purchasesGuidanceModals.orders[7] || salesGuidanceModals.orders[7],
				close: () => {
					dispatch(setPurchasesGuidanceModalsClosed())
					dispatch(setSalesGuidanceModalsClosed())
				},
				next: () => {
					if(purchasesGuidanceModals.orders[7]){
						dispatch(setPurchasesGuidanceOrderModals([false, false, false, false, false, false, false, false, true, false]))
					}else if(salesGuidanceModals.orders[7]){
						dispatch(setSalesGuidanceOrderModals([false, false, false, false, false, false, false, false, true, false]))
					}
				},
				text: ''
			}
		},
		{
			active: 'errors' === activeOrderSection,
			text: t('errors'),
			onClick: () => {setActiveOrderSection('errors')},
			guidance: {
				open: purchasesGuidanceModals.orders[8] || salesGuidanceModals.orders[8],
				close: () => {
					dispatch(setPurchasesGuidanceModalsClosed())
					dispatch(setSalesGuidanceModalsClosed())
				},
				next: () => {
					if(purchasesGuidanceModals.orders[8]){
						dispatch(setPurchasesGuidanceOrderModals([false, false, false, false, false, false, false, false, false, true]))
					}else if(salesGuidanceModals.orders[8]){
						dispatch(setSalesGuidanceOrderModals([false, false, false, false, false, false, false, false, false, true]))
					}
				},
				text: ''
			}
		},
		{
			active: 'project' === activeOrderSection,
			text: t('project'),
			onClick: () => { navigate(`/ri-business/projects/project/${projectId}`) },
			guidance: {
				open: purchasesGuidanceModals.orders[9] || salesGuidanceModals.orders[9],
				close: () => {
					dispatch(setPurchasesGuidanceModalsClosed())
					dispatch(setSalesGuidanceModalsClosed())
				},
				next: () => {
					if(purchasesGuidanceModals.orders[9]){
						dispatch(setPurchasesGuidanceOrderModals([false, false, false, false, false, false, false, false, false, false]))
					}else if(salesGuidanceModals.orders[9]){
						dispatch(setSalesGuidanceOrderModals([false, false, false, false, false, false, false, false, false, false]))
					}
				},
				text: ''
			}
		}
	])

	useEffect(() => {
		setOrderDetailTabButton([
			{
				active: 'details' === activeOrderSection,
				text: t('details'),
				onClick: () => {setActiveOrderSection('details')},
				guidance: {
					open: purchasesGuidanceModals.orders[6],
					close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
					next: () => { dispatch(setPurchasesGuidanceOrderModals([false, false, false, false, false, false, false, true, false, false])) },
					text: ''
				}
			},
			{
				active: 'tracking' === activeOrderSection,
				text: t('tracking'),
				onClick: () => {setActiveOrderSection('tracking')},
				guidance: {
					open: purchasesGuidanceModals.orders[7],
					close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
					next: () => { dispatch(setPurchasesGuidanceOrderModals([false, false, false, false, false, false, false, false, true, false])) },
					text: ''
				}
			},
			{
				active: 'errors' === activeOrderSection,
				text: t('errors'),
				onClick: () => {setActiveOrderSection('errors')},
				guidance: {
					open: purchasesGuidanceModals.orders[8],
					close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
					next: () => { dispatch(setPurchasesGuidanceOrderModals([false, false, false, false, false, false, false, false, false, true])) },
					text: ''
				}
			},
			{
				active: 'project' === activeOrderSection,
				text: t('project'),
				onClick: () => { navigate(`/ri-business/projects/project/${projectId}`) },
				guidance: {
					open: purchasesGuidanceModals.orders[9],
					close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
					next: () => { dispatch(setPurchasesGuidanceOrderModals([false, false, false, false, false, false, false, false, false, false])) },
					text: ''
				}
			}
		])
	}, [purchasesGuidanceModals.orders, salesGuidanceModals.orders, activeOrderSection])

	return (
		<Layout header={{
			avatar: true,
			title: t('title', { count: orderId })
		}} tabButtons={orderDetailTabButton} >
			{activeOrderSection === 'details' ? <OrderDetails setErrors={setErrors} setProjectId={(value) => {setProjectId(value)}} /> : null}
			{activeOrderSection === 'tracking' ? <OrderTracking projectId={projectId} /> : null}
			{activeOrderSection === 'errors' ? <ErrorList errors={errors} /> : null}
		</Layout>
	)
}

export default Order