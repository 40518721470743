//styles
import './salesOrderList.scss'
import { listIcon, tableIcon } from '../../../assets/general/generalIcons'

// react
import { FC, useEffect, useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import Pagination from '../../../components/general/pagination/pagination'
import CreateSalesOrderModal from '../../../components/sales/salesOrderList/modals/createSalesOrderModal/createSalesOrderModal'
import SalesOrderCardList from '../../../components/sales/salesOrderList/salesOrderCardList/salesOrderCardList'
import SwitchButtons from '../../../components/general/switchButtons/switchButtons'
import OrderTable from '../../../components/general/tableViews/orderTable/orderTable'

// types
import {
	filterBar,
	filters,
	infoPopupTypes,
	order,
	switchButton,
} from '../../../types/general/generalTypes'

//network
import {
	getSalesOrdersUrl,
	getSalesOrdersFiltersUrl,
} from '../../../utils/old_urls/sales/salesOrderListUrls'
import { authorizedRequest } from '../../../utils/queries'

// translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setCreateSalesOrderModalIsOpen } from '../../../redux/sales/salesOrderList/modals'
import { setSalesGuidanceModalsClosed, setSalesGuidanceOrderModals } from '../../../redux/general/modals'

//other
import {
	createFilteringRequest,
	formatFilters,
	formatOrderResponse,
	getItemsPerColumn,
} from '../../../assets/general/generalFunctions'

const SalesOrderList: FC = () => {
	const dispatch = useAppDispatch()
	const { userCompanyData } = useAppSelector((state) => state.general)
	const { salesGuidanceModals } = useAppSelector((state) => state.generalModals.guidanceModals)

	const companyId: number = userCompanyData?.companyId || -1

	const { t } = useTranslation('', { keyPrefix: 'sales.salesOrderList' })

	const [orders, setOrders] = useState<order[]>([])
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn(2))

	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)
	const [activeViewId, setActiveViewId] = useState(0)

	const titleUrls = [
		{
			url: '/ri-business/sales/order-list',
			title: t('saleOrders'),
		}
	]

	const infoPopup = {
		content: [
			{
				title: 'sales_order_list_info_title',
				description: 'sales_order_list_info_description',
				link: '/ri-manual/sales',
			}
		],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(
				getSalesOrdersFiltersUrl(companyId),
				'GET'
			)

			const filterResult = result.filters
			const createAccess = result.create_access

			const formatedFilters: filters[] = formatFilters(filterResult)

			console.warn(formatedFilters)

			setFilterBar({
				filters: formatedFilters,
				addButton: createAccess && {
					text: t('create'),
					active: true,
					onClick: () => {
						dispatch(setCreateSalesOrderModalIsOpen(true))
					},
				},
				onSearch: onSearch,
				guidance: {
					open: salesGuidanceModals.orders[2],
					close: () => {
						dispatch(setSalesGuidanceModalsClosed())
					},
					next: () => {
					},
					text: ''
				}
			})
		} finally {
			setFilterBarLoading(false)
		}
	}
	const loadData = async (
		page: number,
		request: string,
		filters: filters[]
	) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)
			const { result } = await authorizedRequest(
				getSalesOrdersUrl(companyId) +
					`?page=${page}&per_page=${10}&needle=${request}&type=sales` +
					filteringRequest,
				'GET'
			)
			if (result.length > 0) {
				const loadData: order[] = result.map(formatOrderResponse)

				return loadData
			}
			return []
		} finally {
			setLoading(false)
		}
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters).then((result) => {
			setOrders([...result])
			if (result.length > 0) {
				setPage(page + 1)
			} else {
				setLastPage(true)
			}
		})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page, searchRequest, activeFilters).then((result) => {
				setOrders([...orders, ...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
		}
	}

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn(2))
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const [viewButtons, setViewButtons] = useState<switchButton[]>([
		{
			id: 0,
			icon: listIcon,
			guidance: {
				open: salesGuidanceModals.orders[4],
				close: () => {
					dispatch(setSalesGuidanceModalsClosed())
				},
				next: () => {
					dispatch(setSalesGuidanceOrderModals([false, false, false, false, false, true, false, false, false, false]))
				},
				text: ''
			}
		},
		{
			id: 1,
			icon: tableIcon,
			guidance: {
				open: salesGuidanceModals.orders[3],
				close: () => {
					dispatch(setSalesGuidanceModalsClosed())
				},
				next: () => {
					dispatch(setSalesGuidanceOrderModals([false, false, false, false, true, false, false, false, false, false]))
				},
				text: ''
			}
		},
	])

	useEffect(() => {


		setViewButtons([
			{
				id: 0,
				icon: listIcon,
				guidance: {
					open: salesGuidanceModals.orders[4],
					close: () => {
						dispatch(setSalesGuidanceModalsClosed())
					},
					next: () => {
						dispatch(setSalesGuidanceOrderModals([false, false, false, false, false, true, false, false, false, false]))
					},
					text: ''
				}
			},
			{
				id: 1,
				icon: tableIcon,
				guidance: {
					open: salesGuidanceModals.orders[3],
					close: () => {
						dispatch(setSalesGuidanceModalsClosed())
					},
					next: () => {
						dispatch(setSalesGuidanceOrderModals([false, false, false, false, true, false, false, false, false, false]))
					},
					text: ''
				}
			},
		])

		console.log('data', salesGuidanceModals)

		setFilterBar({
			...filterBar,
			guidance: {
				open: salesGuidanceModals.orders[2],
				close: () => {
					dispatch(setSalesGuidanceModalsClosed())
				},
				next: () => {
				},
				text: ''
			}
		})

		
	}, [salesGuidanceModals.orders])

	const renderComponent = () => {
		switch (activeViewId) {
		case 0:
			return (
				<SalesOrderCardList
					orders={orders}
					loading={loading}
					itemsPerColumn={itemsPerColumn}
				/>
			)
		case 1:
			return (
				<OrderTable
					preloadedOrders={{ orders: orders, setOrders: setOrders }}
					parent={{ type: 'sales' }}
				/>
			)
		}
	}

	return (
		<Layout
			header={{
				avatar: true,
				title: 'Sale Orders',
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className="sale-order-list">
				<SwitchButtons
					switchButtons={viewButtons}
					activeSwitchButtonId={activeViewId}
					setActiveSwitchButtonId={setActiveViewId}
					size="medium"
				/>
				<Pagination
					onLoadMore={handleLoadMore}
					loading={loading}
					showLoader={false}
				>
					{renderComponent()}
				</Pagination>
			</div>
			<CreateSalesOrderModal orders={orders} setOrders={setOrders}/>
		</Layout>
	)
}

export default SalesOrderList
