// components
import ManualLayout from '../../../components/general/manualLayout/manualLayout'

//types
import {manualData} from '../../../types/types'

//translation
import { useTranslation } from 'react-i18next'

const SettingsManual = () => {
	const { t } = useTranslation('', { keyPrefix: 'apps.landing.pages.manual.settings' })
	const data: manualData[] = [
		{
			title: t('settingsTitle'),
			text: t('settingsText')
		},
		{
			title: t('settingsAddPhoneNumberTitle'),
			text: t('settingsAddPhoneNumberText')
		},
		{
			title: t('settingsAddEmailTitle'),
			text: t('settingsAddEmailText')

		},
		{
			title: t('settingsAddLabelTitle'),
			text: t('settingsAddLabelText')
		},
		{
			title: t('settingsAddPositionTitle'),
			text: t('settingsAddPositionText'),
		}
	]
	
	return (
		<ManualLayout 
			heading={t('generalSettingsTitle')}
			data={data}
		/>
	)
}

export default SettingsManual
