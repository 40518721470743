// styles and icons
import './pipelineList.scss'

// react
import { FC, useEffect, useState } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import Pagination from '../../../components/general/pagination/pagination'
import PipelineListItem from '../../../components/projects/pipelineList/pipelineListItem/pipelineListItem'
import ListSummaryCard from '../../../components/general/listSummaryCard/listSummaryCard'
import ColumnLayout from '../../../components/general/columnLayout/columnLayout'
import CreatePipelineModal from '../../../components/projects/pipelineList/modals/createPipelineModal/createPipelineModal'
import DeletePipelineConfirmationModal from '../../../components/projects/pipelineList/modals/deletePipelineConfirmationModal/deletePipelineConfirmationModal'
import OverviewCardSkeleton from '../../../components/general/skeletons/overviewCard/overviewCardSkeleton'
import ListCardSkeleton from '../../../components/general/skeletons/listCard/listCardSkeleton'
import GuideModal from '../../../components/general/modals/guideModal/guideModal'

// types
import { pipeline, pipelineResponse } from '../../../types/projects/pipelineTypes'
import { filterBar, currency, filters, infoPopupTypes } from '../../../types/general/generalTypes'

//network
import { authorizedRequest } from '../../../utils/queries'
import {
	getPipelinesOverviewResourceUrl,
	pipelinesGetFiltersResourceUrl,
	pipelinesManagePipelineResourceUrl
} from '../../../utils/urls/projects/pipelines'

// translation
import { useTranslation } from 'react-i18next'

//redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setCreatePipelineModalIsOpen } from '../../../redux/projects/pipelineList/modals'
import { setProjectsGuidanceModalsClosed, setProjectsGuidancePipelinesModals } from '../../../redux/general/modals'

//other
import { createFilteringRequest, currencyToFormattedString, formatFilters, getItemsPerColumn } from '../../../assets/general/generalFunctions'

const PipelineList: FC = () => {
	const { userCompanyData } = useAppSelector((state) => state.general)
	const { projectsGuidanceModals } = useAppSelector((state) => state.generalModals.guidanceModals)  

	const companyId: number = userCompanyData?.companyId || -1

	const dispatch = useAppDispatch()

	const { t } = useTranslation('', { keyPrefix: 'projects.pipelineList' })

	const [pipelinesOverview, setPipelinesOverview] = useState<{ title: string, text: string }[]>([])
	const [pipelines, setPipelines] = useState<pipeline<Date | undefined>[]>([])
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())

	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)
	const [isOverViewLoading, setOverviewLoading] = useState<boolean>(false)

	const titleUrls = [
		{
			url: '/ri-business/pipelines',
			title: t('pipelines')
		}
	]

	const infoPopup = {
		content: [{
			title: 'pipeline_list_info_title',
			description: 'pipeline_list_info_description',
			link: '/ri-manual/projects'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(pipelinesGetFiltersResourceUrl(companyId), 'GET')

			const filterResult = result.filters
			const createAccess = result.create_access

			const formatedFilters: filters[] = formatFilters(filterResult)

			const filterBarObj = {
				filters: formatedFilters,
				addButton: createAccess && {
					text: t('create'),
					active: true,
					onClick: () => {
						dispatch(setCreatePipelineModalIsOpen(true))
					}
				},
				onSearch: onSearch,
				guidance: {
					open: projectsGuidanceModals.pipelines[2],
					next: () => {},
					close: () => {
						dispatch(setProjectsGuidanceModalsClosed())
					},
					text: 'string'
				}
			}

			setFilterBar(filterBarObj)
		} finally {
			setFilterBarLoading(false)
		}
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)

			const { result } = await authorizedRequest(pipelinesManagePipelineResourceUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')

			if (result.length > 0) {
				const formatedBills: pipeline<Date | undefined>[] = result.map((pipeline: pipelineResponse) => {
					return {
						id: pipeline.id,
						revenue: pipeline.revenue.map((item) => { return { value: item.amount, currency: item.currency } }),
						earliestDeadline: pipeline.earliest_deadline ? new Date(pipeline.earliest_deadline * 1000) : null,
						name: pipeline.name,
						description: pipeline.description,
						numberOfProjects: pipeline.number_of_projects,
						deleteAccess: pipeline.delete_access
					}
				})
				return formatedBills
			}

			return []
		} finally {
			setLoading(false)
		}
	}

	const loadOverview = async () => {
		try {
			setOverviewLoading(true)
			const { result } = await authorizedRequest(getPipelinesOverviewResourceUrl(companyId), 'GET')

			setPipelinesOverview([
				{
					title: 'amountOfProjects',
					text: result.total_projects
				},
				...result.total_revenue.map((amount: { amount: number, currency: currency }) => {
					return {
						title: 'totalRevenue',
						text: currencyToFormattedString(amount.amount, amount.currency)
					}
				})
			])
		} finally {
			setOverviewLoading(false)
		}
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		loadOverview()
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				setPipelines([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setPipelines([...pipelines, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()
	}, [])

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		setFilterBar({
			...filterBar,
			guidance: {
				open: projectsGuidanceModals.pipelines[2],
				next: () => {},
				close: () => {
					dispatch(setProjectsGuidanceModalsClosed())
				},
				text: 'string'
			}
		})
	}, [projectsGuidanceModals.pipelines])

	return (
		<Layout
			header={{
				avatar: true
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className='pipeline-list'>
				<Pagination onLoadMore={handleLoadMore} loading={loading} showLoader={false}>
					<ColumnLayout amount={itemsPerColumn}>
						{isOverViewLoading ? <OverviewCardSkeleton /> : <ListSummaryCard
							title={t('pipelineOverview')}
							data={pipelinesOverview.map(overview => {
								return {
									...overview,
									title: t(overview.title)
								}
							})}
						/>}
						{pipelines.map((pipeline, index) => {
							return (
								<div className="pipeline-list-wrapper">
									<PipelineListItem
										key={`pipeline-item-${pipeline.id}`}
										{...pipeline}
										onClick={() => {
											dispatch(setProjectsGuidancePipelinesModals([false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
										}}
									/>
									{
										index === 0 &&

											<GuideModal
												closeModal={() => {
													dispatch(setProjectsGuidanceModalsClosed())
												}}
												open={projectsGuidanceModals.pipelines[3]}

											>
												<></>
											</GuideModal>
									}
								</div>
							)
						})}
						{loading && <ListCardSkeleton />}
					</ColumnLayout>
				</Pagination>
			</div>

			<CreatePipelineModal pipelines={pipelines} setPipelines={setPipelines} />
			<DeletePipelineConfirmationModal pipelines={pipelines} setPipelines={setPipelines} />
		</Layout>
	)
}

export default PipelineList