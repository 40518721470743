// styles
import './home.scss'

// react
import { lazy } from 'react'

// components
import Layout from '../../components/general/layout/layout'
import HomeHeroSection from '../../components/home/homeHeroSection/homeHeroSection'
import HomeContent from '../../components/home/homeContent/homeContent'
const ParticlesBg = lazy(() => import('../../components/general/particlesBg/particlesBg')) 

const Home = () => {
	return (
		<Layout>
			<HomeHeroSection/>
			<HomeContent/>
			<ParticlesBg/>
		</Layout>
	)
}

export default Home