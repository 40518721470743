// styles and icons
import { listIcon, tableIcon } from '../../../../assets/general/generalIcons'


// react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import Pagination from '../../../general/pagination/pagination'
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import ListSummaryCard from '../../../general/listSummaryCard/listSummaryCard'
import BillListItem from '../../../general/billListItem/billListItem'
import ImportBillFileSelectorModal from '../../../general/modals/bill/importBillFileSelectorModal/importBillFileSelectorModal'
import ExportBillFileModal from '../../../general/modals/bill/exportBillFileModal/exportBillFileModal'
import BillTablePreviewModal from '../../../general/modals/bill/billTablePreviewModal/billTablePreviewModal'
import CreateBillModal from '../../../general/modals/bill/createBillModal/createBillModal'
import DeleteBillConfirmationModal from '../../../general/modals/bill/deleteBillConfirmationModal/deleteBillConfirmationModal'
import CounterpartySkeleton from '../../../general/skeletons/counterParty/counterPartySkeleton'
import BillTable from '../../../general/tableViews/billTable/billTable'
import SwitchButtons from '../../../general/switchButtons/switchButtons'
import OverviewCardSkeleton from '../../../general/skeletons/overviewCard/overviewCardSkeleton'

// translations
import { useTranslation } from 'react-i18next'

// types
import {
	currency,
	filterBar,
	filters,
	switchButton,
} from '../../../../types/general/generalTypes'
import { bill } from '../../../../types/finance/general'

//redux
import { useAppDispatch } from '../../../../customHooks/redux'
import { setCreateBillModalIsOpen } from '../../../../redux/general/modals'

//network
import { authorizedRequest } from '../../../../utils/queries'
import {
	singleProviderBillFiltersUrl,
	singleProviderBillsOverviewUrl,
	singleProviderBillsUrl,
} from '../../../../utils/urls/purchases/provider'

// other
import {
	formatFilters,
	createFilteringRequest,
	currencyToFormattedString,
	getItemsPerColumn,
} from '../../../../assets/general/generalFunctions'
import { formatBillResponse } from '../../../../assets/finance/general/generalFunctions'

type providerBillsListProps = {
	setFilterBar: (filterBar: filterBar | undefined) => void
}

const ProviderBillsList: FC<providerBillsListProps> = ({ setFilterBar }) => {
	const { t } = useTranslation('', {
		keyPrefix: 'purchase.provider.providerBillList',
	})

	const dispatch = useAppDispatch()

	const [billsOverview, setBillsOverview] = useState<
		{ title: string; text: string }[]
	>([])
	const [bills, setBills] = useState<bill[]>([])
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())
	const [activeViewId, setActiveViewId] = useState(0)

	const [searchRequest, setSearchRequest] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isOverviewLoading, setOverviewLoading] = useState<boolean>(false)

	const relationshipId = parseInt(useParams()?.relationshipId || '-1', 10) ?? -1

	const loadFilterBar = async () => {
		const { result } = await authorizedRequest(
			singleProviderBillFiltersUrl(relationshipId),
			'GET'
		)

		const filterResult = result.filters
		const createAccess = result.create_access

		const formatedFilters: filters[] = formatFilters(filterResult)
		console.log(result, formatedFilters)

		setFilterBar({
			// importButton: () => {
			// 	dispatch(setImportBillFileSelectorModalIsOpen(true))
			// },
			// exportButton: () => {
			// 	dispatch(setExportBillFileModalIsOpen(true))
			// },
			filters: formatedFilters,
			addButton: createAccess && {
				text: t('create'),
				active: true,
				onClick: () => dispatch(setCreateBillModalIsOpen(true)),
			},
			onSearch: onSearch,
		})
	}

	const loadData = async (
		page: number,
		request: string,
		filters: filters[]
	) => {
		const filteringRequest = createFilteringRequest(filters)

		const { result } = await authorizedRequest(
			singleProviderBillsUrl(relationshipId) +
				`?page=${page}&per_page=${10}&needle=${request}` +
				filteringRequest,
			'GET'
		)
		if (result.length > 0) {
			const formatedBills: bill[] = result.map(formatBillResponse)
			return formatedBills
		}

		return []
	}

	const loadOverview = async (request: string, filters: filters[]) => {
		setOverviewLoading(true)
		const filteringRequest = createFilteringRequest(filters)

		const { result } = await authorizedRequest(
			singleProviderBillsOverviewUrl(relationshipId) +
				`?page=${page}&per_page=${10}&needle=${request}` +
				filteringRequest,
			'GET'
		)

		setBillsOverview([
			{
				title: t('totalBills'),
				text: result.total_bills,
			},
			...result.amount.map((amount: { amount: number; currency: currency }) => {
				return {
					title: t('total'),
					text: currencyToFormattedString(amount.amount, amount.currency),
				}
			}),
		])

		setOverviewLoading(false)
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		loadOverview(searchValue, filters)
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadData(1, searchValue, filters).then((result) => {
			setBills([...result])
			if (result.length > 0) {
				setPage(page + 1)
			} else {
				setLastPage(true)
			}
			setLoading(false)
		})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters).then((result) => {
				setBills([...bills, ...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)
		loadFilterBar()
		return () => {
			setFilterBar(undefined)
		}
	}, [])

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])
	const renderComponent = () => {
		switch (activeViewId) {
		case 0:
			return (
				<ColumnLayout amount={itemsPerColumn}>
					{isOverviewLoading ?<OverviewCardSkeleton/> : (
						<ListSummaryCard
							title={t('billsOverview')}
							data={billsOverview}
						/>
					)}
					{bills.map((bill) => (
						<BillListItem key={`bill-item-${bill.id}`} {...bill} />
					))}
					{loading ? <CounterpartySkeleton /> : null}
				</ColumnLayout>
			)
		case 1:
			return (
				<BillTable
					preloadedBills={{
						bills: bills,
						setBills: setBills,
					}}
				/>
			)
		}
	}
	const viewButtons: switchButton[] = [
		{
			id: 0,
			icon: listIcon,
		},
		{
			id: 1,
			icon: tableIcon,
		},
	]

	return (
		<Pagination onLoadMore={handleLoadMore} showLoader={false}>
			<SwitchButtons
				switchButtons={viewButtons}
				activeSwitchButtonId={activeViewId}
				setActiveSwitchButtonId={setActiveViewId}
				size="medium"
			/>
			<Pagination
				onLoadMore={handleLoadMore}
				loading={loading}
				showLoader={false}
			>
				{renderComponent()}
			</Pagination>
			<CreateBillModal setBills={setBills} bills={bills} />
			<ImportBillFileSelectorModal />
			<ExportBillFileModal />
			<BillTablePreviewModal setBills={setBills} bills={bills} />
			<DeleteBillConfirmationModal setBills={setBills} bills={bills} />
		</Pagination>
	)
}

export default ProviderBillsList
