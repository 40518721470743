//styles and icons
import './dashboardStage.scss'
import { plusIcon } from '../../../../assets/general/generalIcons'

//react
import { FC } from 'react'

// components
import Draggable from '../../dragAndDropHandler/draggable/draggable'
import DropStageArea from '../../dragAndDropHandler/dropStageArea/dropStage'
import DashboardCard from './dashboardCard/dashboardCard'
import DashboardStageInfo from './dashboardStageInfo/dashboardStageInfo'
import GuideModal from '../../modals/guideModal/guideModal'
import Pagination from '../../pagination/pagination'

//redux
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'
import { setProjectsGuidancePipelinesModals, setProjectsGuidanceModalsClosed, setProjectsGuidanceMyTasksModals } from '../../../../redux/general/modals'

// types
import { dragLocation, dashboardCard } from '../../../../types/general/generalTypes'

type dashboardStageProps = {
	stageId: number
	color?: string
	cardList: dashboardCard[]
	moveProject?: (dragInfo: dragLocation, dropinfo: dragLocation) => void
	onCardClick?: (data: dashboardCard) => void
	onStageAdd?: (stageName: string, stageId: number) => void
	createAccess: boolean
	isLoading: boolean
	onLoadMore?: (stageId: number) => void
	quantity: number
	stageName: string
	index: number
}
	
const DashboardStage: FC<dashboardStageProps> = ({ stageId, cardList, color, onStageAdd, moveProject, onCardClick, createAccess, isLoading, quantity, stageName, onLoadMore, index }) => {

	const { projectsGuidanceModals } = useAppSelector((state) => state.generalModals.guidanceModals)

	const dispatch = useAppDispatch()


	const handleOnStageAdd = () => {
		onStageAdd && onStageAdd(stageName, stageId)
	}

	return (
		<div className='dashboard-stage-container'>
			<div className="dashboard-stage-header" style={{ borderTop: `0.3vw solid ${color ?? 'var(--accentPrimary)'}` }}>
				<DashboardStageInfo cardsLength={quantity} stage={stageName} isLoading={isLoading} />
				{
					createAccess && onStageAdd ?
						<div className="add-button-wrapper">
							<div className="icon" onClick={() => {
								handleOnStageAdd()
							}}>
								{plusIcon}
							</div>
							<GuideModal
								open={(projectsGuidanceModals.pipelines[4] || projectsGuidanceModals.myTasks[2]) && index === 0}
								closeModal={() => {
									dispatch(setProjectsGuidanceModalsClosed())
								}}
							>
								<p>{'Create Project'}</p>
							</GuideModal>
						</div>
						: null
				}
			</div>
			<Pagination showLoader={false} loading={isLoading} loadAxis="vertical" onLoadMore={() => {
				onLoadMore && onLoadMore(stageId)
			}}>
				<div className='dashboard-stage'>
					<DropStageArea
						stageId={stageId}
						stage={stageName}
						className='dashboard-items'
						onDrop={moveProject}
					>
						{cardList.map((card, index) => {
							return (
								<Draggable
									className="dashboard-item-container"
									dragData={card}
									index={index}
									stageId={stageId}
									stage={stageName}
									key={'project-card' + index}
								>
									<DashboardCard
										{...card}
										onClick={() => {
											onCardClick && onCardClick(card)

											if(card.first){
												if(projectsGuidanceModals.pipelines[10]){
													dispatch(setProjectsGuidancePipelinesModals([false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false]))
												}else if(projectsGuidanceModals.myTasks[9]){
													dispatch(setProjectsGuidanceMyTasksModals([false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false]))
												}
											}
										}}
										executors={card.executors}
									/>
									{
										card.first ? 
											<GuideModal
												open={card.first && (projectsGuidanceModals.pipelines[10] || projectsGuidanceModals.myTasks[9])}
												closeModal={() => {
													dispatch(setProjectsGuidanceModalsClosed())
												}}
											>
												<p>Task</p>
											</GuideModal>
										: null
									}
									
								</Draggable>
							)
						})}
					</DropStageArea>
				</div>
				
			</Pagination>
		</div>
	)
}

export default DashboardStage