// styles and icons
import './createOrderModal.scss'

// react
import { FC, useEffect, useState } from 'react'

//components
import Modal from '../modal/modal'
import Dropdown from '../../dropdown/dropdown'
import EditableTable from '../../editableTable/editableTable'
import CreateOrderComfirmationModal from './createOrderConfirmationModal/createOrderConfirmationModal'

//types
import { currency, offering, dropdown, infoPopupTypes } from '../../../../types/general/generalTypes'

//other
import { currencyToFormattedString, getOrderItemsTotal } from '../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type createOrderModalProps = {
	isOpen: boolean
	closeModal: () => void
	dropdowns?: dropdown[]
	loadItems?: (searchQuery: string, page: number) => Promise<offering[]>
	createOrder: (items: offering[]) => void
	reload?: boolean
	hasErrors: () => boolean
	relationshipId: number
}

const CreateOrderModal: FC<createOrderModalProps> = ({ isOpen, closeModal, dropdowns, loadItems, createOrder, reload, hasErrors, relationshipId }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.modals.createOrderModal' })

	const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
	const [items, setItems] = useState<offering[]>([])

	const [selectedItems, setSelectedItems] = useState<offering[]>([])
	const [localRelationshipId, setLocalRelationshipId] = useState(0)


	const initialTotal = [
		{ amount: 0, currency: currency.UAH },
		{ amount: 0, currency: currency.USD },
		{ amount: 0, currency: currency.EUR }
	]

	const infoPopup = {
		content: [{
			title: 'create_order_title',
			description: 'create_order_description',
		}],
		type: infoPopupTypes.INFO
	}
	
	const [total, setTotal] = useState(initialTotal)

	const handleCreateOrder = () => {
		const hasErrorsResult = hasErrors()
		if (!selectedItems || selectedItems.length === 0 || hasErrorsResult) return
		setConfirmationModalIsOpen(true)
	}

	useEffect(() => {
		if (selectedItems.length === 0) return setTotal(initialTotal)
		const total = getOrderItemsTotal(selectedItems)

		setTotal(total)

	}, [selectedItems])

	useEffect(() => {
		setLocalRelationshipId(relationshipId)
	}, [relationshipId])

	useEffect(() => {
		console.log('loadItems', loadItems, items)
	}, [loadItems])
	
	return (
		<>
			<Modal
				closeModal={() => {
					closeModal()
					setSelectedItems([])
				}}
				open={isOpen}
				titlePopup={infoPopup}
				title={t('createOrder')}
				submitButton={{
					text: t('order'),
					onClick: handleCreateOrder,
				}}
			>
				<div className="create-order-wrapper">
					<div className="create-order-options">
						{dropdowns?.length
							? dropdowns.map((dropdown, index) => (
								<div className="counterparty-list">
									<Dropdown
										label={dropdown.label}
										popup={dropdown.popup}
										placeholder={t(
											dropdown.placeholder ? dropdown.placeholder : ''
										)}
										loadOptions={dropdown.loadOptions}
										dropdownOptions={dropdown.dropdownOptions}
										onSelect={(option) => {
											if (dropdown.onSelect) dropdown.onSelect(option)
										}}

										selectedOption={dropdown.selectedOption}
										error={dropdown.error ? t(dropdown.error) : undefined}
										disabled={dropdown.disabled}
									/>
								</div>
							))
							: null}
					</div>
					{loadItems && <EditableTable
						items={items}
						setItems={(value) => {
							setItems(value as offering[])
						}}
						loadItems={loadItems}
						selected={{
							items: selectedItems,
							setItems: (value) => {
								setSelectedItems(value as offering[])
							}
						}}
						reload={reload}
					/>}
					<div className="create-order-footer">
						{total.map((item, idx) => (
							<div className="create-order-total" key={`total-${idx + 1}`}>
								<span>{t('total')}</span>
								<span>
									{currencyToFormattedString(item.amount, item.currency)}
								</span>
							</div>
						))}
					</div>
				</div>
			</Modal>
			<CreateOrderComfirmationModal
				isOpen={confirmationModalIsOpen}
				setIsOpen={setConfirmationModalIsOpen}
				selectedItems={selectedItems}
				createOrder={createOrder}
				relationshipId={localRelationshipId}
			/>
		</>
	)
}

export default CreateOrderModal
