//styles and icons
import './guideModal.scss'

//react
import { FC, useEffect, useState, useLayoutEffect, useRef, ReactNode } from 'react'
import { createPortal } from 'react-dom'

//components
import Button from '../../button/button'
import SkipGuidanceConfirmationModal from './skipGuidanceConfirmationModal/skipGuidanceConfirmationModal'

//translation
import { useTranslation } from 'react-i18next'

type guideModalProps = {
    closeModal: (() => void) | undefined
	next?: (() => void) | undefined
    open: boolean | undefined
    children: ReactNode
    isLoading?: boolean
}

type position = {
    left: number
    top: number
}

const GuideModal: FC<guideModalProps> = ({ closeModal, open, children, isLoading, next }) => {

	const { t } = useTranslation('', {keyPrefix: 'general.modals.guidanceModal'})

	const [skipGuidanceModalIsOpen, setSkipGuidanceModalIsOpen] = useState(false)
	const [parentClone, setParentClone] = useState<HTMLElement | null>(null)

	const contentRef = useRef<HTMLDivElement>(null)
	const backdropRef = useRef<HTMLDivElement | null>(null)
	const modalRef = useRef<HTMLDivElement>(null)
	const observerRef = useRef<MutationObserver | null>(null)
	const animationFrameRef = useRef<number | null>(null)
	const resizeObserverRef = useRef<ResizeObserver | null>(null)

	const copyClassAssignedStylesRecursive = (sourceNode: HTMLElement, targetNode: HTMLElement) => {
		// Function to copy styles that differ from defaults
		const copyStyles = (source: HTMLElement, target: HTMLElement) => {
			const dummy = document.createElement(source.tagName)
			document.body.appendChild(dummy)
	
			const sourceStyles = window.getComputedStyle(source)
			const defaultStyles = window.getComputedStyle(dummy)

			Array.from(sourceStyles).forEach((property) => {
				if (sourceStyles.getPropertyValue(property) !== defaultStyles.getPropertyValue(property) && !property.includes('webkit')) {
					target.style.setProperty(property, sourceStyles.getPropertyValue(property))
				}
			})
	
			document.body.removeChild(dummy)
		}
	
		// Copy styles for the sourceNode and its children
		const copyRecursive = (sourceEl: HTMLElement, targetEl: HTMLElement) => {
			forwardEvents(sourceEl, targetEl)
			// Copy the styles for the current element
			copyStyles(sourceEl, targetEl)
	
			// Recursively copy styles for all children
			const sourceChildren = Array.from(sourceEl.children)

			const targetChildren = Array.from(targetEl.children)
	
			sourceChildren.forEach((sourceChild, index) => {
				const targetChild = targetChildren[index]
				copyRecursive(sourceChild as HTMLElement, targetChild as HTMLElement)
			})
		}
	
		// Start copying recursively from the parent element
		copyRecursive(sourceNode, targetNode)
	}

	const forwardEvents = (sourceNode: HTMLElement, targetNode: HTMLElement) => {
		// Define which events to forward
		const eventsToForward = ['click', 'input', 'change', 'focus', 'blur', 'keydown', 'keyup']
	
		eventsToForward.forEach((eventType) => {

			targetNode.addEventListener(eventType, (event) => {
				let newEvent
	
				// Determine the type of event and create the appropriate event object
				if (event instanceof MouseEvent) {
					newEvent = new MouseEvent(event.type, event)
				} else if (event instanceof KeyboardEvent) {
					newEvent = new KeyboardEvent(event.type, event)
				} else if (event instanceof FocusEvent) {
					newEvent = new FocusEvent(event.type, event)
				} else {
					newEvent = new Event(event.type, event) // Generic event fallback
				}

	
				// Dispatch the event on the original element
				sourceNode.dispatchEvent(newEvent)
			})
		})
	}

	const updateCoordinates = () => {
		if(!open) return

		const contentDom = contentRef?.current
		if (!contentDom) return
    
		const modalDom = modalRef?.current
		if (!modalDom) return

		// get the parent real dom
		const parentDom = contentDom?.parentElement
		if (!parentDom) return

		// show the parent component front of the back-screen
            
		// get the parent position
		const rect = parentDom.getBoundingClientRect()

		let position: position = { left: rect.right, top: rect.bottom }

		if(parentDom.parentElement?.classList.contains('dark-bg')){
			position = { left: parentDom.clientWidth, top: parentDom.clientHeight}
		}
    
		// get the modal content width and height
		const contentWidth = modalDom.getBoundingClientRect().width
		const contentHeight = modalDom.getBoundingClientRect().height
    
		let modalLeft = position.left
		let modalTop  = position.top
    
		const paddingOffset = 20
    
		if (window.innerWidth < position.left + contentWidth) {
			modalLeft -= (position.left + contentWidth - window.innerWidth + paddingOffset)
		}
    
		if (window.innerHeight < position.top + contentHeight) {
			modalTop -= (position.top + contentHeight - window.innerHeight + paddingOffset)
		}
    
		modalDom.style.left = String(modalLeft) + 'px'
		modalDom.style.top = String(modalTop) + 'px'
	}
    
	const skipGuidanceConfirmation = () => {
		setSkipGuidanceModalIsOpen(true)
	}

	const deleteBackdrop = () => {
		if (backdropRef.current && document.body.contains(backdropRef.current)) {
			document.body.removeChild(backdropRef.current)
			backdropRef.current.removeEventListener('mousedown', skipGuidanceConfirmation)
		}
	}

	// Create and append backdrop
	const createBackdrop = () => {
		const backdrop = document.createElement('div')

		backdrop.classList.add('user-guide-modal','user-guide-modal-dark-bg')

		// Add a mousedown event listener to the backdrop
		backdrop.addEventListener('mousedown', skipGuidanceConfirmation)

		document.body.appendChild(backdrop)
		backdropRef.current = backdrop
	}

	const deleteCloneAndObserver = () => {
		if (parentClone) {
			parentClone.style.transition = 'all ease 0.3s'
			parentClone.style.opacity = '0'
			setTimeout(() => {
				parentClone.remove()
				setParentClone(null)
			}, 300)
		}

		if (observerRef.current) {
			observerRef.current.disconnect()
			observerRef.current = null
		}
	}


	const startTrackingPosition = (parentClone: HTMLElement) => {
		const track = () => {
			if(animationFrameRef.current === -1) return
			updateClonePosition(parentClone)
			updateCoordinates()
			animationFrameRef.current = requestAnimationFrame(track)
		}
		track()
	}


	const stopTrackingPosition = () => {
		if (animationFrameRef.current) {
			cancelAnimationFrame(animationFrameRef.current)
			animationFrameRef.current = -1
		}
	}
	const scrollToElement = (
		element: HTMLElement,
		parentClone: HTMLElement,
		offset = 0
	): Promise<void> => {
		return new Promise((resolve, reject) => {

			const ancestors: HTMLElement[] = []
			let currentElement: HTMLElement | null = element
	
			// Collect all ancestors
			while (currentElement) {
				ancestors.push(currentElement)
				currentElement = currentElement.parentElement
			}
	
			resizeObserverRef.current = null
	
			const scrollAncestors = (): Promise<void> => {
				return new Promise((resolveScroll, reject) => {
					for (let i = ancestors.length - 1; i >= 0; i--) {
						const ancestor = ancestors[i]

						let scrollTimeout: NodeJS.Timeout

						const scrollListener = () => {

							if(parentClone){
								parentClone.style.display = 'none'
							}
			
							clearTimeout(scrollTimeout)
							scrollTimeout = setTimeout(() => {
								if(i === 0) {
									resolveScroll()
								}
								if(parentClone){
									updateClonePosition(parentClone)
									updateCoordinates()
									parentClone.style.display = 'block'
								}
								ancestor.removeEventListener('scroll', scrollListener)
							}, 100)
						}


						ancestor.addEventListener('scroll', scrollListener)
						
						ancestor.scrollIntoView({ behavior: 'smooth', block: 'center' })
						// Apply offset after scrolling the final target element
						if (i === 0) {
							console.log('yess1: ', document.documentElement.clientHeight, document.documentElement.scrollHeight)
							if(document.documentElement.clientHeight + 20 >= document.documentElement.scrollHeight){
								resolveScroll()
							}
							window.scrollBy({ top: offset, left: 0, behavior: 'smooth' })
							window.addEventListener('scroll', scrollListener)
						}
					}
				})
			}
	
			// Observe size changes
			const observeAncestors = () => {
				resizeObserverRef.current = new ResizeObserver(() => {
					scrollAncestors()
				})
	
				currentElement = element
				while (currentElement) {
					resizeObserverRef.current.observe(currentElement)
					currentElement = currentElement.parentElement
				}
							}
			observeAncestors()
			// Perform initial scroll and start observing

			scrollAncestors().then(() => {
				resolve()
			})
	
		})
	}

	useEffect(() => {
		if (open) {
			const contentDom = contentRef.current
			if(!contentDom) return 

			const parentDom = contentDom?.parentElement

			if (parentDom) {
				const clonedParent = parentDom.cloneNode(true) as HTMLElement

				scrollToElement(parentDom, clonedParent).then(() => {
					console.log('yesss2')
					setParentClone(clonedParent)
					updateClonePosition(clonedParent)
					clonedParent.classList.add('user-guide-modal', 'guide-background')

					// Copy computed styles (for inline styles)
					copyClassAssignedStylesRecursive(parentDom, clonedParent)
	
					document.body.appendChild(clonedParent)
	
	
					const modalParent = contentDom.closest('.modal-container')
	
					if(modalParent){
						modalParent.addEventListener('animationstart', () => startTrackingPosition(clonedParent))
						modalParent.addEventListener('animationend', stopTrackingPosition)
					}
	
					createBackdrop()
				})
			}

		} else {

			deleteBackdrop()
			deleteCloneAndObserver()
		}
	}, [open])

	useEffect(() => {
		let interval: NodeJS.Timer

		if(parentClone){
			interval = setInterval(() => updateClonePosition(parentClone), 100)
		}

		return () => {
			if(interval){
				clearInterval(interval)
			}
		}
		
	}, [parentClone])

	const updateClonePosition = (parentClone: HTMLElement) => {
		const contentDom = contentRef.current
		const parentDom = contentDom?.parentElement
		if (!parentDom || !parentClone) return

		const rect = parentDom.getBoundingClientRect()


		const getAbsoluteScrollOffset = (element: HTMLElement) => {

			let offsetTop = 0
			let currentElement: HTMLElement | null = element
		
			// Traverse through the parent elements
			while (currentElement) {
				offsetTop += currentElement.scrollTop // Add the current element's offsetTop
				currentElement = currentElement.parentElement as HTMLElement // Move to the parent element
			}
		
			return offsetTop
		}


		const getAbsoluteTopOffset = (element: HTMLElement): number => {
			let offsetTop = 0
			let currentElement: HTMLElement | null = element
	
			while (currentElement) {
				offsetTop += currentElement.offsetTop // Include scroll offsets
				currentElement = currentElement.offsetParent as HTMLElement // Traverse upwards
			}
	
			return offsetTop;
		};
	
		const isModalContext = parentDom.parentElement?.classList.contains('dark-bg')
		const absoluteTopOffset = isModalContext ? 0 : getAbsoluteTopOffset(parentDom) - getAbsoluteScrollOffset(parentDom)
	
		parentClone.style.left = `${rect.left}px`
		parentClone.style.top = `${absoluteTopOffset}px`
	}


	useLayoutEffect(() => {
		updateCoordinates()
	})
    
	useEffect(() => {
		window.addEventListener('resize', updateCoordinates)

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener('resize', updateCoordinates)
			if(resizeObserverRef.current){
				resizeObserverRef.current.disconnect()
			}

			deleteBackdrop()
			
			const modals = document.querySelectorAll('.user-guide-modal')

			modals.forEach((elem) => {
				if(document.body.contains(elem)) {
					document.body.removeChild(elem)
				}
			})

		}
	}, [])

	return ( 
		<>
			{!isLoading && parentClone ? createPortal(
				<div
					onMouseDown={(e) => e.stopPropagation()}
					onClick={(e) => {
						e.stopPropagation()
						e.preventDefault()
					}}
					ref={modalRef}
					className={`user-guide-modal guide-modal-container ${open ? 'open' : 'close'}`}
				>
					<div className="modal-content-container">
						{children}
					</div>
					<div className="modal-button">
						<Button
							active={true}
							text={t('skip')}
							onClick={() => {
								setSkipGuidanceModalIsOpen(true)
							}}
						/>
						{
							next &&
							<Button
								active={true}
								text={t('next')}
								onClick={() => {
									next()
								}}
							/>
						}
					</div>
				</div>
				, parentClone) : null}
			<div ref={contentRef} style={{display: 'none'}} onClick={(e) => {
				e.stopPropagation()
				e.preventDefault()
			}}></div>
			{
				createPortal(

					<SkipGuidanceConfirmationModal
						isOpen={skipGuidanceModalIsOpen}
						setIsOpen={setSkipGuidanceModalIsOpen}
						closeModal={closeModal || (() => {})}
					/>
					, document.body)
			}
		</>
	)
}

export default GuideModal