// styles
import './providerList.scss'
import {listIcon, tableIcon } from '../../../assets/general/generalIcons'

// react
import { FC, useEffect, useState } from 'react'

// network
import { authorizedRequest } from '../../../utils/queries'
import { companyProviderFiltersUrl, companyProvidersUrl } from '../../../utils/urls/purchases/provider'

// types
import { provider } from '../../../types/purchase/providerTypes'
import { filterBar, filters, infoPopupTypes, switchButton } from '../../../types/general/generalTypes'

// components
import Layout from '../../../components/general/layout/layout'
import Pagination from '../../../components/general/pagination/pagination'
import CreateProviderModal from '../../../components/purchase/providerList/modals/createProviderModal/createProviderModal'
import DeleteProviderConfirmationModal from '../../../components/purchase/providerList/modals/deleteProviderConfirmationModal/deleteProviderConfirmationModal'
import ImportProviderFileSelectorModal from '../../../components/purchase/providerList/modals/importProviderFileSelectorModal/importProviderFileSelectorModal'
import ProviderTablePreviewModal from '../../../components/purchase/providerList/modals/providerTablePreviewModal/providerTablePreviewModal'
import ExportProviderFileModal from '../../../components/purchase/providerList/modals/exportProviderFileModal/exportProviderFileModal'
import SwitchButtons from '../../../components/general/switchButtons/switchButtons'
import ProviderCardList from '../../../components/purchase/providerList/providerCardList/providerCardList'
import ProviderTable from '../../../components/general/tableViews/providerTable/providerTable'

// redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setCreateProviderModalIsOpen } from '../../../redux/purchase/providersList/modals'
import { setPurchasesGuidanceModalsClosed, setPurchasesGuidanceProviderModals } from '../../../redux/general/modals'

//other
import { createFilteringRequest, formatFilters, formatProviderResponse } from '../../../assets/general/generalFunctions'

// translation
import { useTranslation } from 'react-i18next'


const ProviderList: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'purchase.providerList' })
	
	const { userCompanyData } = useAppSelector((state) => state.general)
	const { purchasesGuidanceModals } = useAppSelector((state) => state.generalModals.guidanceModals)

	const companyId: number = userCompanyData?.companyId || -1
	const dispatch = useAppDispatch()

	const [providerList, setProviderList] = useState<provider[]>([])
	const [searchRequest, setSearchRequest] = useState('')
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [loading, setLoading] = useState(false)
	const [lastPage, setLastPage] = useState(false)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)
	const [activeViewId, setActiveViewId] = useState(0)

	const titleUrls = [
		{
			url: '/ri-business/purchase/provider-list',
			title: t('providers')
		}
	]

	const infoPopup = {
		content: [{
			title: 'provider_list_info_title',
			description: 'provider_list_info_description',
			link: '/ri-manual/purchases'
		}],
		type: infoPopupTypes.INFO
	}

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(companyProviderFiltersUrl(companyId), 'GET')

			const filterResult = result.filters
			const createAccess = result.create_access
			const formatedFilters: filters[] = formatFilters(filterResult)

			setFilterBar({
				filters: formatedFilters,
				addButton: createAccess && {
					text: t('create'),
					active: true,
					onClick: () => {
						dispatch(setCreateProviderModalIsOpen(true))
					}
				},
				onSearch: onSearch,
				guidance: {
					open: purchasesGuidanceModals.providers[2],
					close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
					next: () => {},
					text: 'test'
				}
			})

		} finally {
			setFilterBarLoading(false)
		}
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		try {
			setLoading(true)
			const filteringRequest = createFilteringRequest(filters)
			const { result } = await authorizedRequest(companyProvidersUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
			if (result.length > 0) {
				const formatedProviders: provider[] = result.map(formatProviderResponse)
				return formatedProviders
			}

			return []
		} finally {
			setLoading(false)
		}
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		loadData(1, searchValue, filters)
			.then((result) => {
				setProviderList([...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					setProviderList([...providerList, ...result])
					if (result.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				}).finally(() => {
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)
		loadFilterBar()
	}, [])

	useEffect(() => {
		setFilterBar({
			...filterBar,
			guidance: {
				open: purchasesGuidanceModals.providers[2],
				close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
				next: () => { dispatch(setPurchasesGuidanceProviderModals([false, false, false, true, false, false, false, false, false, false, false, false])) },
				text: 'test'
			}
		})
	}, [purchasesGuidanceModals.providers])

	const [viewButtons, setViewButtons] = useState<switchButton[]>([
		{
			id: 0,
			icon: listIcon,
			guidance: {
				open: purchasesGuidanceModals.providers[4],
				close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
				next: () => { dispatch(setPurchasesGuidanceProviderModals([false, false, false, false, false, true, false, false, false, false, false, false])) },
				text: 'test'
			}
		},
		{
			id: 1,
			icon: tableIcon,
			guidance: {
				open: purchasesGuidanceModals.providers[3],
				close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
				next: () => { dispatch(setPurchasesGuidanceProviderModals([false, false, false, false, true, false, false, false, false, false, false, false])) },
				text: 'test'
			}
		}
	])

	useEffect(() => {
		setViewButtons([
			{
				id: 0,
				icon: listIcon,
				guidance: {
					open: purchasesGuidanceModals.providers[4],
					close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
					next: () => { dispatch(setPurchasesGuidanceProviderModals([false, false, false, false, false, true, false, false, false, false, false, false])) },
					text: 'test'
				}
			},
			{
				id: 1,
				icon: tableIcon,
				guidance: {
					open: purchasesGuidanceModals.providers[3],
					close: () => { dispatch(setPurchasesGuidanceModalsClosed()) },
					next: () => { dispatch(setPurchasesGuidanceProviderModals([false, false, false, false, true, false, false, false, false, false, false, false])) },
					text: 'test'
				}
			}
		])
	}, [purchasesGuidanceModals.providers])

	const renderComponent = () => {
		switch (activeViewId) {
		case 0:
			return <ProviderCardList providers={providerList} loading={loading} />
		case 1:
			return (
				<ProviderTable
					preloadedProviders={{providers:providerList, setProviders:setProviderList }}
				/>
			)
		}
	}

	return (
		<Layout
			header={{
				avatar: true
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className="providers-list">
				<SwitchButtons
					switchButtons={viewButtons}
					activeSwitchButtonId={activeViewId}
					setActiveSwitchButtonId={setActiveViewId}
					size="medium"
				/>

				<Pagination
					onLoadMore={handleLoadMore}
					onlyLoadOn="bottom"
					loading={loading}
					showLoader={false}
				>
					{renderComponent()}
				</Pagination>
			</div>

			<CreateProviderModal providerList={providerList} setProviderList={setProviderList} />
			<DeleteProviderConfirmationModal providerList={providerList} setProviderList={setProviderList} />
			<ImportProviderFileSelectorModal />
			<ProviderTablePreviewModal providerList={providerList} setProviderList={setProviderList} />
			<ExportProviderFileModal />
		</Layout>
	)
}

export default ProviderList