//styles
import './particlesBg.scss'

import { FC, useCallback, useState, useEffect } from 'react'
import Particles from 'react-tsparticles'
import type { Container, Engine } from 'tsparticles-engine'
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from 'tsparticles-slim' // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.

const ParticlesBg: FC<{ color?: string }> = ({ color }) => {
	const [showParticles, setShowParticles] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => setShowParticles(true), 3000)
		return () => clearTimeout(timer)
	}, [])

	const particlesInit = useCallback(async (engine: Engine) => {

		// you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
		// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
		// starting from v2 you can add only the features you need reducing the bundle size
		await loadSlim(engine)
	}, [])

	const particlesLoaded = useCallback(async (container: Container | undefined) => {
	}, [])

	if(!showParticles) return null

	return (
		<Particles
			id='tsparticles'
			init={particlesInit}
			loaded={particlesLoaded}
			options={{
				background: {
					color: {
						value: 'transparent',
					},
				},
				fpsLimit: 60,
				particles: {
					color: {
						value: '#1E1B39',
					},
					links: {
						color: color? color :'#1E1B39',
						distance: 100,
						enable: true,
						opacity: 0.2,
						width: 0.5,
					},
					move: {
						direction: 'none',
						enable: true,
						outModes: {
							default: 'bounce',
						},
						random: false,
						speed: 0.5,
						straight: false,
					},
					number: {
						density: {
							enable: true,
							area: 1000,
						},
						value: 20,
					},
					opacity: {
						value: 0.3,
					},
					shape: {
						type: 'circle',
					},
					size: {
						value: { min: 1, max: 3 },
					},
				},
				detectRetina: true
			}}
		/>
	)
}

export default ParticlesBg