//styles
import './switchButtons.scss'

// react
import { FC } from 'react'

//components
import GuideModal from '../modals/guideModal/guideModal'

//types
import { switchButton } from '../../../types/general/generalTypes'

type switchButtonsProps = {
    switchButtons: switchButton[]
    activeSwitchButtonId?: number
    setActiveSwitchButtonId?: (value: number) => void
    size?:'small' | 'medium' | 'large'
    orientation?:'vertical' | 'horizontal'
}

const SwitchButtons: FC<switchButtonsProps> = ({ switchButtons, activeSwitchButtonId, setActiveSwitchButtonId, size, orientation}) => {

	console.log('SwitchButtons: ', switchButtons)

	return (
		<div className={`switch-buttons-container ${size ? size : ''} ${orientation ? orientation : ''}`}>
			{
				switchButtons.map((button, index) => {
					return (
						<div className="switch-button-wrapper">
							<div 
								className={'switch-button' + ` ${button.id === activeSwitchButtonId ? 'active' : ''}`} 
								onClick={button.onClick ? button.onClick: (e) => {
									e.stopPropagation()
									setActiveSwitchButtonId && setActiveSwitchButtonId(button.id)
									
									if(button.guidance && button.guidance.open){
										button.guidance.next()
									}
								}} 
								key={'switch-button-'+index+'-'+button.text}
							>
								{button.icon ?? button.text}
							</div>
							{
								button.guidance &&
									<GuideModal
										open={button.guidance.open}
										closeModal={button.guidance.close}
									>
										<p>{button.guidance.text}</p>
									</GuideModal>
							}
						</div>
					)
				})
			}
		</div>
	)
}

export default SwitchButtons