//styles and icons
import './navBar.scss'
import {
	navlogo,
	logout,
	employee,
	project,
	sale,
	purchase,
	finance,
	chat,
	warehouse,
	settings,
	bell,
	feedbackIcon
} from '../../../../assets/general/generalIcons'

//react
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//components
import NavBarItem from './navBarItem/navBarItem'
import Button from '../../button/button'
import NavBarBurger from './navBarBurger/navBarBurger'
import LanguageSelector from '../../languageSelector/languageSelector'
import InfoPopup from '../../infoPopup/infoPopup'
import Loading from '../../loading/loading'
import FeedbackModal from './feedbackModal/feedbackModal'
import GuideModal from '../../modals/guideModal/guideModal'

//redux
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'
import { setCompaniesListModalIsOpen, setFeedbackModalIsOpen, setCreateCompanyGuidanceModals, setEmployeesGuidanceModalsClosed, setEmployeesGuidanceHierarchyModals } from '../../../../redux/general/modals'
import { setUserCompanyData } from '../../../../redux/general/general'

//types
import {
	navbarCategory,
	infoPopupTypes,
	infoPopupNavbarResponse,
	infoPopup,
	guidanceModalData
} from '../../../../types/general/generalTypes'

//network
import { navbarUrl } from '../../../../utils/old_urls/generalUrls'
import { authorizedRequest } from '../../../../utils/queries'
import { getUnreadMessagesCountUrl } from '../../../../utils/old_urls/general/generalUrls'
import { getNavbarPopupsUrl } from '../../../../utils/urls/general/navbar/navbar'

//translation
import { useTranslation } from 'react-i18next'

const NavBar = () => {

	const { t } = useTranslation('', { keyPrefix: 'general.layout.navbar' })

	const { userCompanyData } = useAppSelector((state) => state.general)
	const { createCompanyGuidanceModals, employeesGuidanceModals } = useAppSelector((state) => state.generalModals.guidanceModals)

	const companyId: number = userCompanyData?.companyId || -1

	const [navData, setNavData] = useState<navbarCategory[]>([])
	const [activeCategory, setActiveCategory] = useState<number>(0)
	const [mobile, setMobile] = useState(screen.width <= 768)
	const [mobileNavbBarOpen, setMobileNavBarOpen] = useState(false)
	const [unreadMessageCount, setUnreadMessageCount] = useState(0)
	const [infoPopups, setInfoPopups] = useState<infoPopupNavbarResponse[]>([])
	const [loading, setLoading] = useState<boolean>(false)

	const getInfoPopups = async () => {
		const { result } = await authorizedRequest(getNavbarPopupsUrl(companyId), 'GET')

		setInfoPopups(result)

		return result
	}

	const getInfoPopup = (category: string) => {
		if (infoPopups.length) {
			const popup = infoPopups.find(item => item.key === category)
			return popup?.info_popup
		}
	}

	const createOrPickCompanyPopup: infoPopup = {
		content: [
			{
				title: 'createOrPickCompany',
				description: 'createOrPickCompanyDescr',
			}
		],
		type: infoPopupTypes.INFO
	}

	const navigate = useNavigate()

	const dispatch = useAppDispatch()

	const logoutApp = () => {
		dispatch(setUserCompanyData(undefined))
		localStorage.removeItem('accessToken')
		localStorage.removeItem('refreshToken')
		localStorage.removeItem('avatar')
		localStorage.removeItem('userId')

		navigate('/login')
	}

	const handleResize = () => {
		setMobile(screen.width <= 768)
	}


	const getSettingsGuidanceOpen = (): guidanceModalData | undefined => {
		if(employeesGuidanceModals.hierarchy[0]){
			return {
				open: employeesGuidanceModals.hierarchy[0],
				next: () => { 
					dispatch(setEmployeesGuidanceHierarchyModals([false, true, false, false, false, false, false]))
				},
				close: () => { dispatch(setEmployeesGuidanceModalsClosed()) },
				text: 'employee1'
			}
		}

		return undefined
		
	}

	const [settingsGuidanceOpen, setSettingsGuidanceOpen] = useState<guidanceModalData | undefined>(getSettingsGuidanceOpen())
	
	useEffect(() => {
		setSettingsGuidanceOpen(getSettingsGuidanceOpen())
	}, [employeesGuidanceModals.hierarchy])

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		if (companyId > -1) {
			setLoading(true)
			authorizedRequest(navbarUrl(companyId), 'GET')
				.then((responce) => {
					const { result } = responce

					const navbar: navbarCategory[] = [
						{
							name: 'employees',
							icon: employee,
							subCategories: [
								{
									name: 'hierarchy',
									url: '/ri-business/employees/hierarchy',
									key: null
								},
								{
									name: 'searchEmployees',
									url: '/ri-business/employees/search-employee',
									key: null
								},
								{
									name: 'productivity',
									url: '/ri-business/employees/productivity',
									key: 'get_productivity'
								},
								{
									name: 'statistics',
									url: '/ri-business/employees/statistics',
									key: 'employee_statistics'
								}
							]
						},
						{
							name: 'projects',
							icon: project,
							subCategories: [
								{
									name: 'pipelines',
									url: '/ri-business/projects/pipelines',
									key: null
								},
								{
									name: 'myTasks',
									url: '/ri-business/projects/my-tasks',
									key: null
								},
								{
									name: 'statistics',
									url: '/ri-business/projects/statistics',
									key: 'project_statistics'
								}
							]
						},
						{
							name: 'purchases',
							icon: purchase,
							subCategories: [
								{
									name: 'providers',
									url: '/ri-business/purchase/provider-list',
									key: 'get_providers'
								},
								{
									name: 'purchaseOrders',
									url: '/ri-business/purchase/order-list',
									key: 'get_orders'
								},
								{
									name: 'statistics',
									url: '/ri-business/purchase/statistics',
									key: 'purchase_statistics'
								}
							]
						},
						{
							name: 'sales',
							icon: sale,
							subCategories: [
								{
									name: 'customers',
									url: '/ri-business/sales/customer-list',
									key: 'get_customers'
								},
								{
									name: 'salesOrders',
									url: '/ri-business/sales/order-list',
									key: 'get_orders'
								},
								{
									name: 'offerings',
									url: '/ri-business/sales/offering-list',
									key: 'get_offerings'
								},
								{
									name: 'statistics',
									url: '/ri-business/sales/statistics',
									key: 'warehouse_statistics'
								}
							]
						},
						{
							name: 'finance',
							icon: finance,
							subCategories: [
								{
									name: 'accounts',
									url: '/ri-business/finance/account-list',
									key: 'get_accounts'
								},
								{
									name: 'transactions',
									url: '/ri-business/finance/transaction-list',
									key: 'get_accounts'
								},
								{
									name: 'bills',
									url: '/ri-business/finance/bill-list',
									key: 'get_bills'
								},
								{
									name: 'budgets',
									url: '/ri-business/finance/budget-list',
									key: 'get_budgets'
								},
								// {
								// 	name: 'reports',
								// 	url: '/ri-business/finance/report-list',
								// 	key: 'get_accounts'
								// },
								{
									name: 'reports',
									url: '/ri-business/finance/financial-reports',
									key: 'financial_reports'
								},
								{
									name: 'statistics',
									url: '/ri-business/finance/statistics',
									key: 'finance_statistics'
								}
							]
						},
						{
							name: 'warehouses',
							icon: warehouse,
							subCategories: [

								{
									name: 'warehouseList',
									url: '/ri-business/warehouse/warehouse-list',
									key: 'get_warehouses'
								},
								{
									name: 'inventory',
									url: '/ri-business/warehouse/inventory',
									key: 'get_warehouses'
								},
								{
									name: 'shipments',
									url: '/ri-business/warehouse/shipments',
									key: 'get_warehouses'
								},
								{
									name: 'movingList',
									url: '/ri-business/warehouse/moving-list',
									key: 'get_warehouses'
								},
								{
									name: 'statistics',
									url: '/ri-business/warehouse/statistics',
									key: 'warehouse_stats'
								}
							]
						}
					]


					navbar.forEach((category) => {
						category.subCategories = category.subCategories.filter((subCategory) => {
							return result.indexOf(subCategory.key) !== -1 || subCategory.key === null
						})

					})


					setNavData([...navbar.filter((category) => category.subCategories.length !== 0)])

				}).finally(() => {
					setLoading(false)
				})
		}
	}, [userCompanyData])

	useEffect(() => {
		authorizedRequest(getUnreadMessagesCountUrl, 'GET')
			.then(response => {
				const unreadMessages: number = response.result
				setUnreadMessageCount(unreadMessages)
			})

		const interval = setInterval(() => {
			authorizedRequest(getUnreadMessagesCountUrl, 'GET')
				.then(response => {
					const unreadMessages: number = response.result
					setUnreadMessageCount(unreadMessages)
				})
		}, 5000)

		return () => clearInterval(interval)
	}, [])

	useEffect(() => {
		if(companyId > 0) getInfoPopups()
	}, [])
 
	return (
		<>
			<div className={`navbar ${mobile ? 'mobile' : ''} ${mobileNavbBarOpen ? 'open' : ''}`}>
				<div className="navbar-wrapper">
					<div className="navbar-container">
						<div className="navbar-header-wrapper">
							<div className="navbar-header" onClick={() => {
								if(createCompanyGuidanceModals[0]){
									dispatch(setCreateCompanyGuidanceModals([false, true]))
								}

								dispatch(setCompaniesListModalIsOpen(true))
							}}>
								<span className="navbar-company-avatar-container">{userCompanyData?.companyAvatar && userCompanyData.companyAvatar != 'null' ? <img src={userCompanyData.companyAvatar} /> : navlogo}</span>
								{userCompanyData?.companyName ? <p className="navbar-head-text">{userCompanyData?.companyName}</p> : <InfoPopup {...createOrPickCompanyPopup} />}
							</div>
							<GuideModal
								next={() => {
									dispatch(setCreateCompanyGuidanceModals([false, true]))
								}}
								closeModal={() => {
									dispatch(setCreateCompanyGuidanceModals([false, false]))
								}}
								open={createCompanyGuidanceModals[0]}
							>
								<></>
							</GuideModal>
						</div>
						<div className="navbar-content">
							{loading ? <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
								<Loading style={{ position: 'relative', top: '0', left: '0' }} />
							</div> : navData.map((item, i) => {
								const popup = getInfoPopup(item.name)
								return (
									<NavBarItem
										key={i}
										icon={item.icon}
										name={item.name}
										infoPopup={popup}
										subCategories={item.subCategories}
										index={i + 1}
										activeCategory={activeCategory}
										setActiveCategory={setActiveCategory}
									/>
								)
							})}
						</div>
					</div>
					<div className="navbar-bottom">
						<div className="misc-nav">
							<div className="nav-button" onClick={() => {
								dispatch(setFeedbackModalIsOpen(true))
							}} style={{ cursor: 'pointer' }}>
								<div className="icon">
									{feedbackIcon}
								</div>
								<p>{t('feedback')}</p>
							</div>
							<Link className="nav-button" to={'/ri-business/chats'}>
								<div className="icon">
									{chat}
								</div>
								{t('chats')}
								{
									unreadMessageCount > 0 &&
									<div className="unread-messages-container">
										{unreadMessageCount > 99 ? '99+' : unreadMessageCount}
									</div>
								}
							</Link>
							<Link className="nav-button" to={'/ri-business/notifications'}>
								<div className="icon">
									{bell}
								</div>
								{t('notifications')}
							</Link>
							<div className="nav-button-wrapper">
								<Link className="nav-button" to={'/ri-business/settings'} onClick={() => {
									settingsGuidanceOpen && settingsGuidanceOpen.next()
								}}>
									<div className="icon">
										{settings}
									</div>
									{t('settings')}
								</Link>
								<GuideModal
									next={settingsGuidanceOpen?.next}
									open={settingsGuidanceOpen?.open}
									closeModal={settingsGuidanceOpen?.close}
								>
									{settingsGuidanceOpen?.text}
								</GuideModal>
							</div>
							<div className="language-selector-container">
								<LanguageSelector />
							</div>
						</div>
						<div className="logout">
							<Button active={true} onClick={logoutApp} text={t('logout')} icon={logout} />
						</div>
					</div>
				</div>
				{mobile && <NavBarBurger open={mobileNavbBarOpen} setOpen={(value) => { setMobileNavBarOpen(value) }} />}
			</div>
			<FeedbackModal />
		</>
	)
}

export default NavBar
