
//react
import { FC, useState } from 'react'

//components
import ConfirmationModal from '../../confirmationModal/confirmationModal'

//translation
import { useTranslation } from 'react-i18next'

type skipGuidanceConfirmationModalProps = {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
    closeModal: () => void
}

const SkipGuidanceConfirmationModal: FC<skipGuidanceConfirmationModalProps> = ({ isOpen, setIsOpen, closeModal }) => {
	const { t } = useTranslation('', {keyPrefix: 'general.modals.guidanceModal.skipGuidanceConfirmationModal'})


	return (
		<ConfirmationModal
			title={t('title')}
			buttons={[
				{
					text: t('yes'),
					onClickHandler: () => {
						closeModal()
						setIsOpen(false)
					}
				},
				{
					text: t('no'),
					onClickHandler: () => {
						setIsOpen(false)
					}
				}
			]}
			isOpen={isOpen}
			closeModal={() => {
				setIsOpen(false)
			}}
			zIndex={100001}
		/>
	)
}

export default SkipGuidanceConfirmationModal