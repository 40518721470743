import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { file } from '../../types/general/generalTypes'

type generalModalsState = {
  companiesList: {
    modalIsOpen: boolean;
  };
  feedbackModal: {
    modalIsOpen: boolean;
  };
  createCompany: {
    modalIsOpen: boolean;
  };
  attachFiles: {
    modalIsOpen: boolean;
  };
  createTaskModal: {
    modalIsOpen: boolean;
    stageId: number | undefined;
  };
  createBillModal: {
    modalIsOpen: boolean;
  };
  deleteBillModal: {
    modalIsOpen: boolean;
    billId: number | null;
  };
  payBillModal: {
    modalIsOpen: boolean;
    billId: number | null;
  };
  importBillFileSelectorModal: {
    modalIsOpen: boolean;
    file: file | null;
  };
  billTablePreviewModal: {
    modalIsOpen: boolean;
  };
  exportBillFileModal: {
    modalIsOpen: boolean;
  };
  exportReportFileModal: {
    modalIsOpen: boolean;
  };
  createAccountModal: {
    modalIsOpen: boolean;
  };
  deleteAccountModal: {
    modalIsOpen: boolean;
    accountId: number | null;
  };
  importAccountFileSelectorModal: {
    modalIsOpen: boolean;
    file: file | null;
  };
  accountTablePreviewModal: {
    modalIsOpen: boolean;
  };
  exportAccountFileModal: {
    modalIsOpen: boolean;
  };
  restrictUserModal: {
    modalIsOpen: boolean;
  };
  createColumnModal: {
    modalIsOpen: boolean;
  };
  deleteColumnModal: {
    modalIsOpen: boolean;
    columnId: number | null;
  };
  deleteTemplateModal: {
    modalIsOpen: boolean;
    templateId: number | null;
  };
  guidanceModals: {
    createCompanyGuidanceModals: [boolean, boolean];
    employeesGuidanceModals: {
		hierarchy: [boolean, boolean, boolean, boolean, boolean, boolean, boolean]
		searchEmployees: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]
		productivity: [boolean, boolean, boolean, boolean]
	},
	projectsGuidanceModals: {
		pipelines: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]
		myTasks: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]
	},
    purchasesGuidanceModals: {
		providers: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]
		orders: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]
	},
	salesGuidanceModals: {
		customers: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]
		orders:  [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]
	}
    pipelineGuidanceModals: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean];
    financeGuidanceModals: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean];
	warehouseGuidanceModals: [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean];
	settingsGuidanceModals: [boolean, boolean, boolean]
  };
};

const initialState: generalModalsState = {
	companiesList: {
		modalIsOpen: false
	},
	feedbackModal: {
		modalIsOpen: false
	},
	createCompany: {
		modalIsOpen: false
	},
	attachFiles: {
		modalIsOpen: false
	},
	createTaskModal: {
		modalIsOpen: false,
		stageId: undefined
	},

	createBillModal: {
		modalIsOpen: false
	},
	deleteBillModal: {
		modalIsOpen: false,
		billId: null
	},
	payBillModal: {
		modalIsOpen: false,
		billId: null,
	},
	importBillFileSelectorModal: {
		modalIsOpen: false,
		file: null
	},
	billTablePreviewModal: {
		modalIsOpen: false
	},
	exportBillFileModal: {
		modalIsOpen: false
	},
	exportReportFileModal: {
		modalIsOpen: false
	},
	createAccountModal: {
		modalIsOpen: false
	},
	deleteAccountModal: {
		modalIsOpen: false,
		accountId: null
	},
	importAccountFileSelectorModal: {
		modalIsOpen: false,
		file: null
	},
	accountTablePreviewModal: {
		modalIsOpen: false
	},
	exportAccountFileModal: {
		modalIsOpen: false
	},
	restrictUserModal: {
		modalIsOpen: false
	},
	createColumnModal: {
		modalIsOpen: false
	},
	deleteColumnModal: {
		modalIsOpen: false,
		columnId: null
	},
	deleteTemplateModal: {
		modalIsOpen: false,
		templateId: null
	},
	guidanceModals: {
		createCompanyGuidanceModals: [false, false],
		employeesGuidanceModals: {
			hierarchy: [false, false, false, false, false, false, false],
			searchEmployees: [false, false, false, false, false, false, false, false],
			productivity: [false, false, false, false]	
		},
		projectsGuidanceModals: {
			pipelines: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
			myTasks: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
		},
		purchasesGuidanceModals: {
			providers: [false, false, false, false, false, false, false, false, false, false, false, false],
			orders: [false, false, false, false, false, false, false, false, false, false]
		},
		salesGuidanceModals: {
			customers: [false, false, false, false, false, false, false, false, false, false, false],
			orders: [false, false, false, false, false, false, false, false, false, false]
		},
		pipelineGuidanceModals: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
		financeGuidanceModals: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
		warehouseGuidanceModals: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
		settingsGuidanceModals: [false, false, false]
	}
}


export const generalModalsSlice = createSlice({
	name: 'generalModalsSlice',
	initialState,
	reducers: {
		setCompaniesListModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.companiesList.modalIsOpen = action.payload
		},
		setFeedbackModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.feedbackModal.modalIsOpen = action.payload
		},
		setCreateCompanyModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createCompany.modalIsOpen = action.payload
		},
		setAttachFilesModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.attachFiles.modalIsOpen = action.payload
		},
		setCreateTaskModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createTaskModal.modalIsOpen = action.payload
		},
		setCreateTaskModalStageId: (state, action: PayloadAction<number | undefined>) => {
			state.createTaskModal.stageId = action.payload
		},

		setCreateBillModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createBillModal.modalIsOpen = action.payload
		},
		setDeleteBillModalIsOpen: ( state, action: PayloadAction<boolean>) => {
			state.deleteBillModal.modalIsOpen = action.payload
		},
		setDeleteBillId: ( state, action: PayloadAction<number | null>) => {
			state.deleteBillModal.billId = action.payload
		},
		setImportBillFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importBillFileSelectorModal.modalIsOpen = action.payload
		},
		setImportBillFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.importBillFileSelectorModal.file = action.payload
		},
		setBillTablePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.billTablePreviewModal.modalIsOpen = action.payload
		},
		setExportBillFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportBillFileModal.modalIsOpen = action.payload
		},
		setExportReportFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportReportFileModal.modalIsOpen = action.payload
		},
		setCreateAccountModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createAccountModal.modalIsOpen = action.payload
		},
		setDeleteAccountModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deleteAccountModal.modalIsOpen = action.payload
		},
		setDeleteAccountId: (state, action: PayloadAction<number | null>) => {
			state.deleteAccountModal.accountId = action.payload
		},
		setImportAccountFileSelectorModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.importAccountFileSelectorModal.modalIsOpen = action.payload
		},
		setImportAccountFileSelectorModalFile: (state, action: PayloadAction<file | null>) => {
			state.importAccountFileSelectorModal.file = action.payload
		},
		setAccountTablePreviewModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.accountTablePreviewModal.modalIsOpen = action.payload
		},
		setExportAccountFileModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.exportAccountFileModal.modalIsOpen = action.payload
		},
		setPayBillModalIsOpen: ( state, action: PayloadAction<boolean>) => {
			state.payBillModal.modalIsOpen = action.payload
		},
		setPayBillId: ( state, action: PayloadAction<number | null>) => {
			state.payBillModal.billId = action.payload
		},
		setRestrictModalOpen: (state, action: PayloadAction<boolean>) => {
			state.restrictUserModal.modalIsOpen = action.payload
		},
		setCreateColumnModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.createColumnModal.modalIsOpen = action.payload
		},
		setDeleteColumnModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deleteColumnModal.modalIsOpen = action.payload
		},
		setDeleteColumnId: (state, action: PayloadAction<number | null>) => {
			state.deleteColumnModal.columnId = action.payload
		},
		setDeleteTemplateModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.deleteTemplateModal.modalIsOpen = action.payload
		},
		setDeleteTemplateId: (state, action: PayloadAction<number | null>) => {
			state.deleteTemplateModal.templateId = action.payload
		},
		setCreateCompanyGuidanceModals: (state, action: PayloadAction<[boolean, boolean]>) => {
			state.guidanceModals.createCompanyGuidanceModals = action.payload
		},
		setEmployeesGuidanceHierarchyModals: (state, action: PayloadAction<[boolean, boolean, boolean, boolean, boolean, boolean, boolean]>) => {
			state.guidanceModals.employeesGuidanceModals.hierarchy = action.payload
		},
		setEmployeesGuidanceSearchEmployeesModals: (state, action: PayloadAction<[boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]>) => {
			state.guidanceModals.employeesGuidanceModals.searchEmployees = action.payload
		},
		setEmployeesGuidanceProductivityModals: (state, action: PayloadAction<[boolean, boolean, boolean, boolean]>) => {
			state.guidanceModals.employeesGuidanceModals.productivity = action.payload
		},
		setEmployeesGuidanceModalsClosed: (state) => {
			state.guidanceModals.employeesGuidanceModals.hierarchy = [false, false, false, false, false, false, false]
			state.guidanceModals.employeesGuidanceModals.searchEmployees = [false, false, false, false, false, false, false, false]
			state.guidanceModals.employeesGuidanceModals.productivity = [false, false, false, false]
		},
		setProjectsGuidancePipelinesModals: (state, action: PayloadAction<[boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]>) => {
			state.guidanceModals.projectsGuidanceModals.pipelines = action.payload
		},
		setProjectsGuidanceMyTasksModals: (state, action: PayloadAction<[boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]>) => {
			state.guidanceModals.projectsGuidanceModals.myTasks = action.payload
		},
		setProjectsGuidanceModalsClosed: (state) => {
			state.guidanceModals.projectsGuidanceModals.pipelines = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
			state.guidanceModals.projectsGuidanceModals.myTasks = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
		},
		setPurchasesGuidanceProviderModals: (state, action: PayloadAction<[boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]>) => {
			state.guidanceModals.purchasesGuidanceModals.providers = action.payload
		},
		setPurchasesGuidanceOrderModals: (state, action: PayloadAction<[boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]>) => {
			state.guidanceModals.purchasesGuidanceModals.orders = action.payload
		},
		setPurchasesGuidanceModalsClosed: (state) => {
			state.guidanceModals.purchasesGuidanceModals.providers = [false, false, false, false, false, false, false, false, false, false, false, false]
			state.guidanceModals.purchasesGuidanceModals.orders = [false, false, false, false, false, false, false, false, false, false]
		},
		setSalesGuidanceCustomerModals: (state,  action: PayloadAction<[boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]>) => {
			state.guidanceModals.salesGuidanceModals.customers = action.payload
		},
		setSalesGuidanceOrderModals: (state, action: PayloadAction<[boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]>) => {
			state.guidanceModals.salesGuidanceModals.orders = action.payload
		},
		setSalesGuidanceModalsClosed: (state) => {
			state.guidanceModals.salesGuidanceModals.customers = [false, false, false, false, false, false, false, false, false, false, false]
			state.guidanceModals.salesGuidanceModals.orders = [false, false, false, false, false, false, false, false, false, false]
		},
		setPipelineGuidanceModals: (state, action: PayloadAction<[boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]>) => {
			state.guidanceModals.pipelineGuidanceModals = action.payload
		},
		setPipelineGuidanceModalsClosed: (state) => {
			state.guidanceModals.pipelineGuidanceModals = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
		},
		setFinanceGuidanceModals: (state, action: PayloadAction<[boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]>) => {
			state.guidanceModals.financeGuidanceModals = action.payload
		},
		setFinanceGuidanceModalsClosed: (state) => {
			state.guidanceModals.financeGuidanceModals = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
		},
		setWarehouseGuidanceModals: (state, action: PayloadAction<[boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean]>) => {
			state.guidanceModals.warehouseGuidanceModals = action.payload
		},
		setWarehouseGuidanceModalsClosed: (state) => {
			state.guidanceModals.warehouseGuidanceModals = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
		},
		setSettingsGuidanceModals: (state, action: PayloadAction<[boolean, boolean, boolean]>) => {
			state.guidanceModals.settingsGuidanceModals = action.payload
		},
		setSettingsGuidanceModalsClosed: (state) => {
			state.guidanceModals.settingsGuidanceModals = [false, false, false]
		}
	}
})

export const {
	setCompaniesListModalIsOpen,
	setFeedbackModalIsOpen,
	setCreateCompanyModalIsOpen,
	setAttachFilesModalIsOpen,
	setCreateTaskModalIsOpen,
	setCreateTaskModalStageId,
	setCreateAccountModalIsOpen,
	setDeleteAccountModalIsOpen,
	setDeleteAccountId,
	setImportAccountFileSelectorModalIsOpen,
	setImportAccountFileSelectorModalFile,
	setAccountTablePreviewModalIsOpen,
	setExportAccountFileModalIsOpen,
	setCreateBillModalIsOpen,
	setDeleteBillModalIsOpen,
	setDeleteBillId,
	setImportBillFileSelectorModalIsOpen,
	setImportBillFileSelectorModalFile,
	setExportReportFileModalIsOpen,
	setBillTablePreviewModalIsOpen,
	setExportBillFileModalIsOpen,
	setPayBillModalIsOpen,
	setPayBillId,
	setRestrictModalOpen,
	setCreateColumnModalIsOpen,
	setDeleteColumnModalIsOpen,
	setDeleteColumnId,
	setDeleteTemplateModalIsOpen,
	setDeleteTemplateId,
	setCreateCompanyGuidanceModals,
	setEmployeesGuidanceHierarchyModals,
	setEmployeesGuidanceSearchEmployeesModals,
	setEmployeesGuidanceProductivityModals,
	setEmployeesGuidanceModalsClosed,
	setProjectsGuidancePipelinesModals,
	setProjectsGuidanceMyTasksModals,
	setProjectsGuidanceModalsClosed,
	setPurchasesGuidanceProviderModals,
	setPurchasesGuidanceOrderModals,
	setPurchasesGuidanceModalsClosed,
	setSalesGuidanceCustomerModals,
	setSalesGuidanceOrderModals,
	setSalesGuidanceModalsClosed,
	setPipelineGuidanceModals,
	setPipelineGuidanceModalsClosed,
	setFinanceGuidanceModals,
	setFinanceGuidanceModalsClosed,
	setWarehouseGuidanceModals,
	setWarehouseGuidanceModalsClosed,
	setSettingsGuidanceModals,
	setSettingsGuidanceModalsClosed
} = generalModalsSlice.actions

export default generalModalsSlice.reducer