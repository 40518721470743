//styles
import './positionList.scss'
import { deleteIcon, editPencil } from '../../../../../assets/general/generalIcons'

//react
import { useState, useEffect } from 'react'

//network
import { authorizedRequest } from '../../../../../utils/queries'
import { getCompanyPositionsUrl } from '../../../../../utils/old_urls/general/generalUrls'

//components
import Pagination from '../../../pagination/pagination'
import ColumnLayout from '../../../columnLayout/columnLayout'
import CounterpartyCard from '../../../counterpartyCard/counterpartyCard'
import CreatePositionModal from './modals/createPositionModal/createPositionModal'
import DeletePositionModal from './modals/deletePositionModal/deletePositionModal'
import FilterBar from '../../../layout/filterBar/filterBar'
import EditPositionModal from './modals/editPositionModal/editPositionModal'

//redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import {
	setCreatePositionModalIsOpen,
	setDeletePositionId,
	setDeletePositionModalIsOpen,
	setEditPositionId,
	setEditPositionModalIsOpen
} from '../../../../../redux/general/settings/modals'
import { setEmployeesGuidanceModalsClosed } from '../../../../../redux/general/modals'

//translation
import { useTranslation } from 'react-i18next'

//types
import { position, positionResponse } from '../../../../../types/general/settings/companySectionTypes'

//other
import { getItemsPerColumn } from '../../../../../assets/general/generalFunctions'

const PositionList= () => {
	const [loading, setLoading] = useState(true)
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn(3))
	const [searchRequest, setSearchRequest] = useState('')
	const [positions, setPositions] = useState<position[]>([])

	const { settingsActiveCompanyId } = useAppSelector(state => state.settingsGeneral)
	const { employeesGuidanceModals } = useAppSelector(state => state.generalModals.guidanceModals)

	const dispatch = useAppDispatch()
	const { t } = useTranslation('', { keyPrefix: 'general.settings.companySettingsSection' })

	const loadData = async (page: number, request: string) => {
		if (settingsActiveCompanyId) {
			const { result } = await authorizedRequest(getCompanyPositionsUrl(settingsActiveCompanyId, request, page, 10), 'GET')
			if (result.length > 0) {
				const positions: position[] = result.map((position: positionResponse) => {
					return position
				})
				return positions
			}
		}
		return []
	}

	const onSearch = (searchValue: string) => {
		setPage(1)
		setSearchRequest(searchValue)
		setLastPage(false)
		setLoading(true)
		loadData(1, searchValue).then(result => {
			setPositions(result)
			if (result.length > 0) {
				setPage(page + 1)
			} else {
				setLastPage(true)
			}
			setLoading(false)
		})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest).then(result => {
				setPositions([...positions, ...result])
				if (result.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})

		}
	}


	useEffect(() => {
		onSearch(searchRequest)
	}, [settingsActiveCompanyId])

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])


	return (
		<div className="company-positions">
			<h2 className='position-heading'>{t('positions')}</h2>
			<FilterBar
				onSearch={onSearch}
				addButton={{
					text: t('add'),
					active: true,
					onClick: () => {
						dispatch(setCreatePositionModalIsOpen(true))
					}
				}}
				guidance={{
					open: employeesGuidanceModals.hierarchy[2],
					next: () => { 

					},
					close: () => { dispatch(setEmployeesGuidanceModalsClosed()) },
					text: 'employee3'
				}}
			/>
			<Pagination onLoadMore={handleLoadMore} onlyLoadOn='bottom' loading={loading}>
				<ColumnLayout amount={itemsPerColumn}>
					{positions.map(position =>
						<CounterpartyCard
							translateTags={'general.skills'}
							name={position.name}
							tags={position.skills.map(skill => ({ title: skill.name, value: '' }))}
							actions={[
								{
									id: 1, icon: editPencil, onClick: () => {
										dispatch(setEditPositionModalIsOpen(true))
										dispatch(setEditPositionId(position.id))
									}
								},
								{
									id: 2, icon: deleteIcon, onClick: () => {
										dispatch(setDeletePositionModalIsOpen(true))
										dispatch(setDeletePositionId(position.id))
									}
								}
							]}
						/>
					)}
				</ColumnLayout>
			</Pagination>

			<CreatePositionModal
				positions={positions}
				setPositions={setPositions}
			/>
			<DeletePositionModal
				positions={positions}
				setPositions={setPositions}
			/>
			<EditPositionModal
				positions={positions}
				setPositions={setPositions}
			/>
		</div>
	)

}

export default PositionList