// react
import { useState, FC } from 'react'

//components
import Layout from '../../../components/general/layout/layout'
import CompanyProductivity from '../../../components/employees/productivity/companyProductivity/companyProductivity'
import IndividualProductivity from '../../../components/employees/productivity/individualProductivity/individualProductivity'

// types
import {
	button,
	filterBar,
	infoPopupTypes
} from '../../../types/general/generalTypes'

//redux
import { useAppSelector, useAppDispatch } from '../../../customHooks/redux'
import { setEmployeesGuidanceProductivityModals, setEmployeesGuidanceModalsClosed } from '../../../redux/general/modals'

// translation
import { useTranslation } from 'react-i18next'

const Productivity: FC = () => {
	const dispatch = useAppDispatch()

	const { employeesGuidanceModals } = useAppSelector(state => state.generalModals.guidanceModals)

	const [activeProjectSection, setActiveProjectSection] = useState('company')
	const [filterBar, setFilterBar] = useState<filterBar | undefined>()

	const { t } = useTranslation('', { keyPrefix: 'employees.productivity' })


	const projectTabButtons: button[] = [
		{
			active: 'company' === activeProjectSection,
			text: t('company'),
			onClick: () => { setActiveProjectSection('company') },
			guidance: {
				open: employeesGuidanceModals.productivity[2],
				next: () => { dispatch(setEmployeesGuidanceProductivityModals([false, false, false, true])) },
				close: () => { dispatch(setEmployeesGuidanceModalsClosed()) },
				text: 'employe2'
			}
		},
		{
			active: 'individual' === activeProjectSection,
			text: t('individual'),
			onClick: () => { setActiveProjectSection('individual') },
			guidance: {
				open: employeesGuidanceModals.productivity[3],
				next: () => { dispatch(setEmployeesGuidanceProductivityModals([false, false, false, false])) },
				close: () => { dispatch(setEmployeesGuidanceModalsClosed()) },
				text: 'employe2'
			}
		}
	]

	const titleUrls = [
		{
			url: '/ri-business/employees/productivity',
			title: t('productivity')
		}
	]

	const infoPopup = {
		content: [{
			title: 'productivity_info_title',
			description: 'productivity_info_description',
			link: '/ri-manual/employees/productivity'
		}],
		type: infoPopupTypes.INFO
	}

	return (
		<Layout
			header={{
				avatar: true
			}}
			tabButtons={projectTabButtons}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
		>

			<div className='project-wrapper'>
				{
					activeProjectSection === 'company' ?
						<CompanyProductivity />
						: null
				}
				{
					activeProjectSection === 'individual' ?
						<IndividualProductivity setFilterBar={setFilterBar} />
						: null
				}
				
			</div>
		</Layout>
	)

}

export default Productivity