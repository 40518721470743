//react
import { FC } from 'react'

//components
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import OrderCard from '../../../general/orderCard/orderCard'
import OrderItemSkeleton from '../../../general/skeletons/orderItem/orderItemSkeleton'
import GuideModal from '../../../general/modals/guideModal/guideModal'

//redux
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'
import { setSalesGuidanceModalsClosed, setSalesGuidanceOrderModals } from '../../../../redux/general/modals'

//types
import { order } from '../../../../types/general/generalTypes'

type salesOrderCardListProps = {
	orders: order[]
	loading: boolean
	itemsPerColumn: number
}
const SalesOrderCardList: FC<salesOrderCardListProps> = ({
	orders,
	loading,
	itemsPerColumn,
}) => {
	const dispatch = useAppDispatch()
	const { salesGuidanceModals } = useAppSelector(state => state.generalModals.guidanceModals)

	return (
		<ColumnLayout amount={itemsPerColumn}>
			{orders.map((order, index) => {
				return index === 0 ?
					<div>
						<OrderCard {...order} key={'order' + index}  onClick={() => {
								if(salesGuidanceModals.orders[5]){
									dispatch(setSalesGuidanceOrderModals(([false, false, false, false, false, false, true, false, false, false])))
								}
							}}/>
						<GuideModal
							open={salesGuidanceModals.orders[5]}
							closeModal={() => { dispatch(setSalesGuidanceModalsClosed()) }}
						>
							<p>text</p>
						</GuideModal>
					</div>
				: <OrderCard {...order} key={'order' + index} />
			})}
			{loading ? <OrderItemSkeleton /> : null}
		</ColumnLayout>
	)
}

export default SalesOrderCardList