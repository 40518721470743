//react
import { Link } from 'react-router-dom'
import { FC, useEffect, useState } from 'react'

//components
import GuideModal from '../../../../modals/guideModal/guideModal'

//translation
import { useTranslation } from 'react-i18next'

type guidanceModal = {
    open: boolean
	next: () => void
	close: () => void
	text: string
}

type navBarItemSubcategoryProps = {
    url: string
    name: string
    guidance: guidanceModal | undefined
    active: boolean
}

const NavBarItemSubcategory: FC<navBarItemSubcategoryProps> = ({ url, name, guidance, active }) => {
	const { t } = useTranslation('', { keyPrefix: 'general.layout.navbar' })

	const [renderGuidance, setRenderGuidance] = useState(false)

	useEffect(() => {
		if(active){
			setTimeout(() => {
				setRenderGuidance(true)
			}, 500)
		}
	}, [active])

	return (
		<div className="navbar-link-wrapper">
			<Link
				className='navbar-link'
				to={url!}
				onClick={() => {
					if(guidance && guidance.open){
						guidance.next()
					}
				}}
			>
				<p>{t(name)}</p>
			</Link>
			{guidance && renderGuidance && <GuideModal
				open={guidance.open}
				closeModal={guidance.close}
			>
				{guidance.text}
			</GuideModal>}	
		</div>
	)
}

export default NavBarItemSubcategory