// Main Library
import i18n from "i18next";

// Middleware
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Dynamic Imports for Translation Data
import en from "./en/translation.json";
import ua from "./ua/translation.json";
import ru from "./ru/translation.json";

// Initialize i18n
i18n
  .use(LanguageDetector) // Automatically detect user language
  .use(initReactI18next) // Bind i18n to React
  .init({
    debug: true, // Enable debug mode for easier debugging during development
    fallbackLng: "ua", // Fallback language if no translation is found
    lng: "ua", // Default language
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    resources: {
      en: { translation: en },
      ua: { translation: ua },
      ru: { translation: ru },
    }
  });

  // // Set the language explicitly after loading translations
  // const detectedLanguage = i18n.language || "ua"; // Use detected language or fallback
  // i18n.changeLanguage(detectedLanguage); // Change to the detected language

export default i18n;
