import { ThemeContextProvider } from './components/general/theme/context/themeContext'
import Router from './router'

//react
import { useEffect } from 'react'

//redux
import { useAppSelector } from './customHooks/redux'

//translation
import { useTranslation } from 'react-i18next'

const App = () => {
	const { i18n } = useTranslation()

	const { language, userCompanyData } = useAppSelector(state => state.general)

	useEffect(() => {
		console.log(language)
		i18n.changeLanguage(language)
	}, [language])

	useEffect(() => {

		if(userCompanyData){
			localStorage.setItem('companyId', `${userCompanyData.companyId}`)
			localStorage.setItem('employeeId', `${userCompanyData.employeeId}`)
			localStorage.setItem('companyAvatar', `${userCompanyData.companyAvatar}`)
			localStorage.setItem('companyName', `${userCompanyData.companyName}`)
		}else{
			localStorage.setItem('companyId', '')
			localStorage.setItem('employeeId', '')
			localStorage.setItem('companyAvatar', '')
			localStorage.setItem('companyName', '')
		}
	}, [userCompanyData])

	return (
		<>
			<ThemeContextProvider defaultTheme='default'>
				<Router/>
			</ThemeContextProvider>
		</>
	)
}

export default App
