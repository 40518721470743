//styles 
import './pipelineDashboard.scss'

//react
import { FC, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

//components
import Dashboard from '../../general/dashboard/dashboard'
import MoveProjectModal from '../pipeline/modals/moveProjectModal/moveProjectModal'
import DeleteProjectConfirmationModal from '../pipeline/modals/deleteProjectConfirmationModal/deleteProjectConfirmationModal'
import CloseProjectConfirmationModal from '../pipeline/modals/closeProjectConfirmationModal/closeProjectConfirmationModal'
import CreateProjectModal from '../pipeline/modals/createProjectModal/createProjectModal'

//types
import { project, projectResponse, stageResponse } from '../../../types/general/generalTypes'
import { dashboardAction, dragLocation, dragStage } from '../../../types/general/generalTypes'

// network
import { authorizedRequest } from '../../../utils/queries'
import { singleProjectMoveUrl } from '../../../utils/urls/projects/project'
import { pipelinesGetProjectsResourceUrl } from '../../../utils/urls/projects/pipelines'

//redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setCloseProjectModalIsOpen, setCloseProjectId, setDeleteProjectId, setDeleteProjectModalIsOpen, setMoveProjectId, setMoveProjectModalIsOpen, setCreateProjectStageId, setCreateProjectModalIsOpen } from '../../../redux/projects/pipeline/modals'

// other
import { formatProjectResponse, formatStageResponse, moveItem } from '../../../assets/projects/projectsFunctions'

//translation
import { useTranslation } from 'react-i18next'

type pipelineDashboardProps = {
	showClosed?: boolean
}

const PipelineDashboard: FC<pipelineDashboardProps> = ({ showClosed=false }) => {
	const { t } = useTranslation('', { keyPrefix: 'projects.pipeline' })

	const dispatch = useAppDispatch()

	const { pipelineId } = useParams()

	const [stageList, setStageList] = useState<dragStage<project>[]>([])
	const [projects, setProjects] = useState<project[]>([])

	const navigate = useNavigate()


	const loadProjects = async (stageId: number, searchRequest: string, page: number): Promise<project[]>  => {
		const response = await authorizedRequest(pipelinesGetProjectsResourceUrl(Number(pipelineId)) + `?&needle=${searchRequest}&status=${stageId}&page=${page}&show_closed=${showClosed}`, 'GET')
		const { result } = response
		return result.map((project: projectResponse) => formatProjectResponse(project))
		
	}

	const loadStages = async (): Promise<dragStage<project>[]> => {
		const response = await authorizedRequest(pipelinesGetProjectsResourceUrl(Number(pipelineId)) + `?show_closed=${showClosed}`, 'PATCH')
		const { result } = response
		return result.map((stage: stageResponse) => formatStageResponse(stage))
	}

	const moveProject = (dragInfo: dragLocation, dropInfo: dragLocation) => {
		const move = (data: project, newStageId: number) => {
			authorizedRequest(singleProjectMoveUrl(data.id), 'PUT', 'accessToken', {
				stage_id: newStageId
			})
		}

		setStageList((newStagesList) => {
			return moveItem(dragInfo, dropInfo, newStagesList, move).map((stage) => {
				return dragInfo.stageId === dropInfo.stageId ? stage : {
					...stage,
					quantity: stage.stageId === dragInfo.stageId ? stage.quantity - 1 : stage.stageId === dropInfo.stageId ? stage.quantity + 1 : stage.quantity
				}
			})
		})
	}

	const pipelineAction: dashboardAction[] = [
		{
			slug: 'pipeline-delete',
			label: t('delete'),
			color: '#FF4E4E',
			onDrop: (e, drag, drop, data) => {
				if (data && typeof data == 'object' && 'id' in data) {
					dispatch(setDeleteProjectModalIsOpen(true))
					dispatch(setDeleteProjectId(data.id as number))
				}
			}
		}, {
			slug: 'pipeline-success',
			label: t('close'),
			color: '#1DB954',
			onDrop: (e, drag, drop, data) => {
				if (data && typeof data == 'object' && 'id' in data) {
					dispatch(setCloseProjectModalIsOpen(true))
					dispatch(setCloseProjectId(data.id as number))
				}
			}
		}, {
			slug: 'pipeline-moveto',
			label: t('moveTo'),
			color: '#376ABE',
			onDrop: (e, drag, drop, data) => {
				if (data && typeof data == 'object' && 'id' in data) {
					dispatch(setMoveProjectModalIsOpen(true))
					dispatch(setMoveProjectId(data.id as number))
				}
			}
		}
	]

	const onLoadMore = (stageId: number)=>{
		const stageIndex = stageList.findIndex(stage => stage.stageId === stageId)

		if(stageIndex < 0){
			return
		}

		const stage = stageList[stageIndex]

		if(!stage.isLoading && !stage.lastPage ){

			stageList[stageIndex].isLoading = true
			setStageList([...stageList])
			loadProjects(stageId, '', stage.page)
				.then(projectRes=>{
					setProjects([...projects, ...projectRes.filter(task=>!projects.some(el=>el.id === task.id))])

					setStageList(prevStages => {
						return prevStages.map((stage, index) => {
							if (index === stageIndex) {
								return {
									...stage,
									page: stage.page + 1,
									isLoading: false,  // Ensure isLoading is set to false here
									lastPage: projectRes.length < 5,
								}
							}
							return stage
						})
					})

				})
		}
	}

	useEffect(() => {
		loadStages()
			.then((stages) => {
				setStageList([...stages.map((stage) => ({...stage, isLoading: true}))])

				const loadProjectsPromises = stages.map((stage) => loadProjects(stage.stageId, '', stage.page))

				// Wait for all tasks to be loaded
				Promise.all(loadProjectsPromises)
					.then((projectsArrays) => {
						const allProjects = projectsArrays.reduce((accumulator, currentTasks) => accumulator.concat(currentTasks), [])
						setProjects(allProjects)
						// Once all tasks are loaded, update the stage list with the loaded tasks
						const updatedStageList = stages.map((stage) => {
							const projectsForStage = allProjects.filter((project) => project.stageId === stage.stageId)
							return { ...stage, items: projectsForStage, page: stage.page + 1, isLoading: false }
						})
						setStageList(updatedStageList)
					})
			})
	}, [pipelineId, showClosed])


	const sortIntoStages = (projects: project[]) => {
		const updatedStageList: dragStage<project>[] = stageList.map((stage) => {
			return {
				...stage,
				items: []
			}
		})

		projects.forEach((project, index) => {
			const stageIndex = updatedStageList.findIndex((stage) => stage.stageId === project.stageId)
	
			if(stageIndex > -1){
				updatedStageList[stageIndex].items.push(index === 0 ? {...project, first: true} : {...project})	
			}
	
		})

		return updatedStageList
	}

	useEffect(() => {
		const stageList = sortIntoStages(projects)
		console.log(projects)
		setStageList([...stageList])
	}, [projects])

	return (
		<>
			<div className="pipeline">
				<Dashboard
					stages={stageList}
					actions={pipelineAction}
					onMove={moveProject}
					onLoadMore={onLoadMore}
					onCardClick={(project) => {
						navigate(`/ri-business/projects/project/${project.id}`)
					}}
					onStageAdd={(stageName: string, stageId: number) => {
						dispatch(setCreateProjectStageId(stageId))
						dispatch(setCreateProjectModalIsOpen(true))
					}}
					createAccess={true}
				/>
			</div>
			<MoveProjectModal stagesList={stageList} setStagesList={setStageList} />
			<CloseProjectConfirmationModal stagesList={stageList} setStagesList={setStageList} />
			<DeleteProjectConfirmationModal stagesList={stageList} setStagesList={setStageList} projects={projects} setProjects={setProjects} />
			<CreateProjectModal stagesList={stageList} setStagesList={setStageList} projects={projects} setProjects={setProjects} />
		</>
	)
}

export default PipelineDashboard
