// styles
import "./headerAppFeature.scss";

// react
import React, { FC } from "react";
import { useNavigate } from "react-router";

// components
import ScrollAnimation from "../scrollAnimation/scrollAnimation";
import Button from "../../../../../components/general/button/button";
import Title from "../title/title";
import Text from "../text/text";

// icons
import { youtubeIcon, arrow } from "../../../assets/icons/icons";

type headerAppFeatureProps = {
  heading: string[];
  text?: string;
  page?: string;
  buttons: {
    text: string;
    link: string;
    variant: "solid" | "outlined";
  }[];
  imagesData: {
    png: string
    webp: string;
    icon?: {
      icon: React.ReactNode;
      iconPosition?: { left: string; top: string };
    } | null;
  }[];
  alternativeIcons?:
    | {
        icon: React.ReactNode;
        iconPosition?: { left: string; top: string };
      }[]
    | null;
};

const HeaderAppFeature: FC<headerAppFeatureProps> = ({
  page,
  buttons,
  imagesData,
  heading,
  text,
  alternativeIcons,
}) => {
  const navigate = useNavigate();

  return (
    <div className="header-app-feature-wrapper">
      {alternativeIcons?.map((icon, index) => {
        return (
          <div
            key={index}
            className="header-app-feature-images-icon"
            style={{
              left: icon.iconPosition?.left,
              top: icon.iconPosition?.top,
            }}
          >
            {icon.icon}
          </div>
        );
      })}
      <div className="header-app-feature-hero">
        <ScrollAnimation direction="to-bottom">
          <div className="header-app-feature-content">
            <Title>
              {heading.map((text, index) => (
                <span key={index} className={index === 1 ? "title-black" : ""}>
                  {text}
                  {index === 0 && <br />}
                </span>
              ))}
            </Title>
            {text && <Text>{text}</Text>}
            <div className="header-app-feature-buttons">
              {buttons.map((button, idx) => (
                <div
                  key={`header-app-feature-button-${idx}`}
                  className={`header-app-feature-button-${idx + 1}`}
                >
                  <Button
                    text={
                      idx === 0 ? (
                        <div>
                          {button.text}
                          {arrow}
                        </div>
                      ) : idx === 1 ? (
                        <>
                          <span>{button.text.split(" ")[0]}</span>{" "}
                          {button.text.slice(button.text.indexOf(" ") + 1)}
                        </>
                      ) : (
                        button.text
                      )
                    }
                    onClick={() => {
                      if (button.link.includes("http")) {
                        window.open(button.link, "_blank");
                      } else {
                        navigate(button.link);
                      }
                    }}
                    active={idx === 0 && true}
                    outlined={button.variant === "outlined"}
                    icon={idx === 1 && youtubeIcon}
                    arrow={idx === 0 && true}
                  />
                </div>
              ))}
            </div>
            <div
              className={`header-app-feature-images ${
                page === "sales" || page === "projects"
                  ? "second-image-transform"
                  : "first-image-transform"
              }`}
            >
              {imagesData.map((image, index) => {
                return (
                  <div
                    key={index}
                    className={`header-app-feature-image-${
                      index % 2 == 0 ? 0 : 1
                    }`}
                  >
                    {image.icon ? (
                      <div
                        className="header-app-feature-images-icon"
                        style={{
                          left: image.icon.iconPosition?.left,
                          top: image.icon.iconPosition?.top,
                        }}
                      >
                        {image.icon.icon}
                      </div>
                    ) : null}
                    <picture>
                      <source srcSet={image.webp} type="image/webp" />
                      <img
                        src={image.png}
                        alt={`Feature image ${index}`}
                        fetchPriority={index === 0 ? "high" : "auto"}
                        loading={index === 0 ? "eager" : "lazy"}
                      />
                    </picture>
                  </div>
                );
              })}
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default HeaderAppFeature;
