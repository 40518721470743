//styles
import './errorList.scss'
import { checkMark } from '../../../assets/general/generalIcons'

//react
import { FC, useState, useEffect } from 'react' 

//components
import ColumnLayout from '../columnLayout/columnLayout'
import CounterpartyCard from '../counterpartyCard/counterpartyCard'
import ResolveErrorModal from './modals/resolveErrorModal'
import GuideModal from '../modals/guideModal/guideModal'

//types
import {error, resolveType} from '../../../types/general/generalTypes'

//other 
import { getItemsPerColumn } from '../../../assets/general/generalFunctions'

//translation
import {useTranslation} from 'react-i18next'

type errorListProps = {
    errors: error[]
}

const ErrorList: FC<errorListProps> = ({ errors }) => {
	const { t } = useTranslation('',{keyPrefix:'general.infoPopup'})
	
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())

	const [ resolveModal, setResolveModal ] = useState<{
		isOpen: boolean,
		resolve: resolveType | undefined
	}>({
		isOpen: false,
		resolve: undefined
	})

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	const closeModal = ()=>{
		setResolveModal({
			...resolveModal,
			isOpen: false
		})
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<div className="error-list">
			<ColumnLayout amount={itemsPerColumn}>
				{
					errors.map((error) => {
						return (
							<CounterpartyCard
								name={t(error.title)}
								description={t(error.description)}
								link={error.errorRootLink}
								tags={error.labels}
								translateTags={error.translateKey}
								translate={error.translate}
								actions={error.resolve && [
									{
										id: 1,
										icon: checkMark,
										onClick: () => {
											setResolveModal({
												isOpen: true,
												resolve: error.resolve
											})
										}
									}
								]}
							/>
						)
					})
				}
			</ColumnLayout>
			<ResolveErrorModal
				resolve={resolveModal.resolve}
				isOpen={resolveModal.isOpen}
				title={t('wantToConfirmTitle')}
				closeModal={closeModal}
			/>
		</div>
	)
}

export default ErrorList