// styles and icons
import './companySettingsSection.scss'

// components
import PositionList from './positionList/positionList'
import CompanyDataSettings from './companyDataSettings/companyDataSettings'

const CompanySettingsSection = () => {	
	return (
		<div className='company-settings'>
			<CompanyDataSettings />
			<PositionList />
		</div>
	)
}

export default CompanySettingsSection