// react
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import InfoDetailsTable from '../../../general/infoDetailsTable/infoDetailsTable'
import Button from '../../../general/button/button'
import CreateWarehouseActionModal from './modals/createWarehouseActionModal'
import Loading from '../../../general/loading/loading'

//translation
import { useTranslation } from 'react-i18next'

//network
import { authorizedRequest } from '../../../../utils/queries'
import { singleTaskUrl } from '../../../../utils/urls/projects/task'
import { companyWarehouseActionLocationsUrl } from '../../../../utils/urls/warehouses/warehouseActions/warehouseActions'
import { singleWarehouseActionUrl } from '../../../../utils/urls/warehouses/warehouseActions/warehouseActions'

//types
import {
	infoDetailsTableDataItem,
	dropdownOption,
	inventoryItem,
	inventoryItemResponse,
	locationResponse,
	editableTableItem,
} from '../../../../types/general/generalTypes'

//redux
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'
import {
	setCreateWarehouseActionModalIsOpen,
	setCreateWarehouseActionTaskId
} from '../../../../redux/projects/task/modals'

//other
import { formatInventoryItemResponse } from '../../../../assets/general/generalFunctions'
import { companyWarehouseInventoryUrl } from '../../../../utils/urls/warehouses/warehouses/warehouses'

type details = {
	warehouseActionId: number
	origin: dropdownOption | undefined
	destination: dropdownOption | undefined
	editAccess: boolean
}

const WarehouseAction: FC = () => {
	const { t } = useTranslation('', { keyPrefix: 'projects.task.warehouseActionTab' })

	const [details, setDetails] = useState<details>({
		warehouseActionId: 0,
		origin: undefined,
		destination: undefined,
		editAccess: false,
	})

	const [editDetails, setEditDetails] = useState(false)

	const [items, setItems] = useState<inventoryItem[]>([])
	const [selectedItems, setSelectedItems] = useState<inventoryItem[]>([])

	const { userCompanyData } = useAppSelector((state) => state.general)
	const dispatch = useAppDispatch()

	const companyId: number = userCompanyData?.companyId || -1 // id of chosen company
	const taskId = parseInt(useParams().taskId || '-1')

	const [loading, setLoading] = useState<boolean>(false)

	const [destinationWarehouse, setDestinationWarehouse] = useState<dropdownOption | null>(null)
	const [destinationAddress, setDestinationAddress] = useState<dropdownOption | null>(null)
	const [originWarehouse, setOriginWarehouse] = useState<dropdownOption | null>(null)
	const [originAddress, setOriginAddress] = useState<dropdownOption | null>(null)

	const loadCompanyItems = async (searchQuery: string, page: number) => {
		const { result }: { result: inventoryItemResponse[] } = await authorizedRequest(companyWarehouseInventoryUrl(companyId) + `?needle=${searchQuery}&page=${page}`, 'GET')

		if (result && result.length > 0) {
			const itemsData: inventoryItem[] = result.map((item) => {
				return {
					...formatInventoryItemResponse(item),
					maxQuantity: item.quantity
				}
			})
			return itemsData
		} else {
			return []
		}
	}

	const loadLocations = async (query: string, page: number): Promise<dropdownOption[]> => {
		return await authorizedRequest(companyWarehouseActionLocationsUrl(companyId) + `?needle=${query}&page=${page}&per_page=10`, 'GET').then(({ result }) => {

			return result.map((item: locationResponse) => {
				return {
					key: `${item.id} ${item.type}`,
					title: item.address
				}
			})
		})
	}

	const updateSelectedItems = (selectedItems: editableTableItem[]) => {
		if(details?.warehouseActionId){
			authorizedRequest(singleWarehouseActionUrl(details?.warehouseActionId), 'PUT', 'accessToken', {
				inventory_items: selectedItems.map((item) => {
					item = item as inventoryItem
					return {
						id: item.id,
						name: item.name,
						cost: item.cost?.amount || 0,
						currency: item.cost?.currency,
						quantity: item.quantity
					}
				})
			})
		}
	}

	const infoDetailsTableData: infoDetailsTableDataItem[] = [
		{
			title: t('origin'),
			data: {
				dropdown: {
					defaultSelectedOption: details.origin,
					dropdownOptions: [],
					onSelect: (option) => {
						const optionArray = option.key.split(' ')
						setOriginWarehouse(null)
						setOriginAddress(null)
						if (optionArray[1] == 'warehouse') {
							setOriginWarehouse({ key: optionArray[0], title: option.title })
						} else {
							setOriginAddress({ key: optionArray[0], title: option.title })
						}
						setDetails({ ...details, origin: option })
						setEditDetails(true)
					},
					loadOptions: loadLocations,
					disabled: !details.editAccess,
					placeholder: t('origin'),
					selectedOption: details.origin
				}
			}
		},
		{
			title: t('destination'),
			data: {
				dropdown: {
					defaultSelectedOption: details.destination,
					dropdownOptions: [],
					onSelect: (option) => {
						const optionArray = option.key.split(' ')

						setDestinationWarehouse(null)
						setDestinationAddress(null)
						if (optionArray[1] == 'warehouse') {
							setDestinationWarehouse({ key: optionArray[0], title: option.title })
						} else {
							setDestinationAddress({ key: optionArray[0], title: option.title })
						}
						setDetails({ ...details, destination: option })
						setEditDetails(true)
					},
					loadOptions: loadLocations,
					disabled: !details.editAccess,
					placeholder: t('destination'),
					selectedOption: details.destination
				}
			}
		},
		{
			title: t('items'),
			fullWidth: true,
			data: {
				editableTable: {
					items: items,
					setItems: ((value) => {
						setItems(value as inventoryItem[])
					}),
					loadItems: ((request, page) => loadCompanyItems(request, page)),
					selected: {
						items: selectedItems,
						setItems: (value) => {
							updateSelectedItems(value)
							setSelectedItems(value as inventoryItem[])
						}
					},
					disabled: !details.editAccess,
					limitMaxQuantity: false
				}
			}
		}
	]

	const loadData = async () => {
		try {
			setLoading(true)
			const { result } = await authorizedRequest(singleTaskUrl(Number(taskId)), 'GET')

			const warehouseAction = result.warehouse_action

			if (warehouseAction) {
				setDetails({
					warehouseActionId: warehouseAction.id,
					origin: warehouseAction.origin ? {
						title: warehouseAction.origin.address,
						key: `${warehouseAction.origin.id}`
					} : undefined,
					destination: warehouseAction.destination ? {
						title: warehouseAction.destination.address,
						key: `${warehouseAction.destination.id}`
					} : undefined,
					editAccess: true
				})

				setSelectedItems(warehouseAction.inventory_items.map(formatInventoryItemResponse))
			}
		} finally {
			setLoading(false)
		}
	}

	const editData = async () => {

		if (editDetails && details) {
			const body = {
				origin_address_id: originAddress?.key,
				origin_warehouse_id: originWarehouse?.key,
				destination_address_id: destinationAddress?.key,
				destination_warehouse_id: destinationWarehouse?.key
			}

			await authorizedRequest(singleWarehouseActionUrl(details.warehouseActionId), 'PUT', 'accessToken', body)
			setEditDetails(false)
		}
	}

	useEffect(() => {
		loadData()
	}, [])

	useEffect(() => {
		let timeout: NodeJS.Timeout
		if (editDetails) {
			timeout = setTimeout(() => {
				editData()
			}, 700)
		}
		return () => clearTimeout(timeout)
	}, [editDetails])

	return (
		<div className="order-details">

			{
				details ?
					<InfoDetailsTable data={infoDetailsTableData} />
					:
					<div className='warehouse-action-button'>
						<Button text={t('addWarehouseAction')} active={true} onClick={() => {
							dispatch(setCreateWarehouseActionModalIsOpen(true))
							dispatch(setCreateWarehouseActionTaskId(taskId))
						}} />
					</div>
			}
			{
				loading && <Loading style={{ top: '40vh', left: '50vw' }} />
			}
			<CreateWarehouseActionModal loadData={loadData} guideText={[t('warehouseOrigin'), t('warehouseDestination'), t('warehouseChoosen'), t('warehouseQuantity'), t('warehouseCreate')]} />
		</div>
	)

}

export default WarehouseAction