//react
import  { FC, useEffect, useState } from 'react'

//type
import { offering } from '../../../../types/general/generalTypes'

//components
import GuideModal from '../../../general/modals/guideModal/guideModal'
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import OfferingCard from '../../../general/offeringCard/offeringCard'
import OfferingCardSkeleton from '../../../general/skeletons/offeringCard/offeringCardSkeleton'

//redux
import {
	setDeleteOfferingId,
	setDeleteOfferingModalIsOpen,
} from '../../../../redux/sales/offeringList/modals'
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'

//others
import { getItemsPerColumn } from '../../../../assets/general/generalFunctions'

//translations
import { useTranslation } from 'react-i18next'

type offeringCardListProps = {
	offerings: offering[]
	loading: boolean
}
const OfferingCardList: FC<offeringCardListProps> = ({
	offerings,
	loading,
}) => {
	const dispatch = useAppDispatch()
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())
	const handleDelete = (id: number) => {
		dispatch(setDeleteOfferingId(id))
		dispatch(setDeleteOfferingModalIsOpen(true))
	}

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}
	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<ColumnLayout amount={itemsPerColumn}>
			{offerings.map((offeringItem: offering, index) => {
				return (
					<OfferingCard
						{...offeringItem}
						link={`/ri-business/sales/offering/${offeringItem.orderItemId}`}
						handleDelete={
							offeringItem.deleteAccess
								? () => handleDelete(offeringItem.id)
								: undefined
						}
						key={`products-${index}`}
					/>
				)
			})}
			{loading ? <OfferingCardSkeleton /> : null}
		</ColumnLayout>
	)
}

export default OfferingCardList
