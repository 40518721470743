// styles
import './landingSlider.scss'

//react
import { FC, useEffect, useState } from 'react'

// components
import ScrollAnimation from '../scrollAnimation/scrollAnimation'
import Title from '../title/title'
import Text from '../text/text'

//translations
import { useTranslation } from 'react-i18next'

//icon
import { flag } from '../../../../../assets/general/generalIcons'
import Button from '../../../../../components/general/button/button'

type landingSliderProps = {
    translationWay: string
    stepsNumber: number
    images: string[]
    webpImages: string[]
    title: string
    text: string
    smallText?: string
    icon?: JSX.Element[]
    headerTitle?: string
    headerText?: string
}

const LandingSlider: FC<landingSliderProps> = ({translationWay, stepsNumber, images, webpImages, title, text, smallText, icon, headerText, headerTitle}) => {
	const { t } = useTranslation('', { keyPrefix: `${translationWay}` })
    const [stepIndex, setStepIndex] = useState(1)
    const [ mobile, setMobile ] = useState(screen.width <= 425)


    const handleResize = () => {
		setMobile(screen.width <= 425)
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
    <div
      className="landing-slider-steps-wrapper"
      id="landing-slider-steps-wrapper"
    >
      {headerTitle ? (
        <Title>
          <span className="title-black">{t(`${headerTitle}`)}</span>
        </Title>
      ) : null}
      <div className="landing-slider-hero">
        <ScrollAnimation direction="to-right">
          <div className={`landing-slider-content`}>
            <div className={`landing-slider-img-container`}>
              <div
                className="landing-slider-img-container-list"
                style={{
                  transform: `translate(${
                    mobile
                      ? -(images.length - 1) * 120 + (stepIndex - 1) * 120
                      : -(images.length - 1) * 37 + (stepIndex - 1) * 37
                  }vw, 0)`,
                }}
              >
                {Array.from({ length: stepsNumber }).map((_, index) => (
                  <picture key={index}>
                    {webpImages && (
                      <source srcSet={webpImages[index]} type="image/webp" />
                    )}
                    <img
                      src={images[index]}
                      alt={`image ${index}`}
                      fetchPriority={index === 0 ? "high" : "auto"}
                      loading={index === 0 ? "eager" : "lazy"}
                    />
                  </picture>
                ))}
              </div>
            </div>
            <div className="landing-slider-info-container">
              <div className="landing-slider-info-list">
                {Array.from({ length: stepsNumber }).map((_, index) =>
                  index + 1 === stepIndex ? (
                    <div
                      className="step-line selected"
                      key={`step-line-${index}`}
                    >
                      <div className="step-line-icon-container">
                        {flag}
                        <p>
                          {stepIndex}/{stepsNumber}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="step-line" key={`step-line-${index}`} />
                  )
                )}
              </div>
              <div
                className="landing-slider-info-text-list"
                style={{
                  transform: `translate(${
                    mobile
                      ? -(images.length - 1) * 120 + (stepIndex - 1) * 120
                      : -(images.length - 1) * 91 + (stepIndex - 1) * 91
                  }vw, 0)`,
                }}
              >
                {Array.from({ length: stepsNumber }).map((_, index) => (
                  <div key={index} className="landing-slider-info-text">
                    {smallText && icon ? (
                      <div className="landing-slider-info-small-text">
                        {icon[index]}
                        <p>{t(`${smallText}${index + 1}`)}</p>
                      </div>
                    ) : null}
                    <Title>
                      <span className="title-black">
                        {t(`${title}${index + 1}`)}
                      </span>
                    </Title>
                    <Text>{t(`${text}${index + 1}`)}</Text>
                  </div>
                ))}
              </div>
              <div className="landing-slider-info-buttons">
                <Button
                  active={true}
                  outlined={stepIndex > 1 ? false : true}
                  text={t("back")}
                  onClick={() =>
                    stepIndex > 1 ? setStepIndex(stepIndex - 1) : null
                  }
                />
                <Button
                  active={true}
                  outlined={stepIndex < stepsNumber ? false : true}
                  text={t("next")}
                  onClick={() =>
                    stepIndex < stepsNumber ? setStepIndex(stepIndex + 1) : null
                  }
                />
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
      {headerText ? <Text>{t(`${headerText}`)}</Text> : null}
    </div>
  );
}

export default LandingSlider