// styles and icons
import './searchEmployees.scss'
import {
	chat,
	deleteIcon,
	refresh
} from '../../../assets/general/generalIcons'
import { moveEmployeeButton } from '../../../assets/employees/general/generalIcons'

//react
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//components
import GuideModal from '../../../components/general/modals/guideModal/guideModal'
import Layout from '../../../components/general/layout/layout'
import HireEmployee from '../../../components/employees/general/modals/hireEmployee/hireEmployee'
import FireEmployee from '../../../components/employees/general/modals/fireEmployee/fireEmployee'
import MoveEmployee from '../../../components/employees/general/modals/moveEmployee/moveEmployee'
import MoveEmployeeConfirmation from '../../../components/employees/general/modals/moveEmployee/moveEmployeeConfirmation/moveEmployeeConfirmation'
import CounterpartyCard from '../../../components/general/counterpartyCard/counterpartyCard'
import Pagination from '../../../components/general/pagination/pagination'
import ColumnLayout from '../../../components/general/columnLayout/columnLayout'
import CounterpartySkeleton from '../../../components/general/skeletons/counterParty/counterPartySkeleton'

//types
import {
	employeeResponse,
	filterBar,
	filters,
	infoPopupTypes,
	switchButton,
} from '../../../types/general/generalTypes'
import { employee } from '../../../types/employees/hierarchyTypes'

//network
import { authorizedRequest } from '../../../utils/queries'
import {
	companySearchEmployeeFiltersUrl,
	companySearchEmployeesUrl,
} from '../../../utils/urls/employees/search'

//redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import {
	setFireEmployee,
	setFireEmployeeModalIsOpen,
	setHireEmployeeEmployeeId,
	setHireEmployeeLeaderId,
	setHireEmployeeModalIsOpen,
	setMoveEmployeeId,
	setMoveEmployeeModalIsOpen,
	setMoveEmployeeNewLeaderId,
	setMoveEmployeeTeam,
} from '../../../redux/employees/general/modals'
import { setEmployeesGuidanceModalsClosed, setEmployeesGuidanceSearchEmployeesModals } from '../../../redux/general/modals'

//other
import {
	createFilteringRequest,
	formatEmployeeResponse,
	formatFilters,
	getItemsPerColumn,
} from '../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

const SearchEmployees = () => {
	const { t } = useTranslation('', { keyPrefix: 'employees.searchEmployees' })
	const { t: tCurrency } = useTranslation('', { keyPrefix: 'general.currency' })
	const { userCompanyData } = useAppSelector((state) => state.general)
	const { employeesGuidanceModals } = useAppSelector((state) => state.generalModals.guidanceModals)

	const navigate = useNavigate()

	const companyId: number = userCompanyData?.companyId || -1

	const userId: number = parseInt(localStorage.getItem('userId') || '-1')

	const [employeeList, setEmployeeList] = useState<employee[]>([])
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())
	const [filterBar, setFilterBar] = useState<filterBar>()
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [searchRequest, setSearchRequest] = useState('')
	const [page, setPage] = useState(1)
	const [loading, setLoading] = useState(false)
	const [isFilterBarLoading, setFilterBarLoading] = useState<boolean>(false)
	const [lastPage, setLastPage] = useState(false)
	const [employeesLoading, setEmployeesLoading] = useState<boolean>(false)

	const titleUrls = [
		{
			url: '/ri-business/employees/search-employee',
			title: t('title'),
		},
	]

	const infoPopup = {
		content: [
			{
				title: 'search_employees_info_title',
				description: 'search_employees_info_description',
				link: '/ri-manual/employees/search-employee',
			},
		],
		type: infoPopupTypes.INFO,
	}

	const dispatch = useAppDispatch()

	const loadFilterBar = async () => {
		try {
			setFilterBarLoading(true)
			const { result } = await authorizedRequest(
				companySearchEmployeeFiltersUrl(companyId),
				'GET'
			)

			const filterResult = result.filters

			const formatedFilters: filters[] = formatFilters(filterResult)

			setFilterBar({
				filters: formatedFilters,
				onSearch: onSearch,
			})
		} finally {
			setFilterBarLoading(false)
		}
	}

	const loadData = async (
		page: number,
		request: string,
		filters: filters[]
	): Promise<any> => {
		try {
			setEmployeesLoading(true)
			const filteringRequest = createFilteringRequest(filters)

			const { result } = await authorizedRequest(
				companySearchEmployeesUrl(companyId) +
					`?page=${page}&per_page=${24}&needle=${request}&type=search` +
					filteringRequest,
				'GET'
			)

			if (result.length > 0) {
				const formatedEmployee = result.map((employee: employeeResponse) => {
					return formatEmployeeResponse(employee)
				})

				return formatedEmployee
			}

			return []
		} finally {
			setEmployeesLoading(false)
		}
	}

	const replaceEmployee = (employee: employee) => {
		dispatch(setHireEmployeeModalIsOpen(true))
		dispatch(setHireEmployeeLeaderId(undefined))
		dispatch(setHireEmployeeEmployeeId(employee.id))
	}

	const moveEmployee = (employeeId: number, isTeamLeader: boolean) => {
		dispatch(setMoveEmployeeModalIsOpen(true))
		dispatch(setMoveEmployeeId(employeeId))
		dispatch(setMoveEmployeeNewLeaderId(undefined))
		dispatch(setMoveEmployeeTeam(isTeamLeader))
	}

	const fireEmployee = (employee: employee) => {
		dispatch(setFireEmployeeModalIsOpen(true))
		dispatch(setFireEmployee(employee))
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadData(1, searchValue, filters)
			.then((employeeList) => {
				setEmployeeList(employeeList)
				if (employeeList.length > 0) {
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)
	}, [])

	const getEmployeeActions: (employee: employee) => switchButton[] = (
		employee
	) => {
		const buttons = [
			{
				id: 0,
				icon: chat,
				onClick: (event: React.MouseEvent) => {
					event.stopPropagation()
					navigate(`/ri-business/chats/${employee.chatId}`)
				},
			},
			{
				id: 1,
				icon: deleteIcon,
				onClick: (event: React.MouseEvent) => {
					event.stopPropagation()
					fireEmployee(employee)
				},
			},
			{
				id: 2,
				icon: moveEmployeeButton,
				onClick: (event: React.MouseEvent) => {
					event.stopPropagation()
					moveEmployee(employee.id, employee.isTeamLeader)
				},
			},
			{
				id: 3,
				icon: refresh,
				onClick: (event: React.MouseEvent) => {
					event.stopPropagation()
					replaceEmployee(employee)
				},
			},
		]

		const result: switchButton[] = []
		const fired = employee.userId === null

		if (employee.userId !== userId && !fired) {
			result.push(buttons[0])
		}

		if (employee.fireAccess && employee.userId !== userId) {
			result.push(buttons[1])
		}

		if (employee.moveAccess && employee.userId !== userId) {
			result.push(buttons[2])
		}

		if (employee.replaceAccess && employee.userId === null) {
			result.push(buttons[3])
		}

		return result
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			// console.log('Страница', page)
			loadData(page, searchRequest, activeFilters)
				.then((newEmployeeList) => {
					setEmployeeList([...employeeList, ...newEmployeeList])
					if (newEmployeeList.length > 0) {
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}
	useEffect(() => {
		loadFilterBar()
	}, [])

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const regex = /(UAH|EU|USD)/

	return (
		<Layout
			header={{
				avatar: true,
			}}
			filterBar={filterBar}
			titleUrls={titleUrls}
			titlePopup={infoPopup}
			isFilterBarLoading={isFilterBarLoading}
		>
			<div className="search-employee-container">
				<Pagination
					onLoadMore={handleLoadMore}
					onlyLoadOn="bottom"
					loading={loading && employeeList.length > 0}
					showLoader={false}
				>
					<ColumnLayout amount={itemsPerColumn}>
						{employeeList?.map((employee, index) => {
							return (
								<div className="search-employee-card-wrapper">

									<CounterpartyCard
										key={'employee-card-' + employee.userId}
										avatar={employee.avatar}
										description={employee.position}
										name={employee.name}
										link={`/ri-business/employee/${employee.id}`}
										tags={employee.tags?.map((e) => ({
											...e,
											value: regex.test(e.value)
												? Number(e.value.split(' ')[0]).toFixed(2) +
												' ' +
												tCurrency(e.value.split(' ')[1])
												: e.value,
										}))}
										actions={getEmployeeActions(employee)}
										onClick={() => {
											dispatch(setEmployeesGuidanceSearchEmployeesModals([false, false, false, true, false, false, false, false]))
										}}
									/>
									{
										index === 0 &&
											<GuideModal
												closeModal={() => {
													dispatch(setEmployeesGuidanceModalsClosed())
												}}
												open={employeesGuidanceModals.searchEmployees[2]}

											>
												<p>Search employee</p>
											</GuideModal>
									}
								</div>
							)
						})}
						{employeesLoading ? <CounterpartySkeleton /> : null}
					</ColumnLayout>
				</Pagination>
			</div>

			<HireEmployee getEmployeeActions={getEmployeeActions} />
			<FireEmployee getEmployeeActions={getEmployeeActions} />
			<MoveEmployee />
			<MoveEmployeeConfirmation getEmployeeActions={getEmployeeActions} />
		</Layout>
	)
}

export default SearchEmployees