// react
import { FC, useState, useEffect } from 'react'

// components
import Layout from '../../../components/general/layout/layout'
import CompanySettingsSection from '../../../components/general/settings/companySettingsSection/companySettingsSection'

// types
import { button } from '../../../types/general/generalTypes'
import { company, companyResponse } from '../../../types/general/settings/companySectionTypes'

//redux
import { useAppDispatch, useAppSelector } from '../../../customHooks/redux'
import { setSettingsActiveCompanyId } from '../../../redux/general/settings/general'
import { setCompanies } from '../../../redux/general/general'
import { setEmployeesGuidanceHierarchyModals, setEmployeesGuidanceModalsClosed } from '../../../redux/general/modals'

// network
import { authorizedRequest } from '../../../utils/queries'
import { whoAmIUrl } from '../../../utils/old_urls/general/generalUrls'

//translation
import { t } from 'i18next'

const Settings: FC = () => {
	const { settingsActiveCompanyId } = useAppSelector(state => state.settingsGeneral)
	const { companies } = useAppSelector(state => state.general)
	const { employeesGuidanceModals } = useAppSelector((state) => state.generalModals.guidanceModals)

	const dispatch = useAppDispatch()

	// const getTabGuidanceOpen = (): guidanceModalData | undefined => {
	// 	if(employeesGuidanceModals.hierarchy[1]){
	// 		return {
	// 			open: employeesGuidanceModals.hierarchy[1],
	// 			next: () => { 
	// 				dispatch(setEmployeesGuidanceHierarchyModals([false, false, true, false, false, false, false, false, false, false]))
	// 			},
	// 			close: () => { dispatch(setEmployeesGuidanceModalsClosed()) },
	// 			text: 'employee2'
	// 		}
	// 	}

	// 	return undefined
		
	// }

	// const [tabGuidanceOpen, setTabGuidanceOpen] = useState<guidanceModalData | undefined>(getTabGuidanceOpen())
	
	// useEffect(() => {
	// 	setTabGuidanceOpen(getTabGuidanceOpen())
	// }, [employeesGuidanceModals.hierarchy])


	useEffect(() => {
		authorizedRequest(whoAmIUrl, 'GET').then((response) => {
			const companiesDataList: company[] = []
			const { result: { companies } } = response

			companies.forEach((company: companyResponse) => {
				companiesDataList.push({
					avatar: company.avatar,
					companyId: company.company_id,
					employeeId: company.employee_id,
					name: company.name,
					ownerId: company.owner_id,
					position: company.position
				})
			})

			const parsCompanies = companies.map((company: companyResponse) => {
				return {
					avatar: company.avatar,
					companyId: company.company_id,
					employeeId: company.employee_id,
					name: company.name,
					ownerId: company.owner_id,
					position: company.position
				}
			})

			dispatch(setCompanies(parsCompanies))

			dispatch(setSettingsActiveCompanyId(companies[0].company_id))
		})
	}, [])


	const createTabButton = (text: string, companyId: number | null) => {
		return {
			active: companyId === settingsActiveCompanyId,
			text: text,
			onClick: () => {
				dispatch(setSettingsActiveCompanyId(companyId))
			}
		}
	}

	const settingsTabButton: button[] = companies.map(company => createTabButton(company.name, company.companyId))

	return (
		<Layout
			header={{
				avatar: true,
				title: 'Settings'
			}}
			tabButtons={settingsTabButton}
			tabButtonsGuidance={{
				open: employeesGuidanceModals.hierarchy[1],
				next: () => { 
					dispatch(setEmployeesGuidanceHierarchyModals([false, false, true, false, false, false, false]))
				},
				close: () => { dispatch(setEmployeesGuidanceModalsClosed()) },
				text: 'employee2'
			}}
		>
			{settingsActiveCompanyId ? <CompanySettingsSection /> : null}
		</Layout>
	)
}

export default Settings