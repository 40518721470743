// react
import { FC, FormEvent, useRef, useState } from 'react'

// components
import Modal from '../../modal/modal'
import InputField from '../../../inputField/inputField'
import TextArea from '../../../textArea/textArea'
import Dropdown from '../../../dropdown/dropdown'
import GuideModal from '../../guideModal/guideModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setCreateAccountModalIsOpen, setFinanceGuidanceModalsClosed } from '../../../../../redux/general/modals'
import { setFinanceGuidanceModals } from '../../../../../redux/general/modals'

// types 
import { account } from '../../../../../types/finance/accountTypes'
import { infoPopupTypes, infoType } from '../../../../../types/general/generalTypes'

// network
import { authorizedRequest } from '../../../../../utils/queries'

// other 
import { formatAccountResponse } from '../../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type formErrors = {
	accountTitle?: string
	accountNumber?: string
	bankName?: string
}

type createAccountModalProps = {
	setAccounts: (value: account[]) => void
	accounts: account[]
	createAccountUrl: string
}

const CreateAccountModal: FC<createAccountModalProps> = ({ accounts, setAccounts, createAccountUrl }) => {
	const { t } = useTranslation('', { keyPrefix: 'finance.accountList.modals.createAccountModal' })

	const [accountName, setAccountName] = useState<string>('')
	const [accountNumber, setAccountNumber] = useState<string>('')
	const [bankName, setBankName] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const [errors, setErrors] = useState<formErrors>({})

	const dispatch = useAppDispatch()
	const { modalIsOpen } = useAppSelector((state) => state.accontListModal.createAccountModal)

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const formRef = useRef<HTMLFormElement | null>(null)

	const [guideStep, setGuideStep] = useState<number>(1)

	const { financeGuidanceModals } = useAppSelector((state) => state.generalModals.guidanceModals)

	const guideText = [t('financeTitle'), t('financeNumber'), t('financeBank'), t('financeDescription'), t('financeCreate')]

	const handleGuideStep = () => {
		return guideText && guideText[guideStep - 1] || null
	}

	const nextGuideStep = () => {
		if (guideStep === 5) {
			dispatch(setFinanceGuidanceModalsClosed())
		} else {
			setGuideStep((prevStep) => prevStep + 1)
		}
	}

	const infoPopup = {
		content: [{
			title: 'create_account_title',
			description: 'create_account_description',
		}],
		type: infoPopupTypes.INFO
	}

	const createAccount = async () => {
		const { result } = await authorizedRequest(createAccountUrl, 'POST', 'accessToken', {
			data: [{
				name: accountName,
				bank: bankName,
				account_number: accountNumber,
				description: description
			}]
		})
		console.warn(result)

		if (result.length > 0) {
			const formatedAccounts: account[] = result.map(formatAccountResponse)
			setAccounts([...formatedAccounts, ...accounts])
		}
	}

	const closeModal = () => {
		dispatch(setCreateAccountModalIsOpen(false))
		setErrors({})
		setAccountName('')
		setAccountNumber('')
		setBankName('')
		setDescription('')
	}

	const checkErrors = () => {
		let result = true
		if (!accountName) {
			setErrors(prevErrors => {
				return { ...prevErrors, accountTitle: t('accountTitleIsNeeded') }
			})
			result = false
		}
		if (!accountNumber) {
			setErrors(prevErrors => {
				return { ...prevErrors, accountNumber: t('accountNumberIsNeeded') }
			})
			result = false
		}
		if (!bankName) {
			setErrors(prevErrors => {
				return { ...prevErrors, bankName: t('bankNameIsNeeded') }
			})
			result = false
		}
		return result
	}


	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		try {
			setIsLoading(true)
			if (checkErrors()) {
				createAccount().then(() => {
					setIsLoading(false)
					closeModal()
				})
			}
		} finally {
			setIsLoading(false)
		}

	}

	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			titlePopup={infoPopup}
			title={t('addNewAccount')}
			submitButton={{
				text: t('create'),
				onClick: () => {
					if (formRef.current) formRef.current.requestSubmit()
				}
			}}
			isLoading={isLoading}
		>

			<form
				className='create-transaction-container'
				ref={formRef}
				onSubmit={handleSubmit}
			>
				<div className='form-elements-wrapper'>
					<InputField
						type='text'
						label={t('accountTitle')}
						value={accountName}
						info={errors.accountTitle ? {
							type: infoType.error,
							text: errors.accountTitle
						} : undefined}
						onChange={(e) => {
							setAccountName(e.target.value)
							if (guideStep === 1) {
								nextGuideStep()
								dispatch(setFinanceGuidanceModals([false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
							}
						}}
					/>
					<InputField
						type='text'
						label={t('accountNumber')}
						value={accountNumber}
						info={errors.accountNumber ? {
							type: infoType.error,
							text: errors.accountNumber
						} : undefined}
						onChange={(e) => {
							setAccountNumber(e.target.value)
							if (guideStep === 2) {
								nextGuideStep()
								dispatch(setFinanceGuidanceModals([false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
							}
						}}
					/>
					<InputField
						type='text'
						label={t('bankName')}
						value={bankName}
						info={errors.bankName ? {
							type: infoType.error,
							text: errors.bankName
						} : undefined}
						onChange={(e) => {
							setBankName(e.target.value)
							if (guideStep === 3) {
								nextGuideStep()
								dispatch(setFinanceGuidanceModals([false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
							}
						}}
					/>
					<TextArea
						label={t('description')}
						placeholder={t('description')}
						value={description}
						setValue={value => {
							setDescription(value)
							if (guideStep === 4) {
								nextGuideStep()
								dispatch(setFinanceGuidanceModals([false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]))
							}
						}}
					/>
				</div>
			</form>
		</Modal>
	)
}

export default CreateAccountModal
