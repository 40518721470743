// react
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

//components
import InfoDetailsTable from '../../../general/infoDetailsTable/infoDetailsTable'
import Loading from '../../../general/loading/loading'
import ExtraColumnDetails from '../../../general/extraColumnDetails/extraColumnDetails'

// types
import {
	infoDetailsTableDataItem,
	infoType,
	tableTypes,
} from '../../../../types/general/generalTypes'
import { providerResponse } from '../../../../types/purchase/providerTypes'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { singleProviderUrl } from '../../../../utils/urls/purchases/provider'

//translation
import { useTranslation } from 'react-i18next'

type providerDetailsProps = {
	setProviderName: (value: string) => void
}

type details = {
	name: string
	email: string
	phoneNumber: string
	description: string
	editAccess: boolean
}

type errors = {
	name?: string
	email?: string
	phone?: string
}

const ProviderDetails: FC<providerDetailsProps> = ({ setProviderName }) => {
	const { t } = useTranslation('', {
		keyPrefix: 'purchase.provider.providerDetails',
	})

	const tErrors = useTranslation('', {
		keyPrefix: 'purchase.providerList.modals.createProviderModal',
	}).t
	
	const navigate = useNavigate()
	const { relationshipId } = useParams()
	const [details, setDetails] = useState<details>({
		name: '',
		email: '',
		phoneNumber: '',
		description: '',
		editAccess: false,
	})
	const [editDetails, setEditDetails] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [errors, setErrors] = useState<errors>({})

	const checkErrors = () => {
		let hasErrors = true
		setErrors({})
		if (details.name.trim() === '') {
			setErrors((prevErrors) => ({
				...prevErrors,
				name: tErrors('pleaseEnterAName'),
			}))
			hasErrors = false
		}
		if (details.email.trim() === '') {
			setErrors((prevErrors) => ({
				...prevErrors,
				email: tErrors('pleaseEnterAnEmail'),
			}))
			hasErrors = false
		}
		if (details.phoneNumber.trim() === '') {
			setErrors((prevErrors) => ({
				...prevErrors,
				phone: tErrors('pleaseEnterAPhoneNumber'),
			}))
			hasErrors = false
		}
		return hasErrors
	}

	const infoDetailsTableData: infoDetailsTableDataItem[] = [
		{
			title: t('name'),
			data: {
				inputField: {
					type: 'text',
					value: details.name,
					onChange: (e) => {
						setDetails({ ...details, name: e.target.value })
						setProviderName(e.target.value)
						setEditDetails(true)
						setErrors((prevErrors) => ({ ...prevErrors, name: undefined }))
					},
					disabled: !details.editAccess,
					info: errors.name ? {
						type: infoType.error,
						text:  errors.name
					} : undefined
				},
			},
		},
		{
			title: t('email'),
			data: {
				inputField: {
					type: 'text',
					value: details.email,
					onChange: (e) => {
						setDetails({ ...details, email: e.target.value })
						setEditDetails(true)
						setErrors((prevErrors) => ({ ...prevErrors, email: undefined }))
					},
					disabled: !details.editAccess,
					info: errors.email ? {
						type: infoType.error,
						text: errors.email
					} : undefined
				},
			},
		},
		{
			title: t('phoneNumber'),
			data: {
				inputField: {
					type: 'text',
					value: details.phoneNumber,
					onChange: (e) => {
						setDetails({ ...details, phoneNumber: e.target.value })
						setErrors((prevErrors) => ({ ...prevErrors, phone: undefined }))
						setEditDetails(true)
					},
					disabled: !details.editAccess,
					info: errors.phone ? {
						type: infoType.error,
						text: errors.phone
					} : undefined
				},
			},
		},
		{
			title: t('description'),
			data: {
				textArea: {
					value: details.description,
					setValue: (value) => {
						setDetails({ ...details, description: value })
						setEditDetails(true)
					},
					disabled: !details.editAccess,
				},
			},
		},
	]

	const loadData = async () => {
		if (isNaN(Number(relationshipId))) {
			navigate(-1)
		}
		try {
			setLoading(true)
			const { result }: { result: providerResponse } = await authorizedRequest(
				singleProviderUrl(Number(relationshipId)),
				'GET'
			)

			if (result === undefined) {
				navigate(-1)
			}

			setProviderName(result.name)
			setDetails({
				name: result.name,
				phoneNumber: result.phone,
				email: result.email,
				description: result.description,
				editAccess: result.edit_access,
			})
		} finally {
			setLoading(false)
		}
	}

	const editData = async () => {
		if (Number(relationshipId) && checkErrors()) {
			await authorizedRequest(
				singleProviderUrl(Number(relationshipId)),
				'PUT',
				'accessToken',
				{
					name: details.name,
					email: details.email,
					phone: details.phoneNumber,
					description: details.description,
				}
			)
		}
	}

	useEffect(() => {
		if (editDetails) {
			editData()
			setTimeout(() => {
				setEditDetails(false)
			}, 500)
		}
	}, [details])

	useEffect(() => {
		loadData()
	}, [])

	return (
		<>
			<InfoDetailsTable data={infoDetailsTableData} />
			{loading && <Loading style={{ top: '40vh', left: '50vw' }} />}
			<ExtraColumnDetails
				type={tableTypes.provider}
				objectId={Number(relationshipId)}
				setLoading={setLoading}
			/>
		</>
	)
}

export default ProviderDetails
