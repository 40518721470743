// react 
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'

// components
import CreateOrderModal from '../../../../general/modals/createOrderModal/createOrderModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../customHooks/redux'
import { setCreatePurchaseOrderModalIsOpen } from '../../../../../redux/purchase/purchaseOrderList/modals'
import { setPurchasesGuidanceModalsClosed, setPurchasesGuidanceOrderModals } from '../../../../../redux/general/modals'

// network
import { authorizedRequest } from '../../../../../utils/queries'
import { createPurchaseOrderUrl } from '../../../../../utils/old_urls/purchase/generalUrls'
import {
	companyProviderOfferingsUrl, companyProvidersUrl,
	singleProviderOfferingsUrl
} from '../../../../../utils/urls/purchases/provider'
import { pipelinesManagePipelineResourceUrl } from '../../../../../utils/urls/projects/pipelines'
import { companyWarehousesUrl } from '../../../../../utils/urls/warehouses/warehouses/warehouses'

// types
import {
	offering,
	dropdownOption,
	order,
	warehouseResponse,
	createOrderErrors, infoPopupTypes,
} from '../../../../../types/general/generalTypes'
import { providerResponse } from '../../../../../types/purchase/providerTypes'
import { pipelineResponse } from '../../../../../types/projects/pipelineTypes'

// other
import { formatOfferingResponse, formatOrderResponse } from '../../../../../assets/general/generalFunctions'
import { useTranslation } from 'react-i18next'

type createPurchaseOrderModalProps = {
	orders: order[]
	setOrders: Dispatch<SetStateAction<order[]>>
}

const CreatePurchaseOrderModal: FC<createPurchaseOrderModalProps> = ({ orders, setOrders }) => {
	const dispatch = useAppDispatch()
	const { modalIsOpen } = useAppSelector((state) => state.purchaseOrderListModal.createPurchaseOrderModal)
	const { userCompanyData, language } = useAppSelector((state) => state.general)
	const { purchasesGuidanceModals } = useAppSelector(state => state.generalModals.guidanceModals)

	const { t } = useTranslation('', { keyPrefix: 'purchase.createPurchaseOrderModal' })

	const companyId: number = userCompanyData?.companyId || -1

	const activeGuidanceRef = useRef(false)

	const [selectedCounterpartyOption, setSelectedCounterpartyOption] = useState<dropdownOption | null>(null)
	const [selectedPipelineOption, setSelectedPipelineOption] = useState<dropdownOption | null>(null)
	const [selectedDestinationOption, setSelectedDestinationOption] = useState<dropdownOption | null>(null)

	const [reload, setReload] = useState(false)
	const [errors, setErrors] = useState<createOrderErrors>({})

	const closeModal = () => {
		dispatch(setCreatePurchaseOrderModalIsOpen(false))
		setSelectedCounterpartyOption(null)
		setSelectedDestinationOption(null)
		setSelectedPipelineOption(null)
		setErrors({})
		setReload(true)

		if(activeGuidanceRef.current){
			dispatch(setPurchasesGuidanceOrderModals([false, false, false, true, false, false, false, false, false, false]))
		}
	}

	const counterpartyPopup = {
		content: [
			{
				title: 'create_counterparty_info_title',
				description: 'create_counterparty_info_description',
				link: '/ri-business/purchase/provider-list',
			}
		],
		type: infoPopupTypes.INFO,
	}

	const destinationPopup = {
		content: [
			{
				title: 'create_destination_info_title',
				description: 'create_destination_info_description',
				link: '/ri-business/warehouse/warehouse-list',
			}
		],
		type: infoPopupTypes.INFO,
	}

	const pipelinePopup = {
		content: [
			{
				title: 'create_pipeline_info_title',
				description: 'create_pipeline_info_description',
				link: '/ri-business/projects/pipelines',
			}
		],
		type: infoPopupTypes.INFO,
	}

	const loadProducts = async (searchQuery: string, page: number) => {
		let response
		if (selectedCounterpartyOption) response = await authorizedRequest(singleProviderOfferingsUrl(Number(selectedCounterpartyOption.key)) + `?needle=${searchQuery}&page=${page}`, 'GET')

		else response = await authorizedRequest(companyProviderOfferingsUrl(companyId) + `?needle=${searchQuery}&page=${page}`, 'GET')

		const result = response.result
		if (result && result.length > 0) {
			const offeringsData: offering[] = result.map(formatOfferingResponse)
			return offeringsData
		}

		return []
	}

	const loadCounterpartyOptions = async (search: string, page: number) => {
		const { result } = await authorizedRequest(companyProvidersUrl(companyId) + `?needle=${search}&page=${page}&per_page=10`, 'GET')

		return result.map((provider: providerResponse) => {
			return {
				title: provider.name,
				avatar: provider.avatar,
				key: `${provider.relationship_id}`
			}
		})
	}

	const loadDestinationOptions = async (search: string, page: number) => {
		const { result } = await authorizedRequest(companyWarehousesUrl(companyId) + `?needle=${search}&page=${page}&per_page=10`, 'GET')
		
		return result.map((warehouse: warehouseResponse) => {
			return {
				title: warehouse.name,
				key: `${warehouse.id}`
			}
		})
	}

	const loadPipelinesOptions = async (search: string, page: number) => {
		const { result } = await authorizedRequest(pipelinesManagePipelineResourceUrl(companyId) + `?page=${page}&per_page=${10}&needle=${search}`, 'GET')

		return result.map((pipeline: pipelineResponse) => {
			return {
				title: pipeline.name,
				key: `${pipeline.id}`
			}
		})
	}

	const checkErrors = () => {
		let hasErrors = false
		if (!selectedCounterpartyOption) {
			hasErrors = true
			setErrors({ ...errors, counterparty: 'pleaseSelectCounterparty' })
		}

		if (!selectedDestinationOption) {
			hasErrors = true
			setErrors({ ...errors, destination: 'pleaseSelectDestination' })
		}

		if (!selectedPipelineOption) {
			hasErrors = true
			setErrors({ ...errors, pipeline: 'pleaseSelectPipeline' })
		}

		return hasErrors
	}

	const addOrder = async (item: offering[]) => {
		const hasErrors = checkErrors()
		if (hasErrors) return

		if (selectedCounterpartyOption && selectedPipelineOption && selectedDestinationOption) {

			const body = {
				language: language,
				pipeline_id: Number(selectedPipelineOption?.key),
				offerings: [...item.map((offering) => {
					return {
						offering_id: offering.id,
						offering_quantity: offering.quantity
					}
				})],
				address_id: null,
				warehouse_id: Number(selectedDestinationOption?.key)
			}

			const { result } = await authorizedRequest(createPurchaseOrderUrl(Number(selectedCounterpartyOption.key)), 'POST', 'accessToken', body)
			if (result) {
				const formatedOrder: order = formatOrderResponse(result)
				setOrders([formatedOrder, ...orders])
			}
			closeModal()
		}
	}

	useEffect(() => {
		if(purchasesGuidanceModals.orders[2] && modalIsOpen){
			activeGuidanceRef.current = true
			dispatch(setPurchasesGuidanceModalsClosed())
		}
	}, [modalIsOpen, purchasesGuidanceModals.orders])

	useEffect(() => {
		setReload(true)
	}, [selectedCounterpartyOption])

	useEffect(() => {
		if (reload) {
			setTimeout(() => {
				setReload(false)
			}, 500)
		}
	}, [reload])

	return (
		<CreateOrderModal
			isOpen={modalIsOpen}
			closeModal={closeModal}
			loadItems={selectedCounterpartyOption ? loadProducts : undefined}
			createOrder={addOrder}
			hasErrors={checkErrors}
			relationshipId={Number(selectedCounterpartyOption?.key)}
			dropdowns={[
				{
					label: t('counterparty'),
					popup: counterpartyPopup,
					loadOptions: loadCounterpartyOptions,
					selectedOption: selectedCounterpartyOption,
					onSelect: ({ key, title, avatar }) => {
						setErrors && setErrors({})
						if (key === '') return setSelectedCounterpartyOption(null)
						setSelectedCounterpartyOption({ key, title, avatar })
					},
					dropdownOptions: [],
					placeholder: 'selectCounterparty',
					error: errors.counterparty ? errors.counterparty : undefined,
				},
				{
					label: t('destination'),
					popup: destinationPopup,
					loadOptions: loadDestinationOptions,
					selectedOption: selectedDestinationOption,
					onSelect: ({ key, title, avatar }) => {
						if (!selectedCounterpartyOption) {
							return
						}
						setErrors && setErrors({})
						if (key === '') return setSelectedDestinationOption(null)
						setSelectedDestinationOption({ key, title, avatar })
					},
					dropdownOptions: [],
					placeholder: 'selectDestination',
					error: errors.destination ? errors.destination : undefined
				},
				{
					label: t('pipeline'),
					popup: pipelinePopup,
					loadOptions: loadPipelinesOptions,
					selectedOption: selectedPipelineOption,
					onSelect: ({ key, title, avatar }) => {
						setErrors && setErrors({})
						if (key === '') return setSelectedPipelineOption(null)
						setSelectedPipelineOption({ key, title, avatar })
					},
					dropdownOptions: [],
					placeholder: 'selectPipeline',
					error: errors.pipeline ? errors.pipeline : undefined
				}
			]}
			reload={reload}
		/>
	)
}

export default CreatePurchaseOrderModal