// styles and icons
import './createTransactionModal.scss'

// react
import { FC, FormEvent, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

// components
import Modal from '../../../../../general/modals/modal/modal'
import InputField from '../../../../../general/inputField/inputField'
import TextArea from '../../../../../general/textArea/textArea'
import Dropdown from '../../../../../general/dropdown/dropdown'

// redux
import { useAppDispatch, useAppSelector } from '../../../../../../customHooks/redux'
import { setCreateTransactionModalIsOpen } from '../../../../../../redux/finance/general/modals'
import { setFinanceGuidanceModals, setFinanceGuidanceModalsClosed } from '../../../../../../redux/general/modals'

// types 
import { currency, dropdownOption, infoPopupTypes } from '../../../../../../types/general/generalTypes'
import { transaction, transactionResponse, transactionType } from '../../../../../../types/finance/general'
import { accountResponse } from '../../../../../../types/finance/accountTypes'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleAccountTransactionsUrl, companyAccountUrl, singleAccountUrl } from '../../../../../../utils/urls/finance/account/account'

// other 
import { createDropdownOption, translateEnum } from '../../../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'
import GuideModal from '../../../../../general/modals/guideModal/guideModal'

type formErrors = {
	amount?: string
	selectedAccount?: string
}

type createTransactionModalProps = {
	setTransactions: (value: transaction[]) => void
	transactions: transaction[]
}

const CreateTransactionModal: FC<createTransactionModalProps> = ({ setTransactions, transactions }) => {

	const { t } = useTranslation('', { keyPrefix: 'finance.general.modals.transcation.createTransactionModal' })
	const tTransaction = useTranslation('', { keyPrefix: 'general.transactionType' }).t
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t

	const [selectedAccountOption, setSelectedAccountOption] = useState<dropdownOption | null>(null)
	const [selectedCurrencyOption, setSelectedCurrencyOption] = useState<dropdownOption | null>(null)
	const [selectedTransactionTypeOption, setSelectedTransactionTypeOption] = useState<dropdownOption | null>(null)
	const [amount, setAmount] = useState<number>(0)
	const [description, setDescription] = useState<string>('')
	const [errors, setErrors] = useState<formErrors>({})

	const dispatch = useAppDispatch()
	const { modalIsOpen } = useAppSelector((state) => state.financeGeneralModal.createTransactionModal)
	const { userCompanyData } = useAppSelector(state => state.general)

	const formRef = useRef<HTMLFormElement | null>(null)

	const companyId = userCompanyData?.companyId || -1

	const { accountId } = useParams()
	const [guideStep, setGuideStep] = useState<number>(1)

	const { financeGuidanceModals } = useAppSelector((state) => state.generalModals.guidanceModals)

	const guideText = [t('transactionDescription'), t('transactionTotal'), t('transactionTransaction'), t('transactionCurrency'), t('transactionAccount'), t('transactionCreate')]

	const handleGuideStep = () => {
		return guideText && guideText[guideStep - 1] || null
	}

	const nextGuideStep = () => {
		if (guideStep === 6 && !accountId || guideStep === 5 && accountId) {
			dispatch(setFinanceGuidanceModalsClosed())
		} else {
			setGuideStep((prevStep) => prevStep + 1)
		}
	}

	const infoPopup = {
		content: [{
			title: 'create_transaction_title',
			description: 'create_transaction_description'
		}],
		type: infoPopupTypes.INFO
	}

	const accountPopup = {
		content: [
			{
				title: 'create_account_info_title',
				description: 'create_account_info_description',
				link: '/ri-business/finance/account-list',
			}
		],
		type: infoPopupTypes.INFO,
	}

	const createTransaction = async () => {
		if(selectedAccountOption){

			const { result } = await authorizedRequest(singleAccountTransactionsUrl(accountId ? Number(accountId) : Number(selectedAccountOption?.key)), 'POST', 'accessToken', {
				data: [{
					amount: amount,
					currency: selectedCurrencyOption?.key,
					transaction_type: selectedTransactionTypeOption?.key,
					description: description
				}]
			})
	
			const updatedTransactions: transaction[] = result.map((transaction: transactionResponse) => {
				return {
					accountId: transaction.account_id,
					amount: transaction.amount,
					currency: transaction.currency,
					transactionType: transaction.transaction_type,
					description: transaction.description,
					date: new Date(transaction.date * 1000)
				}
			})
	
			setTransactions([...updatedTransactions, ...transactions])
		}
	}

	const closeModal = () => {
		setSelectedAccountOption(null)
		setSelectedCurrencyOption(null)
		setSelectedTransactionTypeOption(null)
		setAmount(0)
		setDescription('')
		dispatch(setCreateTransactionModalIsOpen(false))
	}

	const checkErrors = () => {
		let result = true

		if (selectedAccountOption === undefined) {
			setErrors(prevErrors => ({ ...prevErrors, selectedAccount: t('pleaseChooseAccount') }))
			result = false
		}

		return result
	}


	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		if (checkErrors()) {
			createTransaction()
			closeModal()
			setErrors({})
		}
	}

	const getAccount = async () => {
		const { result } = await authorizedRequest(singleAccountUrl(Number(accountId)), 'GET')

		if (result) {
			const formatedAccount: dropdownOption[] = [{
				key: result.id,
				title: result.name,
			}]

			return formatedAccount
		}

		return []
	}

	const loadAccounts = async (request: string, page: number) => {

		const { result } = await authorizedRequest(companyAccountUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}`, 'GET')

		if (result.length > 0) {
			const formatedAccounts: dropdownOption[] = result.map((account: accountResponse) => {
				return {
					key: account.id,
					title: account.name,
				}
			})
			return formatedAccounts
		}
		return []
	}

	return (
		<Modal
			closeModal={closeModal}
			open={modalIsOpen}
			titlePopup={infoPopup}
			title={t('addNewTransaction')}
			submitButton={{
				text: t('create'),
				onClick: () => {
					if (formRef.current) formRef.current.requestSubmit()
				}
			}}
		>

			<form
				className='create-transaction-container'
				ref={formRef}
				onSubmit={handleSubmit}
			>
				<div className='form-elements-wrapper'>
					<TextArea
						label={t('description')}
						placeholder={t('description')}
						value={description}
						setValue={value => {
							setDescription(value)
							if (guideStep === 1) {
								nextGuideStep()
								dispatch(setFinanceGuidanceModals([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false]))
							}
						}}
					/>
					<InputField
						type='text'
						label={t('total')}
						value={amount.toString()}
						onChange={(e) => {
							!isNaN(Number(e.target.value)) && setAmount(Number(e.target.value))
							if (guideStep === 2) {
								nextGuideStep()
								dispatch(setFinanceGuidanceModals([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false]))
							}
						}}
					/>
					<Dropdown
						label={t('transactionType')}
						placeholder={translateEnum(tTransaction, selectedTransactionTypeOption?.key || '')}
						dropdownOptions={createDropdownOption(tTransaction, transactionType)}
						onSelect={({ key, title }) => {
							setSelectedTransactionTypeOption({ key, title })
							if (guideStep === 3) {
								nextGuideStep()
								dispatch(setFinanceGuidanceModals([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false]))
							}
						}}
						selectedOption={selectedTransactionTypeOption}
						defaultSelectedOption={{ title: tTransaction('expense'), key: 'expense' }}
					/>
					<Dropdown
						label={t('currency')}
						placeholder={translateEnum(tCurrency, selectedCurrencyOption?.key || '')}
						dropdownOptions={createDropdownOption(tCurrency, currency)}
						onSelect={({ key, title }) => {
							setSelectedCurrencyOption({ key, title })
							if (guideStep === 4) {
								nextGuideStep()
								dispatch(setFinanceGuidanceModals([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false]))
							}
						}}
						selectedOption={selectedCurrencyOption}
						defaultSelectedOption={{ title: translateEnum(tCurrency, 'UAH'), key: 'UAH' }}
					/>
					{!accountId && <Dropdown
						popup={accountPopup}
						label={t('account')}
						placeholder={t('account')}
						loadOptions={accountId ? getAccount : loadAccounts}
						onSelect={({ key, title }) => {
							setSelectedAccountOption({ key, title })
							if (guideStep === 5) {
								nextGuideStep()
								dispatch(setFinanceGuidanceModals([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true]))
							}
						}}
						dropdownOptions={[]}
						error={errors.selectedAccount}
						selectedOption={selectedAccountOption}
					/>}
				</div>
			</form>
		</Modal>
	)
}

export default CreateTransactionModal
