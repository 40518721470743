//styles
import './filterBar.scss'
import { arrowIcon } from '../../../../assets/general/generalIcons'

//components
import Button from '../../button/button'
import InputField from '../../inputField/inputField'
import FilterBarSlider from './filterBarSlider/filterBarSlider'

//react
import { FC, useEffect, useState } from 'react'

//types
import { filters, filterBar, dropdownOption } from '../../../../types/general/generalTypes'

//redux

import { useAppDispatch } from '../../../../customHooks/redux'

// translation
import { useTranslation } from 'react-i18next'
import GuideModal from '../../modals/guideModal/guideModal'

type filterBarProps = filterBar

export type selectedDropdown = {
	key: string
	selected: dropdownOption
}

const FilterBar: FC<filterBarProps> = ({ importButton, exportButton, filters, addButton, onSearch, notTranslated, guidance }) => {
	const [searchRequest, setSearchRequest] = useState('')
	const [previousSearch, setPreviousSearch] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [visibleFilters, setVisibleFilters] = useState(false)
	const [selectedOptions, setSelectedOptions] = useState<selectedDropdown[]>([])
	const [touched, setTouched] = useState<boolean>(false)
	const { t } = useTranslation('', { keyPrefix: 'general.layout.filterBar' })

	useEffect(() => {
		if (filters) {
			setActiveFilters([...filters])
		}
	}, [filters])

	const handleFilters = (filters: filters[]) => {
		setActiveFilters([...filters])
		setTouched(true)
	}

	useEffect(() => {
		let timeout: NodeJS.Timeout
		if (touched || previousSearch !== searchRequest) {
			timeout = setTimeout(() => {
				onSearch && onSearch(searchRequest, activeFilters)
				setPreviousSearch(searchRequest)
				setTouched(false)
			}, 500)
		}
		return () => {
			clearTimeout(timeout)
		}
	}, [activeFilters, searchRequest])

	console.log(guidance)

	return (
		<div className='filter-bar-container'>
			<div className="filter-bar-buttons">
				{ importButton || exportButton ? 
				
					<div className="filter-bar-buttons-container">
						{
							importButton ?
								<div className="filter-bar-data-import">
									<Button active={true} icon={arrowIcon} onClick={importButton}/>
								</div>
								: null
						}

						{
							exportButton ?
								<div className="filter-bar-data-export">
									<Button active={true} icon={arrowIcon} onClick={exportButton}/>
								</div>
								: null
						}
					</div>
					: null
				}
				{onSearch && <InputField onSearch={() => { !filters && onSearch && onSearch(searchRequest, []) }} name='Search' type='text' placeholder={t('search')} value={searchRequest} onChange={(e) => setSearchRequest(e.target.value)} />}
				<div className="filter-bar-buttons-container">
					{filters && <Button active={false} onClick={() => {
						setVisibleFilters(!visibleFilters)
					}} text={t('filters')} />}
					{addButton ? 
						<div className="button-wrapper">
							<Button active={true} onClick={() => {
								addButton.onClick()
								if(guidance && guidance.open){
									guidance?.next()
								}
							}} text={t('create')} /> 
							{
								guidance &&
									<GuideModal
										next={guidance.next}
										open={guidance.open}
										closeModal={guidance.close}	
									>
										{guidance.text}
									</GuideModal>
							}
						</div>
						: null}
				</div>
			</div>
			<FilterBarSlider setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} visible={visibleFilters} setVisible={(value) => { setVisibleFilters(value) }} filters={filters || []} activeFilters={activeFilters} setActiveFilters={handleFilters} notTranslated={notTranslated} />
		</div>
	)
}

export default FilterBar