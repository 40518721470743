// styles and icons
import { deleteIcon, navlogo } from '../../../../assets/general/generalIcons'

// react
import  { FC, useEffect, useState } from 'react'

// components
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import CounterpartyCard from '../../../general/counterpartyCard/counterpartyCard'
import CounterpartySkeleton from '../../../general/skeletons/counterParty/counterPartySkeleton'
import GuideModal from '../../../general/modals/guideModal/guideModal'

// redux
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'
import {
	setDeleteProviderModalIsOpen,
	setDeleteProviderRelationshipId,
} from '../../../../redux/purchase/providersList/modals'
import { setPurchasesGuidanceModalsClosed, setPurchasesGuidanceProviderModals } from '../../../../redux/general/modals'

// types
import { provider } from '../../../../types/purchase/providerTypes'

// translation
import { useTranslation } from 'react-i18next'

// other
import {
	formatTags,
	getItemsPerColumn,
} from '../../../../assets/general/generalFunctions'

type providerCardListProps = {
	providers: provider[]
	loading: boolean
}

const ProviderCardList: FC<providerCardListProps> = ({
	providers,
	loading
}) => {
	const dispatch = useAppDispatch()

	const { purchasesGuidanceModals } = useAppSelector(state => state.generalModals.guidanceModals)

	const { t: tCurrency } = useTranslation('', { keyPrefix: 'general.currency' })
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn())
	const deleteProvider = (provider: number) => {
		dispatch(setDeleteProviderModalIsOpen(true))
		dispatch(setDeleteProviderRelationshipId(provider))
	}
	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn())
	}
	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<ColumnLayout amount={itemsPerColumn}>
			{providers.map((provider, index) => {
				return (
					index === 0 ? 

					<div>
						<CounterpartyCard
							key={`provider-${provider.id}`}
							{...provider}
							placeholder={navlogo}
							link={`/ri-business/purchase/provider/${provider.id}`}
							tags={provider.labels.map((e) => ({
								...e,
								value: formatTags(e.value, tCurrency),
							}))}
							actions={
								provider.deleteAccess
									? [
										{
											id: 2,
											icon: deleteIcon,
											onClick: () => deleteProvider(provider.id),
										},
									]
									: []
							}
							onClick={() => {
								if(purchasesGuidanceModals.providers[5]){
									dispatch(setPurchasesGuidanceProviderModals([false, false, false, false, false, false, true, false, false, false, false, false]))
								}
							}}
						/>
						<GuideModal
							open={purchasesGuidanceModals.providers[5]}
							closeModal={() => {
								dispatch(setPurchasesGuidanceModalsClosed())
							}}
						>
							<></>
						</GuideModal>
					</div>

					:
						<CounterpartyCard
							key={`provider-${provider.id}`}
							{...provider}
							placeholder={navlogo}
							link={`/ri-business/purchase/provider/${provider.id}`}
							tags={provider.labels.map((e) => ({
								...e,
								value: formatTags(e.value, tCurrency),
							}))}
							actions={
								provider.deleteAccess
									? [
										{
											id: 2,
											icon: deleteIcon,
											onClick: () => deleteProvider(provider.id),
										},
									]
									: []
							}
						/>
				)
			})}
			{loading && <CounterpartySkeleton />}
		</ColumnLayout>
	)
}

export default ProviderCardList