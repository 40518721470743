import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { store } from './redux/store'
import { Provider } from 'react-redux'

import './locales/i18n'
import TranslationLoader from './translationLoader'

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
)

root.render(
	<Provider store={store}>
		<TranslationLoader>
			<Router>
				<App />
			</Router>
		</TranslationLoader>
	</Provider>
)
