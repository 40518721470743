// react
import { FC } from 'react'

// components
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import OrderCard from '../../../general/orderCard/orderCard'
import OrderItemSkeleton from '../../../general/skeletons/orderItem/orderItemSkeleton'
import GuideModal from '../../../general/modals/guideModal/guideModal'

// types
import { order } from '../../../../types/general/generalTypes'

//redux
import { useAppSelector, useAppDispatch } from '../../../../customHooks/redux'
import { setPurchasesGuidanceModalsClosed, setPurchasesGuidanceOrderModals } from '../../../../redux/general/modals'


type purchaseOrderCardListProps = {
	orders: order[]
	loading: boolean
	itemsPerColumn: number
}
const PurchaseOrderCardList: FC<purchaseOrderCardListProps> = ({
	orders,
	loading,
	itemsPerColumn,
}) => {

	const dispatch = useAppDispatch()
	
	const { purchasesGuidanceModals } = useAppSelector(state => state.generalModals.guidanceModals)

	return (
		<ColumnLayout amount={itemsPerColumn}>
			{orders.map((order, index) => {
				return (
					index === 0 ? 
						<div>
							<OrderCard {...order} isProvider key={'order' + index} onClick={() => {
								if(purchasesGuidanceModals.orders[5]){
									dispatch(setPurchasesGuidanceOrderModals(([false, false, false, false, false, false, true, false, false, false])))
								}
							}} />
							<GuideModal
								open={purchasesGuidanceModals.orders[5]}
								closeModal={() => { dispatch(setPurchasesGuidanceModalsClosed()) }}
							>
								<p>text</p>
							</GuideModal>
						</div>
					:
						<OrderCard {...order} isProvider key={'order' + index} />
				)
			})}
			{loading ? <OrderItemSkeleton /> : null}
		</ColumnLayout>
	)
}

export default PurchaseOrderCardList