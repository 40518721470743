// styles and icons
import './inventoryItemProviderOfferingList.scss'

//react
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

//components
import Pagination from '../../../general/pagination/pagination'
import ColumnLayout from '../../../general/columnLayout/columnLayout'
import OfferingCard from '../../../general/offeringCard/offeringCard'
import DeleteInventoryItemProviderOfferingModal from './modals/deleteInventoryItemProviderOfferingModal/deleteInventoryItemProviderOfferingModal'
import AddInventoryItemProviderOfferingModal from './modals/addInventoryItemProviderOfferingModal/addInventoryItemProviderOfferingModal'
import OfferingCardSkeleton from '../../../general/skeletons/offeringCard/offeringCardSkeleton'

//redux
import { useAppDispatch, useAppSelector } from '../../../../customHooks/redux'
import { setDeleteInventoryItemProviderOfferingModalIsOpen, setDeleteInventoryItemProviderOfferingId, setAddInventoryItemProviderOfferingModalIsOpen } from '../../../../redux/warehouse/inventoryItem/modals'

//types
import { filters, filterBar, offering } from '../../../../types/general/generalTypes'

// network
import { authorizedRequest } from '../../../../utils/queries'
import { inventoryItemProviderOfferingFilterUrl, inventoryItemProviderOfferingsUrl } from '../../../../utils/urls/warehouses/inventoryItems/inventoryItem'

// other
import { createFilteringRequest, formatFilters, formatOfferingResponse, getItemsPerColumn } from '../../../../assets/general/generalFunctions'

// translation
import { useTranslation } from 'react-i18next'


type inventoryItemProviderOfferingListProps = {
	setFilterBar: (value: filterBar | undefined) => void
}

const InventoryItemProviderOfferingList: FC<inventoryItemProviderOfferingListProps> = ({ setFilterBar }) => {
	const dispatch = useAppDispatch()
	const { userCompanyData } = useAppSelector((state) => state.general)

	const companyId: number = userCompanyData?.companyId || -1
	const [inventroyItemOfferings, setInventroyItemOfferings] = useState<offering[]>([])
	const { inventoryItemId } = useParams()

	const [searchRequest, setSearchRequest] = useState('')
	const [activeFilters, setActiveFilters] = useState<filters[]>([])
	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(false)
	const [loading, setLoading] = useState(false)
	const [itemsPerColumn, setItemsPerColumn] = useState(getItemsPerColumn(2))

	const { t } = useTranslation('', { keyPrefix: 'warehouse.inventoryItem.inventoryItemOfferingList' })

	const loadFilterBar = async () => {
		const { result } = await authorizedRequest(inventoryItemProviderOfferingFilterUrl(Number(inventoryItemId)), 'GET')

		const filterResult = result.filters

		const formatedFilters: filters[] = formatFilters(filterResult)

		setFilterBar({
			// importButton: () => {
			// 	dispatch(setImportTransactionFileSelectorModalIsOpen(true))
			// },
			// exportButton: () => {
			// 	dispatch(setExportTransactionFileModalIsOpen(true))
			// },
			filters: formatedFilters,
			addButton: {
				text: t('add'),
				active: true,
				onClick: () => {
					dispatch(setAddInventoryItemProviderOfferingModalIsOpen(true))
				}
			},
			onSearch: onSearch
		})
	}

	const loadData = async (page: number, request: string, filters: filters[]) => {
		const filteringRequest = createFilteringRequest(filters)
		const { result } = await authorizedRequest(inventoryItemProviderOfferingsUrl(Number(inventoryItemId)) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
		// const {result} = await authorizedRequest(getOfferingsUrl(companyId) + `?page=${page}&per_page=${10}&needle=${request}` + filteringRequest, 'GET')
		console.log(result)
		// console.log(result)
		if (result.length > 0) {
			const formatedOfferings: offering[] = result.map(formatOfferingResponse)
			return formatedOfferings
		}

		return []
	}

	const handleDelete = (id: number) => {
		dispatch(setDeleteInventoryItemProviderOfferingId(id))
		dispatch(setDeleteInventoryItemProviderOfferingModalIsOpen(true))
	}

	const onSearch = (searchValue: string, filters: filters[]) => {
		setPage(1)
		setSearchRequest(searchValue)
		setActiveFilters(filters)
		setLastPage(false)
		setLoading(true)
		loadData(1, searchValue, filters)
			.then((result) => {
				if (result.length > 0) {
					setInventroyItemOfferings([...inventroyItemOfferings, ...result])
					setPage(page + 1)
				} else {
					setLastPage(true)
				}
				setLoading(false)
			})
	}

	const handleLoadMore = () => {
		if (!loading && !lastPage) {
			setLoading(true)
			loadData(page, searchRequest, activeFilters)
				.then((result) => {
					if (result.length > 0) {
						setInventroyItemOfferings([...inventroyItemOfferings, ...result])
						setPage(page + 1)
					} else {
						setLastPage(true)
					}
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		onSearch(searchRequest, activeFilters)

		loadFilterBar()

		return () => {
			setFilterBar(undefined)
		}
	}, [])

	const handleResize = () => {
		setItemsPerColumn(getItemsPerColumn(2))
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])


	return (
		<div className="offering-list-container">
			<Pagination onLoadMore={handleLoadMore} loading={loading} showLoader={false}>
				<ColumnLayout amount={itemsPerColumn}>
					{
						inventroyItemOfferings.map((offeringItem: offering, index) => {
							return (
								<OfferingCard
									{...offeringItem}
									handleDelete={() => handleDelete(offeringItem.id)}
									key={`products-${index}`}
								/>
							)
						})
					}
					{ loading ? <OfferingCardSkeleton/> : null }
				</ColumnLayout>
			</Pagination>
			<AddInventoryItemProviderOfferingModal inventroyItemOfferings={inventroyItemOfferings} setInventroyItemOfferings={setInventroyItemOfferings} />
			<DeleteInventoryItemProviderOfferingModal inventroyItemOfferings={inventroyItemOfferings} setInventroyItemOfferings={setInventroyItemOfferings} />
		</div >
	)
}

export default InventoryItemProviderOfferingList