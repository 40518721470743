// styles and icons
import './createProviderOfferingModal.scss'

//react
import { useState, useRef, FC, FormEvent } from 'react'
import { useParams } from 'react-router-dom'

//components
import Modal from '../../../../../general/modals/modal/modal'
import InputField from '../../../../../general/inputField/inputField'
import Dropdown from '../../../../../general/dropdown/dropdown'
import TextArea from '../../../../../general/textArea/textArea'
import FloatInput from '../../../../../general/floatInput/floatInput'
import CheckBox from '../../../../../general/checkBox/checkBox'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../../customHooks/redux'
import { setCreateProviderOfferingModalIsOpen } from '../../../../../../redux/purchase/provider/modals'

// network
import { authorizedRequest } from '../../../../../../utils/queries'
import { singleProviderOfferingsUrl } from '../../../../../../utils/urls/purchases/provider'

// types
import { currency, dropdownOption, infoPopupTypes, infoType, offering } from '../../../../../../types/general/generalTypes'

//other
import { createDropdownOption, formatOfferingResponse, formatStringIntoTwoDigitsFloat } from '../../../../../../assets/general/generalFunctions'

//translation
import { useTranslation } from 'react-i18next'

type formErrors = {
	name?: string
	description?: string
	priceWithVAT?: string
	salesOfferingPriceWithVAT?: string
	salesOfferingPrice?: string
}

type createProviderOfferingModalProps = {
	offerings: offering[]
	setOfferings: (value: offering[]) => void
}

const CreateProviderOfferingModal: FC<createProviderOfferingModalProps> = ({ offerings, setOfferings }) => {
	const dispatch = useAppDispatch()
	const { relationshipId } = useParams()

	const {t} = useTranslation('', { keyPrefix: 'purchase.provider.providerOfferingList.createProviderOfferingModal' })
	const tCurrency = useTranslation('', { keyPrefix: 'general.currency' }).t

	const { modalIsOpen } = useAppSelector((state) => state.providerModal.providerOfferings.createProviderOfferingModal)

	const formRef = useRef<HTMLFormElement | null>(null)

	const [name, setName] = useState<string>('')
	const [priceWithoutVAT, setPriceWithoutVAT] = useState<number>(0)
	const [priceWithVAT, setPriceWithVAT] = useState<number>(0)
	const [VAT, setVAT] = useState<number>(0)
	const [description, setDescription] = useState<string>('')
	const [offeringCurrency, setOfferingCurrency] = useState<dropdownOption>({ key: currency.UAH, title: tCurrency(currency.UAH) })
	
	const [inventoryItemRadio, setInventoryItemRadio] = useState<boolean>(false)
	const [salesOfferingItemRadio, setSalesOfferingItemRadio] = useState<boolean>(false)
	
	const [salesOfferingPriceWithoutVAT, setSalesOfferingPriceWithoutVAT] = useState<number>(0)
	const [salesOfferingPriceWithVAT, setSalesOfferingPriceWithVAT] = useState<number>(0)
	const [salesOfferingVAT, setSalesOfferingVAT] = useState<number>(0)
	const [salesOfferingCurrency, setSalesOfferingCurrency] = useState<dropdownOption>({ key: currency.UAH, title:  tCurrency(currency.UAH) })

	const [errors, setErrors] = useState<formErrors>({})

	const closeModal = () => {
		setName('')
		setDescription('')
		setPriceWithoutVAT(0)
		setPriceWithVAT(0)
		setVAT(0)
		setOfferingCurrency({key: currency.UAH, title: tCurrency(currency.UAH)})
		dispatch(setCreateProviderOfferingModalIsOpen(false))
	}

	const infoPopup = {
		content: [{
			title: 'create_offering_title',
			description: 'create_offering_description',
		}],
		type: infoPopupTypes.INFO
	}

	const checkErrors = () => {
		let result = true
    
		if (salesOfferingItemRadio) {
			if (salesOfferingPriceWithVAT === undefined || salesOfferingPriceWithVAT < 0) {
				setErrors(prevErrors => ({ ...prevErrors, salesOfferingPriceWithVAT: t('pleaseEnterThePrice') }))
				result = false
			}
		}

		if (name === '') {
			setErrors(prevErrors => ({ ...prevErrors, name: t('pleaseEnterTheName') }))
			result = false
		}
		if (priceWithVAT === undefined || priceWithVAT < 0) {
			setErrors(prevErrors => ({ ...prevErrors, priceWithVAT: t('pleaseEnterThePrice') }))
			result = false
		}

		return result
	}

	const amountInputHandleWithoutVAT = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.00'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		
		setPriceWithoutVAT(numberValue)

		const newValue = parseFloat(
			(
				numberValue * (1 + VAT / 100)
			).toFixed(2)
		)

		setPriceWithVAT(newValue)
	}

	const amountInputHandleWithVAT = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.00'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		
		setPriceWithVAT(numberValue)

		const newValue = parseFloat(
			(
				numberValue / (1 + VAT / 100)
			).toFixed(2)
		)
		
		setPriceWithoutVAT(newValue)
	}


	const amountInputHandleVAT = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.00'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		setVAT(numberValue)
		setPriceWithoutVAT(
			parseFloat(
				(
					priceWithVAT / (1 + numberValue / 100)
				).toFixed(2)
			)
		)
	}



	const amountInputHandleSalesOfferingWithoutVAT = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.00'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		
		setSalesOfferingPriceWithoutVAT(numberValue)

		const newValue = parseFloat(
			(
				numberValue * (1 + salesOfferingVAT / 100)
			).toFixed(2)
		)

		setSalesOfferingPriceWithVAT(newValue)
	}

	const amountInputHandleSalesOfferingWithVAT = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.00'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		
		setSalesOfferingPriceWithVAT(numberValue)

		const newValue = parseFloat(
			(
				numberValue / (1 + salesOfferingVAT / 100)
			).toFixed(2)
		)
		
		setSalesOfferingPriceWithoutVAT(newValue)
	}


	const amountInputHandleSalesOfferingVAT = (rawValue: string) => {
		if (rawValue.length < 1) {
			rawValue = '00.00'
		}
		const numberValue = formatStringIntoTwoDigitsFloat(rawValue)
		setSalesOfferingVAT(numberValue)
		setSalesOfferingPriceWithoutVAT(
			parseFloat(
				(
					salesOfferingPriceWithVAT / (1 + numberValue / 100)
				).toFixed(2)
			)
		)
	}

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		setErrors({})

		if (checkErrors()) {
			console.log(offeringCurrency)
			const { result } = await authorizedRequest(singleProviderOfferingsUrl(Number(relationshipId)), 'POST', 'accessToken', {
				data: [
					{
						name: name,
						description: description,
						price: priceWithVAT,
						vat: VAT,
						currency: offeringCurrency.key,
						create_inventory_item: inventoryItemRadio,
						create_offering: salesOfferingItemRadio,
						offering_price: salesOfferingPriceWithVAT,
						offering_currency: salesOfferingCurrency.key,
						offering_vat: salesOfferingVAT
					}
				]
			})
			if (result.length > 0) {
				const formatedOfferings: offering[] = result.map(formatOfferingResponse)
				setOfferings([...formatedOfferings, ...offerings])
			}
			closeModal()
		}
	}

	return (
		<Modal
			titlePopup={infoPopup}
			closeModal={closeModal}
			open={modalIsOpen}
			title={t('title')}
			submitButton={{
				text: t('add'),
				onClick: () => {
					if (formRef.current) {
						formRef.current.requestSubmit()
					}
				}
			}}
		>
			<form
				className='create-offering-container'
				ref={formRef}
				onSubmit={handleSubmit}
			>
				<div className="form-elements-wrapper">
					<InputField
						type='text'
						name='offeringName'
						label={t('offeringName')}
						value={name}
						onChange={(e) => {
							setName(e.target.value)
						}}
						info={errors.name ? {
							type: infoType.error,
							text: errors.name
						} : undefined}
					/>
					<TextArea
						label={t('description')}
						placeholder={t('description')}
						value={description}
						setValue={val => {
							setDescription(val)
						}}
					/>
					<FloatInput
						label={t('priceWithoutVat')}
						amountInputHandle={amountInputHandleWithoutVAT}
						value={`${priceWithoutVAT}`}	
					/>
					<FloatInput
						label={t('priceWithVat')}
						info={errors.priceWithVAT ? {
							type: infoType.error,
							text: errors.priceWithVAT
						} : undefined}
						amountInputHandle={amountInputHandleWithVAT}
						value={`${priceWithVAT}`}	
					/>
					<FloatInput
						label={t('vat')}
						amountInputHandle={amountInputHandleVAT}
						value={`${VAT}`}
						maxLength={2}	
					/>
					<Dropdown
						label={t('currency')}
						placeholder={t('currency')}
						dropdownOptions={createDropdownOption(tCurrency, currency)}
						onSelect={(value) => {
							setOfferingCurrency(value)
						}}
						selectedOption={offeringCurrency}
					/>
					<CheckBox 
						isChecked={inventoryItemRadio} 
						onClick={() => {
							if (salesOfferingItemRadio){
								setSalesOfferingItemRadio(false)
							}
							setInventoryItemRadio(!inventoryItemRadio)
						}} 
						label={t('createInventoryItem')}
					/> 
					<CheckBox 
						isChecked={salesOfferingItemRadio} 
						onClick={() => {
							setSalesOfferingItemRadio(!salesOfferingItemRadio)
							setInventoryItemRadio(true)
						}} 
						label={t('createSalesOffering')}
					/>
					{inventoryItemRadio && salesOfferingItemRadio && (
						<>
							<FloatInput
								label={t('priceWithoutVat')}
								amountInputHandle={amountInputHandleSalesOfferingWithoutVAT}
								value={`${salesOfferingPriceWithoutVAT}`}	
							/>
							<FloatInput
								label={t('priceWithVat')}
								info={errors.priceWithVAT ? {
									type: infoType.error,
									text: errors.priceWithVAT
								} : undefined}
								amountInputHandle={amountInputHandleSalesOfferingWithVAT}
								value={`${salesOfferingPriceWithVAT}`}	
							/>
							<FloatInput
								label={t('vat')}
								amountInputHandle={amountInputHandleSalesOfferingVAT}
								value={`${salesOfferingVAT}%`}	
							/>
							<Dropdown
								label={t('currency')}
								placeholder={t('currency')}
								dropdownOptions={createDropdownOption(tCurrency, currency)}
								onSelect={(value) => {
									setSalesOfferingCurrency(value)
								}}
								selectedOption={salesOfferingCurrency}
							/>
						</>
					)}
				</div>
			</form>
		</Modal>
	)
}
export default CreateProviderOfferingModal