import { useState, useEffect, FC } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from './components/general/loading/loading'

type translationLoaderProps = {
    children: React.ReactNode
}

const TranslationLoader: FC<translationLoaderProps> = ({ children }) => {
  const { i18n } = useTranslation()
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (i18n.isInitialized) {
      setIsReady(true)
    } else {
      const onReady = () => setIsReady(true)
      i18n.on('initialized', onReady)
      return () => {
        i18n.off('initialized', onReady)
      }
    }
  }, [i18n])

  if (!isReady) {
    return <Loading/>
  }

  return <>{children}</>
};

export default TranslationLoader