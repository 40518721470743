//styles and icons
import './hierarchyColumn.scss'
import { hireEmployeeButton } from '../../../../../assets/employees/hierarchy/hierarchyIcons'

//react
import { FC } from 'react'

//components
import CounterpartyCard from '../../../../general/counterpartyCard/counterpartyCard'
import CounterpartySkeleton from '../../../../general/skeletons/counterParty/counterPartySkeleton'
import GuideModal from '../../../../general/modals/guideModal/guideModal'

//redux
import { useAppSelector, useAppDispatch } from '../../../../../customHooks/redux'
import { setHierarchy } from '../../../../../redux/employees/hierarchy/general'
import { setHireEmployeeModalIsOpen, setHireEmployeeLeaderId, setHireEmployeeEmployeeId } from '../../../../../redux/employees/general/modals'
import { setEmployeesGuidanceHierarchyModals, setEmployeesGuidanceModalsClosed } from '../../../../../redux/general/modals'

//types
import { employee, hierarchyColumn } from '../../../../../types/employees/hierarchyTypes'
import { switchButton } from '../../../../../types/general/generalTypes'

//network
import { getEmployeeHierarchyData } from '../../../../../assets/employees/hierarchy/hierarchyFunctions'
import { hierarchyEmployeesUrl } from '../../../../../utils/urls/employees/hierarchy'

//translation
import { useTranslation } from 'react-i18next'

type hierarchyColumnProps = hierarchyColumn & {
	getEmployeeActions: (employee: employee) => switchButton[]
	level: number
}

const HierarchyColumn: FC<hierarchyColumnProps> = ({ employees, level, isLoading, hire, getEmployeeActions }) => {

	const { hierarchy } = useAppSelector((state) => state.hierarchyGeneral)
	const { employeesGuidanceModals } = useAppSelector((state) => state.generalModals.guidanceModals)
	const { t: tCurrency } = useTranslation('', { keyPrefix: 'general.currency' })

	const dispatch = useAppDispatch()

	// adding new column of employee once someone was chosen in the hierarchy

	const getEmployeesData = (employeeId: number) => {

		const slicedHierarchy = hierarchy.slice(0, level+1)

		const newHierarchy: hierarchyColumn[] = []

		for(let i=0; i<slicedHierarchy.length; i++){

			newHierarchy.push({
				employees: slicedHierarchy[i].employees.map((employee) => {
					const active = employee.id === employeeId
					return {
						...employee,
						active: i === level ? active : employee.active
					}
				}),
				isLoading: false,
				hire: slicedHierarchy[i].hire
			})
		}

		const newColumn: hierarchyColumn = {
			employees: [],
			isLoading: true,
			hire: false
		}

		newHierarchy.push(newColumn)

		dispatch(setHierarchy([
			...newHierarchy
		]))

		// getting employees of the chosen employee
		getEmployeeHierarchyData(hierarchyEmployeesUrl(employeeId), getEmployeeActions)
			.then((employees) => {
				const updatedHierarchy = newHierarchy.map((column, index) => {
					if (index === newHierarchy.length - 1) {
						return {
							...column,
							hire: employees.every((employee) => employee.replaceAccess),
							employees: employees,
							isLoading: false,
						}
					}
					return column
				})

				dispatch(setHierarchy([
					...updatedHierarchy
				]))
			})
	}
	const regex = /(UAH|EU|USD)/
	return (
		<div className="hierarchy-column">
			{
				employees.map((employeeData, index) => {
					const fired = employeeData.userId === null

					return (
						<div
							className={`counterparty-container ${fired ? 'fired' : ''} ${employeeData.active && employeeData.userId ? 'counterparty-active' : ''}`}
							onClick={() => { 
								getEmployeesData(employeeData.id)
								if(employeesGuidanceModals.hierarchy[6] && level === hierarchy.length-1){
									dispatch(setEmployeesGuidanceHierarchyModals([false, false, false, false, false, false, false]))
								}
							}}
							key={`hierarchy-employee-${employeeData.id}`}
						>
							<CounterpartyCard
								avatar={fired ? undefined : employeeData.avatar}
								description={employeeData.position}
								name={employeeData.name}
								actions={employeeData.actions}
								tags={employeeData.tags?.map(e => ({ ...e, value: regex.test(e.value) ? Number(e.value.split(' ')[0]).toFixed(2) + ' ' + tCurrency(e.value.split(' ')[1]) : e.value }))}
							/>
							{
								index === 0 &&
									<GuideModal
										next={() => {
											dispatch(setEmployeesGuidanceHierarchyModals([false, false, false, false, false, false, false]))
										}}
										open={employeesGuidanceModals.hierarchy[6] && level === hierarchy.length-1 }
										closeModal={() => {
											dispatch(setEmployeesGuidanceModalsClosed())
										}}
									>
										<></>
									</GuideModal> 
								
							}
						</div>
					)
				})
			}

			{
				hire ?
					<div className="hierarchy-hire-worker-button-wrapper">
						<div className="hierarchy-hire-worker-button" onClick={() => {
							dispatch(setHireEmployeeModalIsOpen(true))
							dispatch(setHireEmployeeLeaderId(hierarchy[level - 1].employees.find(employee => employee.active)?.id))
							dispatch(setHireEmployeeEmployeeId(undefined))
						}}>
							{hireEmployeeButton}
						</div>

						<GuideModal
							next={() => {
								dispatch(setEmployeesGuidanceHierarchyModals([false, false, false, false, false, false, true]))
							}}
							open={employeesGuidanceModals.hierarchy[5]}
							closeModal={() => {
								dispatch(setEmployeesGuidanceModalsClosed())
							}}
						>
							<></>
						</GuideModal>
					</div>
					:
					<></>
			}
			{
				isLoading ?
					<CounterpartySkeleton/>
					: null
			}
		</div>
	)
}

export default HierarchyColumn